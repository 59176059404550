/*
 * Notification reducer
 */
import produce from 'immer';
import { constants } from './actions';

// The initial state of the Notifications
export const initialState = [];

/* eslint-disable default-case, no-param-reassign */
const notificationReducer = (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case constants.ENQUEUE_SNACKBAR:
      draft.push({ key: action.key, ...action.notification });
      break;

    case constants.CLOSE_SNACKBAR:
      draft.forEach((notification, i) => {
        draft[i] = (action.dismissAll || notification.key === action.key)
          ? { ...notification, dismissed: true }
          : { ...notification };
      });
      break;

    case constants.REMOVE_SNACKBAR:
      draft.splice(draft.findIndex((n) => n.key === action.key), 1);
      break;
  }
});

export default notificationReducer;

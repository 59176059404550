/**
 *
 * PointsProgress
 * sync
 */

import React from 'react';
import PropTypes from 'prop-types';

import useGetCustomerGroup from 'utils/Hooks/useGetCustomerGroup';
import { useGetCustomer } from 'containers/UserProvider/queries';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import messages from 'containers/MembershipPage/messages';
import CustomerGroupRenderer from 'components/Layouts/CustomerGroupRenderer';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
  },
  label: {
    display: 'inline-flex',
    position: 'relative',
    zIndex: 2,
    flexGrow: 1,
    justifyContent: 'space-between',
    color: 'inherit',
    fontSize: 12,
    fontWeight: 'inherit',
    lineHeight: 1.7,
  },
  addition: {
    color: theme.palette.primary.main,
  },
  values: {
    marginLeft: theme.spacing(1),
    color: 'inherit',
    fontSize: 'inherit',
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 'inherit',
  },
  bar: {
    position: 'relative',
    zIndex: 1,
    display: 'inline-flex',
    flexGrow: 1,
    height: 16,
    borderRadius: 4,
    backgroundColor: theme.palette.grey[400],
    boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.15)',
    overflow: 'hidden',
    '&.active': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  modified: {
    position: 'absolute',
    height: 15,
    zIndex: 1,
    borderRadius: 4,
    backgroundColor: theme.palette.primary.main,
  },
  current: {
    position: 'absolute',
    height: 15,
    zIndex: 2,
    borderRadius: 4,
    backgroundColor: theme.palette.secondary.main,
    '$modified + &': {
      backgroundColor: theme.palette.grey[600],
    },
  },
}));

function PointsProgress({ addition }) {
  const { data: customer } = useGetCustomer();
  const currentGroup = useGetCustomerGroup();

  const classes = useStyles();
  const p = parseInt(customer?.point_value, 10);
  const a = parseInt(addition, 10);
  const m = parseInt(currentGroup === 'customer' ? 100 : 40, 10);
  const current = (p / m) * 100;
  const modified = ((p + a) / m) * 100;

  const renderValue = () => (
    <Typography component="span" className={classes.values}>
      {!Number.isNaN(p) && `${p} `}
      {(!Number.isNaN(a) && a > 0) && <span className={classes.addition}>{`(+${a}) `}</span>}
      {!Number.isNaN(m) && `/ ${m}`}
    </Typography>
  );

  return (
    <CustomerGroupRenderer groups={['customer']}>
      <Box className={classes.root}>
        <Typography className={classes.label}>
          {(currentGroup === 'customer'
            && <FormattedMessage {...messages.pvProgressToVIP} />)
            || <FormattedMessage {...messages.pvMonthly} />}
          {renderValue()}
        </Typography>
        <Box className={classes.bar}>
          {modified > current && <Box style={{ width: `${modified}%` }} className={classes.modified} />}
          <Box style={{ width: `${current}%` }} className={classes.current} />
        </Box>
      </Box>
    </CustomerGroupRenderer>
  );
}

PointsProgress.defaultProps = {
  addition: 0,
};

PointsProgress.propTypes = {
  addition: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default PointsProgress;

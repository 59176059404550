/**
 *
 * CustomerGroupRenderer
 * sync
 * Used to conditionally render content for specific customer groups
 *
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import isUndefined from 'lodash/isUndefined';
import isEmpty from 'lodash/isEmpty';
import useGetCustomerGroup from 'utils/Hooks/useGetCustomerGroup';

function CustomerGroupRenderer({
  children, fallback, groups, hide,
}) {
  const currentGroup = useGetCustomerGroup();
  if (!isUndefined(currentGroup) && !isUndefined(groups)) {
    if (!groups.includes(currentGroup) && !hide) {
      if (!isUndefined(fallback) && !isEmpty(fallback)) {
        return <>{fallback}</>;
      }
      return <></>;
    }

    if (groups.includes(currentGroup) && hide) {
      if (!isUndefined(fallback) && !isEmpty(fallback)) {
        return <>{fallback}</>;
      }
      return <></>;
    }
  }

  return <>{children}</>;
}

CustomerGroupRenderer.defaultProps = {
  hide: false,
};

CustomerGroupRenderer.propTypes = {
  hide: PropTypes.bool,
  children: PropTypes.node.isRequired,
  fallback: PropTypes.node,
  groups: PropTypes.arrayOf(
    PropTypes.oneOf(['guest', 'customer', 'vip', 'sm - ibe', 'sm - personal']),
  ).isRequired,
};

export default memo(CustomerGroupRenderer);

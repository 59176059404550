import { useQuery, useMutation } from 'react-query';
import useNotificationSnackbar from 'utils/Hooks/useNotificationSnackbar';
import request from 'utils/request';
import { querySetting } from 'utils/requestGql';

export const getConsentQuery = () => request('GET', '/tmo/gdpr/consent');
export const acceptForm = (payload) => request('POST', '/tmo/gdpr/consent', payload);
export const getDownloadLinkQuery = () => request('GET', '/tmo/gdpr/export', null, { responseType: 'blob' });
export const downloadAccountPost = () => request('POST', '/tmo/gdpr/export');
export const deleteAccount = (payload) => request('POST', '/tmo/gdpr/erase', payload);
export const getCustomerQuery = () => request('GET', '/tmo/gdpr/customer');
export const getConsent = () => request('GET', '/tmo/gdpr/cmsPage/aboutus');
export const getConfigQuery = () => request('GET', '/tmo/gdpr/config');
export const getRegisterCheckbox = () => request('GET', '/tmo/gdpr/checkboxes/register');

export const useGetConsent = () => useQuery('privacy.consents',
  () => getConsentQuery((res) => res.success && res.consents),
  querySetting);

export const useGetUpdateConsent = () => useQuery('privacy.customer',
  () => getCustomerQuery());

export const useGetRegisterCheckbox = () => useQuery('privacy.RegisterCheckbox',
  () => getRegisterCheckbox(), querySetting);

export const useDeleteAccount = () => useMutation((payload) => deleteAccount(payload));

export const useAcceptForm = () => useMutation((payload) => acceptForm(payload));

export const useAgreeLicense = () => {
  const notificationSnackbar = useNotificationSnackbar();
  const { mutate: acceptLicense } = useAcceptForm();
  return (payload) => acceptLicense(payload, {
    onSuccess: () => {
    },
    onFail: (err) => {
      notificationSnackbar(err, 'warning');
    },
  });
};

/**
 *
 * CartListItem
 *
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import get from 'lodash/get';
import { FormattedMessage } from 'react-intl';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { formatPrice } from 'utils/helpers';
import { actions } from 'containers/CartProvider/actions';
import { addToCartAction, handleDeleteCartItem } from 'utils/ga';
import {
  makeSelectCartItemIsSelected, makeSelectLockCheckout, makeSelectSavingCartItems, makeSelectCartItems,
} from 'containers/CartProvider/selectors';
import CheckBoxInput from 'components/Inputs/CheckBoxInput';
import QuantityAdjuster from 'components/Order/QuantityAdjuster';
import { makeSelectLockCart } from 'containers/CheckoutPage/selectors';
import appMessages from 'containers/App/messages';
import Picture from 'components/Picture';
import isEmpty from 'lodash/isEmpty';
import ConfirmRemoval from './ConfirmRemoval';

function MiniCartListItem({
  cartItem, toggleCartItem, updateCartItem, removeCartItem, isCartLock, cartItems,
  isChecked, lockCheckout, toggleLock1000, savingItems, proceedingToCheckout,
}) {
  const [quantity, setQuantity] = useState(cartItem.qty);
  const [PV, setPV] = useState(0);
  const [updating, setUpdating] = useState(false);
  const [pendingDeletion, setPendingDeletion] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [timer, setTimer] = useState(-1);
  const [total, setTotal] = useState(0);

  // const price = get(cartItem, 'extension_attributes.original_price', 0);
  const calculatePrice = get(cartItem, 'extension_attributes.calculated_price', 0);

  useEffect(() => {
    if (!isEmpty(cartItems)) {
      let totalVar = 0;
      // eslint-disable-next-line array-callback-return
      cartItems.map((item) => {
        const price = get(item.extension_attributes, 'calculated_price', 0);
        totalVar += item.qty * price;
      });
      setTotal(totalVar);
    }
  }, [cartItems]);

  useEffect(() => {
    setQuantity(cartItem.qty);
  }, [cartItem]);

  useEffect(() => {
    setPV(get(cartItem, 'extension_attributes.point_value', 0) / cartItem.qty);
  }, []);

  useEffect(() => {
    setUpdating(savingItems.includes(cartItem.item_id));
  }, [savingItems]);

  const handleToggle = () => toggleCartItem(cartItem.item_id);

  const handleUpdateCartItem = (qty) => {
    if (qty === 0 || cartItem.qty === qty) {
      return false;
    }
    proceedingToCheckout(true);
    setQuantity(qty);
    updateCartItem({
      data: { cartItem: { ...cartItem, qty } },
      complete: () => proceedingToCheckout(false),
      error: () => proceedingToCheckout(false),
    });
    return false;
  };

  const handleQuantityAdjustment = (event) => {
    toggleLock1000(true);
    const qty = parseInt(event, 10);
    if (qty === 0) {
      setShowConfirm(true);
    } else {
      setQuantity(qty);
      const adjuest = qty - quantity;

      if (adjuest > 0) {
        addToCartAction(cartItem, 'add', adjuest);
      } else {
        addToCartAction(cartItem, 'add', -adjuest);
      }
      clearTimeout(timer);
      const timeoutId = setTimeout(() => {
        handleUpdateCartItem(qty);
      }, 1000);
      setTimer(timeoutId);
    }
  };

  const handleCloseConfirm = () => {
    setShowConfirm(false);
    toggleLock1000(false);
  };

  const handleRemoval = () => {
    setPendingDeletion(true);
    removeCartItem({
      itemId: cartItem.item_id,
      complete: () => {
        setPendingDeletion(false);
        handleCloseConfirm(false);
        handleDeleteCartItem(cartItem.sku, total - calculatePrice);
      },
      error: () => {
        setPendingDeletion(false);
        handleCloseConfirm(false);
      },
    });
  };

  return (
    <>
      <Box p={1.75} display="flex" alignItems="center">
        <Box width={30}>
          <CheckBoxInput
            checked={isChecked}
            value={cartItem.name}
            onChange={handleToggle}
            disabled={pendingDeletion || updating || lockCheckout === 1 || isCartLock}
          />
        </Box>
        <Box minHeight={{ xs: 64, md: 88 }} minWidth={{ xs: 64, md: 88 }} bgcolor="background.default" mx={1}>
          <Picture
            src={cartItem.extension_attributes.main_image_url}
            alt={cartItem.name}
            title=""
            op="fit"
            params={{
              xs: {
                width: 64,
                height: 64,
              },
              md: {
                width: 88,
                height: 88,
              },
            }}
          />
        </Box>
        <Box width={1}>
          <Box display="flex" justifyContent="space-between" alignItems="baseline">
            <Typography variant="caption" color="textSecondary">
              {cartItem.extension_attributes.product_line}
            </Typography>
          </Box>
          <Typography variant="subtitle2">
            {cartItem.name}
          </Typography>
          <Box display="flex" justifyContent="space-between" alignItems="flex-end">
            <Box>
              <Typography variant="subtitle2" component="span">
                {formatPrice(calculatePrice)}
              </Typography>
              {PV > 0
                  && (
                  <Box ml={0.5} display="inline">
                    <Typography variant="caption" color="textSecondary">
                      <FormattedMessage {...appMessages.pvc} />
                      {PV}
                    </Typography>
                  </Box>
                  )}
            </Box>
            <QuantityAdjuster
              initialValue={quantity}
              onChange={handleQuantityAdjustment}
              disabled={updating || lockCheckout === 1 || isCartLock}
              allowZero
              small
            />
          </Box>
        </Box>
      </Box>

      <Divider component="hr" />

      <ConfirmRemoval
        open={showConfirm}
        title={<FormattedMessage {...appMessages.confirmRemovalOfItem} />}
        onClose={handleCloseConfirm}
        onConfirm={handleRemoval}
        loading={pendingDeletion}
      />
    </>
  );
}

MiniCartListItem.defaultProps = {
  cartItems: [],
};

MiniCartListItem.propTypes = {
  toggleLock1000: PropTypes.func,
  cartItems: PropTypes.array,
  isCartLock: PropTypes.bool,
  cartItem: PropTypes.object.isRequired,
  isChecked: PropTypes.bool.isRequired,
  savingItems: PropTypes.array.isRequired,
  toggleCartItem: PropTypes.func.isRequired,
  updateCartItem: PropTypes.func.isRequired,
  removeCartItem: PropTypes.func.isRequired,
  proceedingToCheckout: PropTypes.func,
  lockCheckout: PropTypes.number,
};

const mapStateToProps = (state, ownProps) => createStructuredSelector({
  isChecked: makeSelectCartItemIsSelected(ownProps.cartItem.item_id),
  savingItems: makeSelectSavingCartItems(),
  isCartLock: makeSelectLockCart(),
  cartItems: makeSelectCartItems(),
  lockCheckout: makeSelectLockCheckout(),
});

function mapDispatchToProps(dispatch) {
  return {
    toggleLock1000: (lock) => dispatch(actions.toggleLock1000(lock)),
    toggleCartItem: (itemId) => dispatch(actions.toggleCartItem(itemId)),
    updateCartItem: (cartItem) => dispatch(actions.updateCartItem(cartItem)),
    removeCartItem: (cartItemId) => dispatch(actions.removeCartItem(cartItemId)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default withConnect(MiniCartListItem);

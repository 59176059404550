/**
 *
 * Price
 * sync
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import isNull from 'lodash/isNull';
import currency from 'currency.js';
import Box from '@material-ui/core/Box';
import { styled, useTheme } from '@material-ui/core/styles';

export const formatPrice = (input, symbol = '¥') => {
  const decimals = input % 1 === 0 ? 0 : 2;
  const output = currency(input, {
    symbol,
    separator: ',',
    precision: decimals,
    formatWithSymbol: true,
  });
  return output.format();
};

const FinalPrice = styled(({ ...other }) => <span {...other} />)({
  display: 'inline-flex',
  color: (props) => props.color,
  fontSize: (props) => (Number.isInteger(props.size) ? `${props.size}px` : props.size),
});

const OriginalPrice = styled(({ ...other }) => <span {...other} />)({
  display: 'inline-flex',
  marginLeft: 8,
  color: (props) => props.color,
  fontSize: (props) => (Number.isInteger(props.size) ? `${props.size * 0.75}px` : props.size),
  fontWeight: 400,
  textDecoration: 'line-through',
});

function Price({
  price, originalPrice, size, secondarySize, color, secondaryColor, ...other
}) {
  const theme = useTheme();
  const ps = size || theme.typography.h5.fontSize;
  let pc = theme.palette.error.main;
  if (color) {
    if (color === 'primary' || color === 'secondary') pc = theme.palette[color].main;
    if (color === 'textPrimary' || color === 'textSecondary') pc = '#006dba';
    if (color === 'initial' || color === 'inherit') pc = color;
    if (color === 'grey' || color === 'grey') pc = theme.palette.text.secondary;
  }
  const ss = secondarySize || theme.typography.subtitle1.fontSize;
  let sc = theme.palette.text.secondary;
  if (secondaryColor) {
    if (secondaryColor === 'primary' || secondaryColor === 'secondary') sc = theme.palette[color].main;
    if (secondaryColor === 'textPrimary' || secondaryColor === 'textSecondary') sc = theme.palette.text[color];
    if (secondaryColor === 'initial' || secondaryColor === 'inherit') sc = color;
  }

  const fp = parseFloat(price, 10);
  const op = parseFloat(originalPrice, 10);
  return (
    <Box {...other} component="span">
      <FinalPrice size={ps} color={pc}>{formatPrice(fp === 0 ? op : fp)}</FinalPrice>
      {!isNull(originalPrice) && fp !== op && fp !== 0 && <OriginalPrice size={ss} color={sc}>{formatPrice(parseFloat(originalPrice, 10))}</OriginalPrice>}
    </Box>
  );
}

Price.defaultProps = {
  originalPrice: null,
};

Price.propTypes = {
  price: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  originalPrice: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  size: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  secondarySize: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  color: PropTypes.string,
  secondaryColor: PropTypes.string,
};

export default memo(Price);

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useUpdateSmCustomer } from 'containers/SmCustomer/queries';
import isEmpty from 'lodash/isEmpty';
import { FormattedMessage, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useUploadImagesForSMUpgrade } from 'containers/SocialMarketerUpgrade/queries';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import messages from 'containers/SocialMarketerUpgrade/messages';
import FormTextField from 'components/Inputs/FormTextField';
import ImageUpload from 'components/Inputs/ImageUpload';
import Buttons from 'components/Buttons/Buttons';

function CheckForm({
  customAttributes, customer, handleLists,
}) {
  const addAttachments = useUploadImagesForSMUpgrade();
  const findValue = (code) => {
    if (customAttributes.find((a) => a.attribute_code === code)) {
      return customAttributes.find((a) => a.attribute_code === code).value;
    }
    return '';
  };
  const updateSmCustomer = useUpdateSmCustomer();
  const [reason, setReason] = useState('');
  const [smStatus, setSmStatus] = useState(0);
  const [loading, setLoading] = useState(false);
  const [lllApproved, setLLCApproved] = useState(false);
  const [openLLCNumberInput, setOpenChangeLLCNumberInput] = useState(false);
  const [IBENumber, setIBENumber] = useState('');
  const [setLLCAttachment] = useState('');
  const intl = useIntl();

  const handleChangeReason = (e) => {
    setReason(e.target.value);
  };
  const handeChangeIBE = (e) => {
    setIBENumber(e.target.value);
  };
  const handleAttachBusinessLicense = (images) => {
    setLLCAttachment(images[0]);
    // eslint-disable-next-line camelcase
    const { name, type, base64_encoded_data } = images[0];
    const payload = { imageContent: { name, type, base64_encoded_data } };
    const key = 'llc_license';
    addAttachments.mutate({ key, payload });
  };
  const handleRemoveBusinessLicense = (images) => {
    setLLCAttachment(images[0]);
  };
  useEffect(() => {
    setReason(findValue('llc_reason'));
  }, []);

  const handleSubmit = () => {
    setLoading(true);

    const customerAttributes = [
      { attribute_code: 'mobile', value: findValue('mobile') },
      { attribute_code: 'mobile_prefix', value: findValue('mobile_prefix') },
    ];

    if (reason) {
      customerAttributes.push({ attribute_code: 'llc_reason', value: reason });
    }
    if (IBENumber) {
      customerAttributes.push({ attribute_code: 'llc_business_id', value: IBENumber });
    }

    if (smStatus) {
      customerAttributes.push({ attribute_code: 'llc_status', value: smStatus });
    }

    const data = customer;
    data.custom_attributes = customerAttributes;
    delete data.llc_status;
    updateSmCustomer.mutate({
      id: customer.id,
      data,
    }, {
      onSuccess: () => {
        setLoading(false);
        handleLists({ page: 1 });
        setReason('');
      },
    });
  };
  const handleSetSmStatus = (value) => {
    setSmStatus(value);
  };
  return (
    <>
      <Box display="flex" width={1} borderRadius={1} bgcolor="background.default" my={0.5} py={2.5} px={3} alignItems="flex-start">
        <Box width={0.2} display="flex" mt={4.5} alignItems="center">
          <FontAwesomeIcon size="lg" color="inherit" icon={['fas', 'balance-scale']} />
          <Typography style={{ marginLeft: 20 }} color="inherit" variant="subtitle2">LLC信息</Typography>
        </Box>
        <Box width={0.4} mx={2}>
          <Box>
            <Typography variant="subtitle1" color="textSecondary" component="span">
              {`${intl.formatMessage(messages.fullLegalName)}:  ${findValue('llc_representative_name')}`}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" color="textSecondary" component="span">
              {`${intl.formatMessage(messages.idNumber)}:  ${findValue('llc_representative_id')}`}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1" color="textSecondary" component="span">
              {`${intl.formatMessage(messages.companyName)}:  ${findValue('llc_business_name')}`}
            </Typography>
          </Box>
          <Box mb={1}>
            <Box display="flex" alignItems="center">
              <Typography variant="subtitle1" color="textSecondary" component="span">
                {`${intl.formatMessage(messages.businessLicenseNumber)}:  ${findValue('llc_business_id')}`}
              </Typography>
              <Buttons
                messageId={<FontAwesomeIcon icon={['fal', openLLCNumberInput ? 'check' : 'edit']} />}
                color="primary"
                style={{ marginLeft: 16 }}
                onClick={() => setOpenChangeLLCNumberInput(!openLLCNumberInput)}
              />
            </Box>
            {openLLCNumberInput && (
              <FormTextField
                onChange={handeChangeIBE}
                variant="outlined"
                value={IBENumber}
                placeholder={intl.formatMessage(messages.ibeid)}
                id="llc_business_id"
              />
            )}
          </Box>
          <ImageUpload
            image={
              !isEmpty(JSON.parse(findValue('llc_license')))
                ? JSON.parse(findValue('llc_license'))[
                  JSON.parse(findValue('llc_license')).length - 1
                ]
                : undefined
            }
            label={<FormattedMessage {...messages.businessLicense} />}
            onChange={handleAttachBusinessLicense}
            onRemove={handleRemoveBusinessLicense}
          />
        </Box>
        <Box width={0.4}>
          <Box display="flex" mb={2}>
            <Buttons
              variant="outlined"
              messageId={<FormattedMessage {...messages.reject} />}
              onClick={() => {
                handleSetSmStatus(2);
                setLLCApproved(false);
              }}
              startIcon={<FontAwesomeIcon icon={['fal', 'times']} />}
              fullWidth
              color="secondary"
            />
            {parseInt(findValue('llc_status'), 10) !== 1 && (
              <Buttons
                variant="outlined"
                messageId={<FormattedMessage {...messages.pass} />}
                startIcon={<FontAwesomeIcon icon={['fal', 'check']} />}
                onClick={() => {
                  setLLCApproved(true);
                  handleSetSmStatus(1);
                  setReason('');
                }}
                fullWidth
                color="primary"
                style={{ marginLeft: 8 }}
              />
            )}
          </Box>
          {smStatus === 2 && (
            <FormTextField
              onChange={handleChangeReason}
              variant="outlined"
              placeholder={intl.formatMessage(messages.rejectReason)}
              value={reason}
              id="rejested_reason"
            />
          )}
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end" my={2}>
        <Buttons
          variant="contained"
          disabled={openLLCNumberInput || (!lllApproved && reason === '') || loading}
          loading={loading}
          onClick={handleSubmit}
          style={{ width: 395 }}
          messageId={<FormattedMessage {...messages.update} />}
        />
      </Box>
    </>
  );
}

CheckForm.propTypes = {
  customAttributes: PropTypes.array,
  customer: PropTypes.object,
  handleLists: PropTypes.func,
};

export default CheckForm;

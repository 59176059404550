/* eslint-disable no-console */
/**
 * Call various actions to set up the app
 */

import store from 'store';
import {
  takeLatest, takeLeading, call, put, all,
} from 'redux-saga/effects';
import get from 'lodash/get';
import { getAuthorization } from 'utils/Auth';
import { actions as cartActions } from 'containers/CartProvider/actions';
import { actions as checkoutActions } from 'containers/CheckoutPage/actions';
import { getStoreConfigsQuery } from 'containers/App/queries';
import { getConfigQuery } from 'containers/PrivacyCenterPage/queries';
import { constants as globalConstants, actions as globalActions } from './actions';

export function* initApp() {
  yield put(globalActions.getStoreConfigs());
  yield put(cartActions.getCartQuote());

  const auth = getAuthorization();

  if (auth.type === 'user') {
    yield put(checkoutActions.getCheckoutQuote());
  }
}

/**
 * Store configs request/response handler
 */
export function* handleGetStoreConfigs(action) {
  // Fetch store configs from local storage
  let storeConfigs = store.get('store_configs');

  try {
    if (action.force === true || !storeConfigs) {
      const data = yield call(getStoreConfigsQuery);
      storeConfigs = get(data, 'storeConfig', {});
      store.set('store_configs', storeConfigs, new Date().getTime() + 86400000);
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

export function* handleGetGDPRConfigs() {
  try {
    const data = yield call(getConfigQuery);
    yield put(globalActions.setGDPRConfig({ enabled: data.config.general.enabled === '1' }));
    yield put(globalActions.setCookieConfig({ enabled: data.config.cookie.enabled === '1' }));
  } catch (err) {
    console.log('Error: ', err);
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* rootSaga() {
  yield all([
    takeLeading(globalConstants.SET_UP_APP, initApp),
    takeLatest(globalConstants.GET_STORE_CONFIGS_REQUEST, handleGetStoreConfigs),
    takeLatest(globalConstants.GET_GDPR_CONFIG, handleGetGDPRConfigs),
  ]);
}

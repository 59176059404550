/* eslint-disable no-underscore-dangle */
/**
 *
 * Consent Forms
 *
 */

import React, { useEffect, useState } from 'react';

import {
  Box,
  Typography,
  makeStyles,
  Hidden,
} from '@material-ui/core';
import Buttons from 'components/Buttons/Buttons';
import useNotificationSnackbar from 'utils/Hooks/useNotificationSnackbar';
import { useAcceptForm } from 'containers/PrivacyCenterPage/queries';

const useStyles = makeStyles(() => ({
  root: {
    position: 'fixed',
    bottom: 0,
    backgroundColor: '#ffffff',
    zIndex: 100,
    width: '100%',
    left: 0,
    height: 144,
    justifyContent: 'center',
    boxShadow: '0px 1px 10px 0px rgba(0, 40, 89, 0.15)',
  },
  mobile_root: {
    position: 'fixed',
    bottom: 0,
    backgroundColor: '#ffffff',
    zIndex: 1200,
    width: '100%',
    left: 0,
    justifyContent: 'center',
    boxShadow: '0px 1px 10px 0px rgba(0, 40, 89, 0.15)',
  },
  content: {
    overflowY: 'auto',
  },
  normal: {
    '& button': {
      height: 48,
      width: 360,
      backgroundColor: '#00A7E0',
      color: '#ffffff',
    },
  },
  mobileDialog: {
    '& .MuiDialog-paper': {
      padding: 16,
    },
  },
}));

function CookieUpdateBottom() {
  const classes = useStyles();
  const [showDialog, setShowDialog] = useState(false);
  const notificationSnackbar = useNotificationSnackbar();
  const postConsent = useAcceptForm();

  useEffect(() => {
    if (!document.cookie.includes('consent_cookie1')) {
      setShowDialog(true);
    }
  }, []);

  const acceptConsent = () => {
    postConsent.mutate({ type: 'consent_cookie' }, {
      onSuccess: () => {
        setShowDialog(false);
        document.cookie = 'consent_cookie1=1';
        // notificationSnackbar(res.message, 'info');
      },
      onFail: (err) => {
        setShowDialog(false);
        notificationSnackbar(err, 'warning');
      },
    });
  };

  return (
    <>
      <Hidden mdDown>
        <Box
          display={showDialog ? 'flex' : 'none'}
          className={classes.root}
        >
          <Box display="flex" className={classes.content} px={10}>
            <Box mr={8} display="flex" justifyContent="center" flexDirection="column">
              <Typography variant="h4" style={{ fontSize: 16, fontWeight: 700 }}>
                使用Cookie的说明
              </Typography>
              <Typography variant="h4" style={{ fontSize: 16, fontWeight: 400 }}>
                我们和我们的合作伙伴使用 cookie 来改善您的体验并在网站上为您提供个性化的功能，衡量我们服务的有效性，并在您浏览网络或跨设备与我们互动时根据您的兴趣定制广告和内容。
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" width="360px">
              <Buttons
                variant="contained"
                onClick={acceptConsent}
                messageId="我都接受"
              />
            </Box>
          </Box>
        </Box>
      </Hidden>
      <Hidden lgUp>
        <Box display={showDialog ? 'flex' : 'none'} className={classes.mobile_root} py={2}>
          <Box className={classes.content} px={2}>
            <Box mb={2}>
              <Typography variant="h4" style={{ fontSize: 16, fontWeight: 700, marginBottom: 8 }}>
                使用Cookie的说明
              </Typography>
              <Typography variant="h4" style={{ fontSize: 16, fontWeight: 400 }}>
                我们和我们的合作伙伴使用 cookie 来改善您的体验并在网站上为您提供个性化的功能，衡量我们服务的有效性，并在您浏览网络或跨设备与我们互动时根据您的兴趣定制广告和内容。
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Buttons
                variant="contained"
                onClick={acceptConsent}
                messageId="我都接受"
              />
            </Box>
          </Box>
        </Box>
      </Hidden>
    </>
  );
}

export default CookieUpdateBottom;

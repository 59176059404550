/**
 *
 * Asynchronously loads the component for OrderDetail
 *
 */

import loadable from 'utils/loadable';

export const OrderRefundApplicationPage = loadable(() => import('./OrderRefundApplicationPage'));
export const OrderRefundCourierPage = loadable(() => import('./OrderRefundCourierPage'));

export default loadable(() => import('./index'));

import React from 'react';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Proptypes from 'prop-types';
import useStyle from 'containers/SmInvoice/style';

export function RadioSelect({
  title, subTitle, value, onChange, icon,
}) {
  const classes = useStyle();
  return (
    <Box>
      <FormControl component="fieldset" className={classes.formControl}>
        <RadioGroup
          aria-label="selected-shipping-method"
          name="shipping-method"
          value={value}
          className={classes.formGroupRoot}
        >
          <Box className={classes.radioIcon}>{icon}</Box>
          <FormControlLabel
            control={<div />}
            label={<>{title}</>}
            classes={{ label: classes.formControlLabelTitle }}
          />
          <FormControlLabel
            control={<div />}
            label={<>{subTitle}</>}
            classes={{ label: classes.formControlLabel }}
          />
          <FormControlLabel
            value={1}
            onClick={() => onChange(1)}
            control={<Radio color="secondary" />}
            label={<>是</>}
            classes={{ label: classes.formControlLabel }}
          />
          <FormControlLabel
            value={0}
            onClick={() => onChange(0)}
            control={<Radio color="secondary" />}
            label={<>否</>}
            classes={{ label: classes.formControlLabel }}
          />
        </RadioGroup>
      </FormControl>
    </Box>
  );
}

RadioSelect.propTypes = {
  title: Proptypes.string,
  subTitle: Proptypes.string,
  value: Proptypes.number,
  onChange: Proptypes.func,
  icon: Proptypes.object,
};
export default RadioSelect;

/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

function CheckboxIcons({ type }) {
	switch (type) {
		case 'unchecked':
			return (
				<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26"><g className="nc-icon-wrapper" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" fill="#5c6166" stroke="#5c6166"><path d="M20,22H4a2,2,0,0,1-2-2V4A2,2,0,0,1,4,2H20a2,2,0,0,1,2,2V20A2,2,0,0,1,20,22Z" fill="none" stroke="#585858" strokeMiterlimit="10"/></g></svg>
			);

		case 'checked':
			return (
				<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26"><g className="nc-icon-wrapper" fill="#5c6166"><path d="M20,1H4A3,3,0,0,0,1,4V20a3,3,0,0,0,3,3H20a3,3,0,0,0,3-3V4A3,3,0,0,0,20,1ZM18.707,7.707l-9,9a1,1,0,0,1-1.414,0l-3-3a1,1,0,0,1,1.414-1.414L9,14.586l8.293-8.293a1,1,0,1,1,1.414,1.414Z" fill="#006DBA"/></g></svg>
			);

		case 'checkedCircle':
			return (
				<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><g className="nc-icon-wrapper" fill="#5c6166"><path d="M12,0A12,12,0,1,0,24,12,12.035,12.035,0,0,0,12,0ZM10,17.414,4.586,12,6,10.586l4,4,8-8L19.414,8Z" fill="#006DBA"/></g></svg>
			);

		case 'uncheckedCircle':
			return (
				<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><g className="nc-icon-wrapper" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.8" fill="#5c6166" stroke="#5c6166"><circle cx="12" cy="12" r="11" fill="none" stroke="#585858" strokeMiterlimit="10"/></g></svg>
			);
	}
};

CheckboxIcons.propTypes = {
	type: PropTypes.string,
};

export default CheckboxIcons;
/*
 *
 * Authorization reducer
 *
 */
import produce from 'immer';
import { constants } from './actions';

export const DEFAULT_TIMER_DURATION = process.env.PROJECT_ENV === 'development' ? 1 : 30;

export const initialState = {
  token: '',
  verificationCode: '',
  verificationCodeTimer: null,
};

/* eslint-disable default-case, no-param-reassign */
const authorizationReducer = (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case constants.SET_AUTH:
      draft.type = action.payload.type;
      draft.token = action.payload.token;
      break;

    case constants.SET_LOG_IN_ID:
      draft.accountIdentifier = action.payload;
      break;

    case constants.CLEAR_LOG_IN_INFO:
      draft.verificationCode = '';
      break;

    case constants.START_TIMER:
      draft.verificationCodeTimer = (action.payload && action.payload.secs) || DEFAULT_TIMER_DURATION;
      break;

    case constants.STOP_TIMER:
      draft.verificationCodeTimer = null;
      break;

    case constants.TICK:
      draft.verificationCodeTimer = action.payload;
      break;

    default:
      break;
  }
});

export default authorizationReducer;

/* eslint-disable consistent-return */
import store from 'store';
import currency from 'currency.js';
import dayjs from 'dayjs';
import isUndefined from 'lodash/isUndefined';
import isNull from 'lodash/isNull';
import isEmpty from 'lodash/isEmpty';
import indexOf from 'lodash/indexOf';
import without from 'lodash/without';
import get from 'lodash/get';
import placeholder from '../images/placeholder.png';

/**
 * Toggle (add or remove) item in array
 *
 * @param {array} array         The array in which the item should be toggled
 * @param {string} item         The input to toggle inside the collection
 *
 * @returns {*[]}               Returns the new array in which the item as been toggled
 */
export const toggleItemInArray = (collection, item) => {
  const index = indexOf(collection, item);
  if (index !== -1) {
    return without(collection, item);
  }
  return [...collection, item];
};

/**
 * Reverse an array
 *
 * @param {array} array         The array to be reversed
 *
 * @returns {*[]}               Returns the new reversed array
 */
/* eslint-disable no-unused-vars, prefer-const, no-plusplus */
export const reverseArray = (array) => {
  let ar = array;
  let len = ar.length;
  let forwardI = 0;
  while (forwardI < len) {
    let end = (len - 1) - forwardI;
    ar.push(ar[end]);
    ar.splice(end, 1);
    forwardI++;
  }
  return ar;
};
/* eslint-enable */

/**
 * Return a formatted string with swapped parameters
 *
 * @param {string} input      The string that needs to be formatted
 * @param {array} parameters  The array of parameters that will used to swap variables in the input
 *
 * @return {string}           Returns the formatted string
 */
export const sprintf = (input, parameters) => {
  if (Array.isArray(parameters)) {
    /* eslint-disable-next-line no-plusplus, no-param-reassign */
    return input.replace(/%(\d+)/g, (_, m) => parameters[--m]);
  }
  if (parameters) {
    return input.replace(/%fieldName/g, parameters.fieldName);
  }
  return input;
};

/**
 * Parse date and return in the user's local timezone
 *
 * @param {(string|number)} input    The input date, should be UTC
 *
 * @return {string}                  Returns the localised date
 */
export const getLocalTime = (input) => dayjs.utc(input).local();

/**
 * Format price
 *
 * @param {(string|number)} input    The string or number that needs to be formatted
 * @param {string} symbol            The currency symbol to be used for formatting
 * @param {number} decimals          The amount of decimals in the formatted price
 *
 * @return {string}                  Returns the formatted price
 */
export const formatPrice = (input, symbol = '¥') => {
  const decimal = /^1(.+)1$/.exec((1.1).toLocaleString('zh'))[1];
  const decimals = input % 1 === 0 ? 0 : 2;
  const output = currency(input, {
    symbol,
    separator: decimal === '.' ? ',' : '.',
    decimal,
    precision: decimals,
    formatWithSymbol: true,
  });
  return output.format();
};

/**
 * Format product weight
 *
 * @param {(string|number)} weight      The string or number that needs to be formatted
 * @param {boolean} small               Whether to use the smaller version of the unit: ounce
 *
 * @return {string}                     Returns the formatted price
 */
export const formatWeight = (weight, small = false) => {
  const configs = store.get('store_configs');
  const unit = get(configs, 'weight_unit', 'kgs');

  if (small && unit === 'kgs') return `${weight * 1000}g`;
  if (small && unit === 'lb') return `${weight * 16}oz`;

  return `${weight}${unit}`;
};

/**
 * Format product volume
 *
 * @param {(string|number)} volume      The string or number that needs to be formatted
 *
 * @return {string}                     Returns the formatted volume
 */
export const formatVolume = (volume) => {
  const unit = 'mL';

  return `${volume}${unit}`;
};

/**
 * Parse the media_gallery_entries array from GQL and return a standardised object with a single image.
 * Includes a fallback to a product placeholder image.
 *
 * @param {Array} entries                       Array of product images
 * @param {string} name                         Optional string to override the image label
 *
 * @returns {{file: string, label: string}}
 */
export const parseMediaGalleryEntries = (entries = [], name = '') => {
  let entry = { file: placeholder, label: name };
  if (entries && Array.isArray(entries) && entries.length) {
    entry = {
      file: process.env.PRODUCT_MEDIA + entries[0].file,
      label: entries[0].label ? entries[0].label : name,
    };
  }
  if (entries && typeof entries === 'string') {
    entry.file = entries;
  }
  return entry;
};

/**
 * Extend product image file path with a base URL pointing to the proper pub/media folder
 *
 * @param {string} file         File path of the image, starting from catalog/product/
 * @param {bool} leadingSlash   Include a slash before the relative path
 *
 * @returns {string}            Full public file URL
 */
export const parseProductImage = (file, leadingSlash = true) => ((isUndefined(file) || isNull(file))
  ? placeholder : `${process.env.PRODUCT_MEDIA}${leadingSlash ? '/' : ''}${file}`);

/**
 * Extend category image file path with a base URL pointing to the proper pub/media folder
 *
 * @param {string} file        File path of the image, starting from catalog/category/
 * @param {bool} leadingSlash   Include a slash before the relative path
 *
 * @returns {string}           Full public file URL
 */
export const parseCategoryImage = (file, leadingSlash = true) => ((isUndefined(file) || isNull(file))
  ? placeholder : `${process.env.CATEGORY_MEDIA}${leadingSlash ? '/' : ''}${file}`);

/**
 * Parse a product object for bundle options and return a spreadable object that can be used
 * to add the product to the cart.
 *
 * @param {object} product      Product fetched from GQL
 *
 * @returns {object}
 */
export const parseBundleOptions = (product) => {
  if (product.type_id === 'bundle' && product.items) {
    return ({
      product_option: {
        extension_attributes: {
          bundle_options: product.items.map((i) => ({
            option_id: i.option_id,
            option_qty: i.options[0].qty,
            option_selections: [i.options[0].id],
          })),
        },
      },
    });
  }
  return {};
};

/**
 * Parse an HTML string to strip out unwanted tags. Removes all attributes while ignoring the exceptions.
 *
 * @param {string} html             HTML to strip
 * @param {string} exceptions       HTML attributes to ignore while parsing
 *
 * @returns {string}                Parsed HTML
 */
/* eslint-disable no-param-reassign, func-names */
export const parseHTML = (html, exceptions = ['src', 'alt', 'href', 'controls']) => {
  const walk = function walk(node, func) {
    func(node);
    node = node.firstChild;
    while (node) {
      walk(node, func);
      node = node.nextSibling;
    }
  };
  const wrapper = document.createElement('div');
  wrapper.innerHTML = html;
  walk(wrapper, (element) => {
    if (element.hasAttributes && element.hasAttributes() && element.removeAttribute) {
      const attrs = element.attributes;
      for (let i = attrs.length - 1; i >= 0; i -= 1) {
        if (exceptions.indexOf(attrs[i].name) === -1) {
          element.removeAttribute(attrs[i].name);
        }
      }
    }
  });
  return wrapper.innerHTML;
};
/* eslint-enable */

/**
 * Luhn algorithm in JavaScript: validate credit card number supplied as string of numbers
 *
 * @param {string} luhn     Credit card number
 *
 * @returns {boolean}       Return result of validity check
 */
export const luhnCheck = (luhn) => {
  let ca;
  let sum = 0;
  let mul = 0;
  let len = luhn.length;
  // eslint-disable-next-line no-plusplus
  while (len--) {
    // eslint-disable-next-line no-bitwise
    ca = parseInt(luhn.charAt(len), 10) << mul;
    sum += ca - (ca > 9) * 9; // sum += ca - (-(ca>9))|9
    // 1 <--> 0 toggle.
    // eslint-disable-next-line no-bitwise
    mul ^= 1; // mul = 1 - mul;
  }
  return (sum % 10 === 0) && (sum > 0);
};

/**
 * Convert base64 data to a blob object
 *
 * @param {string} base64
 * @param {string} mimetype
 * @param {number} slicesize    specified range of bytes of the blob
 *
 * @returns {object}            Blob
 */
export const base64ToBlob = (base64, mimetype, slicesize) => {
  const mimeType = mimetype || '';
  const sliceSize = slicesize || 512;
  if (!window.atob || !window.Uint8Array) {
    return null;
  }
  const bytechars = atob(base64);
  const bytearrays = [];
  for (let offset = 0; offset < bytechars.length; offset += sliceSize) {
    const slice = bytechars.slice(offset, offset + sliceSize);
    const bytenums = new Array(slice.length);
    for (let i = 0; i < slice.length; i += 1) {
      bytenums[i] = slice.charCodeAt(i);
    }
    const bytearray = new Uint8Array(bytenums);
    bytearrays[bytearrays.length] = bytearray;
  }
  return new Blob(bytearrays, { type: mimeType });
};

/**
 * Function to test for a specific browser. Add more cases to allow testing for other browsers.

 * @param {'ie' | 'safari-desktop' | 'wechat'} browserToTest

 * @returns {boolean}
 */
export const detectBrowser = (browserToTest) => {
  const browser = browserToTest.toLowerCase().split(' ').join('');
  const uA = navigator.userAgent;
  const { vendor } = navigator;
  switch (browser) {
    case 'ie':
      return uA.indexOf('rv') > -1 && uA.indexOf('like Gecko') > -1;
    case 'safari-desktop':
      return /Safari/i.test(uA) && /Apple Computer/.test(vendor) && !/Mobi|Android/i.test(uA);
    case 'wechat':
      return /micromessenger/i.test(uA);
    default:
      return false;
  }
};

/**
 * Function to resize image . magento only support int
 *
 * @param {string} image
 * @param {array} size
 *
 * @returns {string}
 */
export const resizeImage = (image, size) => {
  let imageSrc = image;
  if (size && !isEmpty(size) && !imageSrc.startsWith('data:image')) {
    const width = parseInt((Math.ceil(size[0]) * window.devicePixelRatio), 10);
    const height = parseInt((Math.ceil(size[1]) * window.devicePixelRatio), 10);
    imageSrc = `${image}?w=${width}&h=${height}`;
  }
  return imageSrc;
};
/**
 * Function to check China phone number
 * @param phone
 * @returns {boolean}
 */

export const checkPhone = (phone) => {
  if (!(/^1[3456789]\d{9}$/.test(phone))) {
    return false;
  }
  return true;
};
/**
 * Function to format js string date
 * @param dateString
 * @returns {string}
 */
export const formatDate = (dateString) => {
  const fsp = getLocalTime(dateString);
  return fsp.format('YYYY年M月D日');
};

export const calTimeDifference = (dateString) => {
  const now = dayjs.utc();
  const fsp = getLocalTime(dateString);
  let diffInMilliSeconds = now.diff(fsp) / 1000;
  let difference = '';
  // calculate days
  const days = Math.floor(diffInMilliSeconds / 86400);
  diffInMilliSeconds -= days * 86400;

  // calculate hours
  const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
  diffInMilliSeconds -= hours * 3600;

  // calculate minutes
  const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
  diffInMilliSeconds -= minutes * 60;
  if (days > 0) {
    difference += (days === 1) ? `${days} 天前 ` : `${days} 天前`;
    return difference;
  }

  difference += (hours === 0 || hours === 1) ? `${hours} 小时前 ` : `${hours} 小时前`;

  // difference += (minutes === 0 || hours === 1) ? `${minutes} 分钟前` : `${minutes} 分钟前`;

  return difference;
};
/**
 * Function to format FlashSale string date
 * @param dateString
 * @returns {string}
 */
export const formatFlashSaleDate = (dateString) => {
  // const date = new Date(dateString);
  // const dateTimeFormat = new Intl.DateTimeFormat('zh', { year: 'numeric', month: 'short', day: '2-digit' });

  const now = dayjs.utc();
  const fsp = getLocalTime(dateString);
  const difference = '';
  if (!isNull(fsp) && now.isBefore(fsp)) {
    return fsp.format('M月D日HH:mm');
  }
  return difference;
};

// eslint-disable-next-line consistent-return
export const convertGroupIdToName = (groupId) => {
  // eslint-disable-next-line no-param-reassign
  groupId = parseInt(groupId, 10);
  if (groupId === 1) {
    return 'Guest';
  }
  if (groupId === 2) {
    return 'Customer';
  }
  if (groupId === 3) {
    return 'VIP';
  }
  if (groupId === 1) {
    return 'SM-Personal';
  }
  if (groupId === 5) {
    return 'SM-IBE';
  }
};

export const filterFlashSale = (product) => {
  const from = product.special_from_date;
  const to = product.special_to_date;
  const now = dayjs.utc();
  let fsp = getLocalTime(from);
  if (!isNull(fsp) && !fsp.isValid()) fsp = null;
  let tsp = getLocalTime(to);
  if (!isNull(tsp) && !tsp.isValid()) tsp = null;
  if (!isNull(fsp) && !isNull(tsp) && !now.isAfter(tsp)) {
    return true;
  }
  return false;
};

export const isInSpecialPrice = (product) => {
  const from = product.special_from_date;
  const to = product.special_to_date;
  const now = dayjs.utc();

  let fsp = getLocalTime(from);
  if (!isNull(fsp) && !fsp.isValid()) fsp = null;
  let tsp = getLocalTime(to);
  if (!isNull(tsp) && !tsp.isValid()) tsp = null;

  if (!isNull(fsp) && !isNull(tsp) && now.isAfter(fsp) && now.isBefore(tsp)) {
    return true;
  }
  return false;
};
export const convertCartItemId = (items) => {
  const itemIds = [];
  items.forEach((i) => {
    itemIds.push(i.item_id);
  });
  return itemIds;
};

/**
 * check if this product belong to this categories
 */
export const checkBelongCategories = (cateId, product) => {
  const result = product.categories.filter(((item) => parseInt(item.id, 10) === parseInt(cateId, 10)
  ));
  if (result.length > 0) {
    return true;
  }
  return false;
};

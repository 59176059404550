import { useQuery } from 'react-query';
import queryGetStoreConfigs from 'containers/App/gql/queryGetStoreConfigs';
import queryGetStoreCurrencies from 'containers/App/gql/queryGetStoreCurrencies';
import queryGetStoreCountries from 'containers/App/gql/queryGetStoreCountries';
import requestGql, { querySetting } from 'utils/requestGql';
import get from 'lodash/get';

export const getStoreConfigsQuery = () => requestGql(queryGetStoreConfigs);
export const getStoreCurrenciesQuery = () => requestGql(queryGetStoreCurrencies);
export const getStoreCountriesQuery = () => requestGql(queryGetStoreCountries);

export const useGetStoreConfig = () => useQuery('general.getStoreConfig',
  () => getStoreConfigsQuery().then((response) => get(response, 'storeConfig', [])),
  querySetting);

export const useGetStoreCurrencies = () => useQuery('general.getStoreCurrencies',
  () => getStoreCurrenciesQuery(),
  querySetting);

export const useGetStoreCountries = () => useQuery('general.getStoreCountries',
  () => getStoreCountriesQuery().then((response) => get(response, 'countries.[0].available_regions', [])),
  querySetting);

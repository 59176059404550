/*
 * Notification Messages
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.utils.Notifications';

export default defineMessages({
  dismiss: {
    id: `${scope}.dismiss`,
    defaultMessage: 'Dismiss',
  },
});

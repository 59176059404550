import { createSlice } from '@reduxjs/toolkit';
import { DEFAULT_LOCALE } from '../../i18n';

export const appState = createSlice({
  name: 'app',
  initialState: {
    locale: DEFAULT_LOCALE,
    showNotificationDialog: true,
    showCouponDialog: true,
    stickyHeader: false,
    showMinicart: false,
    category: 'all',
    showLoyaltyDialog: true,
  },
  reducers: {
    hideNotificationDialog: (state) => {
      state.showNotificationDialog = false;
    },
    hideCouponDialog: (state) => {
      state.showCouponDialog = false;
    },
    setStickyHeader: (state, action) => {
      state.stickyHeader = action.payload;
    },
    toggleMinicart: (state, action) => {
      state.showMinicart = action.payload;
    },
    setFilterCategory: (state, action) => {
      state.category = action.payload;
    },
    hideLoyaltyPointsDialog: (state) => {
      state.showLoyaltyDialog = false;
    },
    changeLanguage: (state, action) => {
      state.locale = action.payload;
    },
  },
});

export const {
  changeLanguage, hideNotificationDialog, hideCouponDialog, setStickyHeader,
  toggleMinicart, setFilterCategory, hideLoyaltyPointsDialog,
} = appState.actions;

export const selectLanguage = (state) => state.app.locale;
export const selectShowNotificationDialog = (state) => state.app.showNotificationDialog;
export const selectShowCouponDialog = (state) => state.app.showCouponDialog;
export const selectStickyHeader = (state) => state.app.stickyHeader;
export const selectShowMiniCart = (state) => state.app.showMinicart;
export const categoryFilterSelector = (state) => state.app.category;
export const selectShowLoyaltyDialog = (state) => state.app.showLoyaltyDialog;

export default appState.reducer;

import { useQuery, useMutation } from 'react-query';
import request from 'utils/request';
import { querySetting } from 'utils/requestGql';

export const listInvoice = (filter) => request(
  'GET',
  `/customer_invoice/admin?status=${filter.status}&page=${filter.page}&month=${filter.month}&page_size=${
    filter.page_size
  }&keywords=${filter.keywords}`,
);
export const updateInvoice = (payload) => request('PUT', `/customer_invoice/admin/${payload.id}`, {
  status: payload.data.status,
  invoice: payload.data.invoice,
  reject_reason: payload.data.reject_reason,
  reject_reason_other: payload.data.reject_reason_other,
  pay_at: payload.data.pay_at,
  files: payload.data.files,
});
export const submitAttachment = (payload) => request('POST', '/customer_invoice/image', payload);

export const useGetRefundList = (payload) => useQuery(['smInvoice.list'],
  () => listInvoice(payload), querySetting);

export const useUpdateInvoice = () => useMutation((payload) => updateInvoice(payload));

export const useSubmitAttachment = () => useMutation((payload) => submitAttachment(payload));

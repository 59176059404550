/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable camelcase */
/**
 * @api {MUTATION} updateUpgradeData Update data for the purpose of upgrading to SM
 * @apiGroup Membership
 */
export default function (data) {
  const arr = [];
  // eslint-disable-next-line no-unused-vars
  for (const property in data) {
    const value = typeof data[property] === 'number' ? `${data[property]}` : `"${data[property]}"`;
    arr.push(`${property}: ${value}`);
  }
  const customer = arr.join();
  return (
    `
    mutation {
          updateCustomer(
              input: { ${customer} }
              ) {
                customer {
                    id
                    bank_account_name
                    bank_account_number
                    bank_branch
                    bank_reason
                    bank_status
                    certificate_photo
                    certificate_reason
                    certificate_status
                    certificate_type
                    email
                    email_changed
                    firstname
                    id_back
                    id_front
                    id_reason
                    id_status
                    lastname
                    llc_business_id
                    llc_business_name
                    llc_license
                    llc_reason
                    llc_representative_id
                    llc_representative_name
                    llc_status
                    sm_status
                }
            }
        }
    `
  );
}

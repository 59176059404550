import { useQuery, useMutation } from 'react-query';
import GET_CUSTOMER from 'containers/UserProvider/gql/queryGetCustomer';
import requestGql from 'utils/requestGql';
import request from 'utils/request';
import { isSignedIn } from 'utils/Auth';
import get from 'lodash/get';

export const getCustomerQuery = () => requestGql(GET_CUSTOMER);
export const updateSM = (payload) => request('PUT', '/customers/sm', payload);
export const getNotificationSettings = () => request('GET', '/downline_alert/config');
export const updateNotificationSettings = (payload) => request('PUT', '/downline_alert/config', payload);
export const updateProfile = (payload) => request('PUT', '/customers/me', payload);
export const changePassword = (payload) => request('POST', '/customer/changepassword', payload);

export const useGetCustomer = () => useQuery('userProvider.customer',
  () => getCustomerQuery().then((response) => get(response, 'customer', [])),
  {
    initialStale: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: isSignedIn(),
  });

export const useGetNotificationConfigs = () => useQuery('userConfig.notification',
  () => getNotificationSettings().then((response) => ({
    mp: get(response, 'mp', 0),
    sms: get(response, 'sms', 0),
  })),
  {
    initialStale: true,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    enabled: isSignedIn(),
  });

export const useUpdateSM = () => useMutation((payload) => updateSM(payload));
export const useUpdateNotificationConfig = () => useMutation((payload) => updateNotificationSettings(payload));

export const useUpdateProfile = () => useMutation((payload) => updateProfile(payload));

export const useChangePassword = () => useMutation((payload) => changePassword(payload));

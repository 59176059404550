/**
 *
 * CenteredDialog
 * @param {bool} open (open dialog)
 * @param {node} children - include content of the modal
 *
 * @return {node} Returns CenteredDialog, all styling managed by Material UI Theme and classes (root and paper)
 *
 */

import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

function CenteredDialog({
  children,
  open,
  maxWidth = 'md',
  classes,
}) {
  return (
    <Dialog
      maxWidth={maxWidth}
      fullWidth
      open={open}
      TransitionComponent={Transition}
      classes={classes}
    >
      {children}
    </Dialog>
  );
}

CenteredDialog.propTypes = {
  open: PropTypes.bool,
  children: PropTypes.node,
  maxWidth: PropTypes.string,
  classes: PropTypes.object,
};

export default CenteredDialog;

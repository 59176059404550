/**
 *
 * Asynchronously loads the component for CatalogPage
 *
 */

import loadable from 'utils/loadable';

export const FlashSale = loadable(() => import('./FlashSalePage'));
export const SearchResultPage = loadable(() => import('./SearchResultPage'));

export default loadable(() => import('./index'));

/**
 * Customer endpoints for the cart quote
 * Courtesy of the partial-checkout module
 *
 * Checkout Flow
 * 1. Create quote
 * 2. Add product to quote
 * 3. Estimate shipping costs (get shipping methods)
 * 4. Add shipping and billing information to quote
 * 5. Get available payment methods for quote
 * 6. Place order ( add default payment method )
 * 7. Complete payment
 */

/**
 * Guest endpoints for the default cart quote
 */

import request from 'utils/request';

/**
 * @api {POST} /guest-carts Create guest cart
 * @apiGroup Cart
 */
export const createGuestCart = () => request('POST', '/guest-carts');

/**
 * @api {POST} /partial-checkout/cart/itemsArray Add a lot of item to cart quote
 * @apiGroup Cart
 */
export const addToCartQuoteArray = (payload) => request('POST', '/partial-checkout/cart/itemsArray', payload);
/**
 * @api {GET} /guest-carts/:token Fetch guest cart
 * @apiGroup Cart
 */
export const getGuestCart = (token) => request('GET', `/guest-carts/${token}`);

/**
 * @api {POST} /guest-carts/:token/items Add item to guest cart
 * @apiGroup Cart
 */
export const addToGuestCart = (payload, token) => request('POST', `/guest-carts/${token}/items`, payload);

/**
 * @api {PUT} /guest-carts/:token/items/:itemId Update guest cart item
 * @apiGroup Cart
 */
export const updateGuestCartItem = (itemId, payload, token) => request('PUT', `/guest-carts/${token}/items/${itemId}`, payload);

/**
 * @api {DELETE} /guest-carts/:token/items/:itemId Delete item from guest cart
 * @apiGroup Cart
 */
export const removeFromGuestCart = (itemId, token) => request('DELETE', `/guest-carts/${token}/items/${itemId}`);

/**
 * @api {GET} /partial-checkout/cart Fetch cart quote
 * @apiGroup Cart
 */
export const getCartQuote = () => request('GET', '/partial-checkout/cart');

/**
 * @api {POST} /partial-checkout/cart/items Add item to cart quote
 * @apiGroup Cart
 */
export const addToCartQuote = (payload) => request('POST', '/partial-checkout/cart/items', payload);

/**
 * @api {PUT} /partial-checkout/cart/items/:itemId Update cart quote item
 * @apiGroup Cart
 */
export const updateCartQuoteItem = (itemId, payload) => request('PUT', `/partial-checkout/cart/items/${itemId}`, payload);

/**
 * @api {DELETE} /partial-checkout/cart/items/:itemId Delete item from cart quote
 * @apiGroup Cart
 */
export const removeFromCartQuote = (itemId) => request('DELETE', `/partial-checkout/cart/items/${itemId}`);

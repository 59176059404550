import React, { useState } from 'react';
import Proptypes from 'prop-types';

import { useSubmitAttachment, useUpdateInvoice } from 'containers/SmInvoice/queries';
import { convertGroupIdToName } from 'utils/helpers';
import { useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import RadioSelect from 'containers/SmInvoice/components/RadioSelect';
import FormTextField from 'components/Inputs/FormTextField';
import messages from 'containers/SocialMarketerUpgrade/messages';
import Buttons from 'components/Buttons/Buttons';
import InputAdornment from '@material-ui/core/InputAdornment';
import Price from 'components/Order/Price';
import Attachments from 'containers/SocialMarketerUpgrade/components/Attachments';
import ImageUpload from 'components/Inputs/ImageUpload';
import useStyle from '../style';

function InvoiceForm({ item, refresh }) {
  const classes = useStyle();
  const intl = useIntl();
  const [files, setFiles] = useState('');
  const [open, setOpen] = useState(false);
  const [disable, setDisable] = useState(true);
  const [invoice, setInvoice] = useState(parseInt(item.invoice, 10));
  const [status, setStatus] = useState(parseInt(item.status, 10));
  const [payAt, setPayAt] = useState(item.pay_at ? 1 : 0);
  const [rejectReason, setRejectReason] = useState(item.reject_reason);
  const [rejectReasonOther, setRejectReasonOther] = useState(item.reject_reason_other);

  const submitAttachement = useSubmitAttachment();
  const submitInvoice = useUpdateInvoice();

  const handleOpen = () => {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  if (!item) {
    return <></>;
  }
  const getEditedData = () => {
    const data = [];
    if (status !== item.status) {
      data.status = status;
    }
    if (invoice !== item.invoice) {
      data.invoice = invoice;
    }
    if (rejectReason !== item.reject_reason) {
      data.reject_reason = rejectReason;
    }
    if (rejectReasonOther !== item.reject_reason_other) {
      data.reject_reason_other = rejectReasonOther;
    }
    if (payAt !== (item.pay_at ? 1 : 0)) {
      data.pay_at = payAt;
    }
    if (files !== item.files) {
      data.files = files;
    }
    if (data !== []) {
      setDisable(false);
    } else {
      setDisable(true);
    }
    data.status = status;
    return data;
  };
  const handleChangeInvoice = (value) => {
    setInvoice(value);
    if (value === 1) {
      setPayAt(0);
    }
    getEditedData();
  };
  const handleChangePayAt = (value) => {
    setPayAt(value);
    getEditedData();
  };
  const handleChangeStatus = (value) => {
    setStatus(value);
    getEditedData();
  };
  const handleSelectRejectReason = (e) => {
    setRejectReason(e.target.value);
    getEditedData();
  };
  const handleSubmit = () => {
    const data = getEditedData();
    setDisable(true);
    submitInvoice.mutate({
      id: item.id,
      data,
    }, {
      onSuccess: () => {
        refresh();
        setDisable(true);
      },
      onError: () => {
        setDisable(false);
      },
    });
  };

  const RejectReasons = [
    '写错付款方税号（包括英文字母大小写)',
    '使用与在付款方注册不一致的单位开具发票',
    '销售或服务内容写错,一般只能为"咨询服务"或"市场推广服务"或"产品推广服务"',
    '过纳税起点(月收入10万以上),需提供增值税专用发票,不能提供增值税普通发票',
    '漏盖发票专用章或发票章盖错',
    '发票在全国税务网上查寻不到',
    '超过公司收票时限(90天,特殊情况例外)',
    '发票在全国税务网上查寻不到',
    '开票金额与当月应发金额不一致',
  ];
  const convertStatus = () => {
    if (payAt) {
      return '<span style="color:#24a95d">已转账</span>';
    }
    if (status === 2) {
      return '<span style="color:#006dba">发票审核通过，待转账</span>';
    }
    if (status === 3) {
      return '<span style="color:#ea3f3f">不予通过</span>';
    }

    return '<span style="color:#ffb144">奖金数额已产生，待处理</span>';
  };
  const handleChangeRejectReason = (e) => {
    getEditedData();
    setRejectReasonOther(e.target.value);
  };
  const handleAttachBusinessLicense = (images) => {
    submitAttachement.mutate({ files: images[0] }, {
      onSuccess: (res) => {
        setFiles(res);
        getEditedData();
      },
    });
  };
  const handleRemoveBusinessLicense = () => {
    setFiles('');
  };
  if (status === 3 && (rejectReason === '-1' || (rejectReason === '0' && rejectReasonOther === null))) {
    if (disable === false) {
      setDisable(true);
    }
  }

  return (
    <Container className={classes.formContainer}>
      <ul className={`${classes.formRow}`}>
        <li>{item.customer_id}</li>
        <li>{item.month}</li>
        <li style={{ width: 160 }}>
          {item.firstname}
          {item.lastname}
        </li>
        <li>{convertGroupIdToName(item.group_id)}</li>
        <li>
          <Price price={item.amount} color="grey" size={16} />
        </li>
        <li>{item.invoice ? '是' : '否'}</li>
        <li
          style={{ width: 200 }}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: convertStatus() }}
        />
        <li>
          <Buttons
            onClick={() => handleOpen()}
            messageId={open ? '收起' : '展开'}
            icon={<FontAwesomeIcon icon={['fas', open ? 'caret-up' : 'caret-down']} />}
            color="primary"
          />
        </li>
      </ul>
      <Grid className={classes.form} style={{ display: open ? 'block' : 'none' }}>
        {open && (
          <Grid container className={classes.formContent}>
            <Grid item xs={6}>
              <RadioSelect
                icon={<FontAwesomeIcon icon={['fas', 'file']} />}
                title="用户奖金发票"
                subTitle="需要发票"
                value={parseInt(invoice, 10)}
                onChange={handleChangeInvoice}
              />

              <Grid>
                {item.files !== 0 && (
                  <Attachments
                    image={item.files}
                  />
                )}
                <ImageUpload
                  label="发票照片"
                  onChange={handleAttachBusinessLicense}
                  onRemove={handleRemoveBusinessLicense}
                />
              </Grid>
            </Grid>

            <Grid item xs={6}>
              {invoice === 1 && (
                <>
                  <Buttons
                    onClick={() => handleChangeStatus(3)}
                    messageId="不通过"
                    startIcon={<FontAwesomeIcon icon={['far', 'times']} />}
                    color="primary"
                    className={`${classes.handleBtn} ${classes.rejectBtn} ${
                      status === 3 ? classes.rejectBtnSelected : ''
                    }`}
                  />
                  <Buttons
                    onClick={() => handleChangeStatus(2)}
                    messageId="通过"
                    startIcon={<FontAwesomeIcon icon={['far', 'check']} />}
                    color="primary"
                    className={`${classes.handleBtn} ${classes.passBtn} ${
                      status === 2 ? classes.passBtnSelected : ''
                    }`}
                  />
                </>
              )}
              {status === 3 && invoice === 1 && (
                <>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <Select
                      className={`${classes.reasonField} ${classes.selectField}`}
                      onChange={handleSelectRejectReason}
                      value={rejectReason}
                    >
                      <MenuItem value="-1">请选择</MenuItem>
                      {RejectReasons.map((row) => (
                        <MenuItem key={row} value={row}>
                          {row}
                        </MenuItem>
                      ))}
                      <MenuItem value="0">其他</MenuItem>
                    </Select>
                    <FontAwesomeIcon
                      color="#a0a0a0"
                      icon={['far', 'stamp']}
                      size="lg"
                      style={{
                        position: 'absolute',
                        top: 22,
                        left: 24,
                        fontSize: 16,
                      }}
                    />
                  </FormControl>

                  {rejectReason === '0' && (
                    <FormControl className={classes.formControl}>
                      <FormTextField
                        className={classes.reasonField}
                        variant="outlined"
                        value={rejectReasonOther}
                        onChange={handleChangeRejectReason}
                        placeholder={intl.formatMessage(messages.rejectReason)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <FontAwesomeIcon icon={['far', 'sticky-note']} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        )}
        {((status === 2 && invoice === 1) || invoice === 0) && (
          <Box className={classes.payRadio}>
            <RadioSelect
              icon={<FontAwesomeIcon icon={['fas', 'envelope-open-dollar']} />}
              title="用户奖金转账"
              subTitle="已经转账"
              value={parseInt(payAt, 10)}
              onChange={handleChangePayAt}
            />
          </Box>
        )}
        <Box className={classes.toolBar}>
          <Buttons
            variant="contained"
            loading={disable}
            onClick={handleSubmit}
            messageId="确认修改"
            style={{ width: 180, float: 'right', margin: '16px 0 0 16px' }}
          />
        </Box>
      </Grid>
    </Container>
  );
}

InvoiceForm.propTypes = {
  item: Proptypes.object,
  refresh: Proptypes.func,
};

export default InvoiceForm;

import { zhCN } from '@material-ui/core/locale';
import { createTheme } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

const breakpoints = createBreakpoints({});

export const Primary = '#191919';
export const Secondary = '#585858';
export const Disabled = '#A0A0A0';
export const LightGreyBG = '#F2F4F5';
export const White = '#FFFFFF';
export const Transparent = 'transparent';
export const Black = '#000';

export const LifeVantageActionBlue = '#00A7E0';
export const LifeVantageStaticBlue = '#006DBA';
export const LifeVantageDeepBlue = '#002859';

export const Success = '#24A95D';
export const Warning = '#EA3F3F';
export const WarningBG = '#FFE7E9';
export const Pending = '#FFB144';
export const Wechat = '#1AAD19';

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: LifeVantageActionBlue,
      dark: LifeVantageDeepBlue,
      white: White,
      disabled: Disabled,
      pending: Pending,
      transparent: Transparent,
      black: Black,
    },
    secondary: {
      main: LifeVantageStaticBlue,
    },
    text: {
      primary: Primary,
      secondary: Secondary,
    },
    warning: {
      main: Warning,
      bg: WarningBG,
    },
    error: {
      main: Warning,
    },
    success: {
      main: Success,
    },
    disabled: {
      background: LightGreyBG,
    },
    default: {
      main: Secondary,
    },
    background: {
      primary: LifeVantageActionBlue,
      default: LightGreyBG,
    },
    divider: Disabled,
    orderStatus: {
      pendingPayment: Warning,
      pending_payment: Warning,
      fraud: Warning,
      pendingShipment: LifeVantageStaticBlue,
      pending_shipment: LifeVantageStaticBlue,
      inTransit: LifeVantageStaticBlue,
      in_transit: LifeVantageStaticBlue,
      onHold: Warning,
      on_hold: Warning,
      holded: LifeVantageStaticBlue,
      complete: Success,
      reviewed: Success,
      canceled: Disabled,
      closed: Disabled,
    },
    socialMedia: {
      wechat: Wechat,
      weixin: Wechat,
    },
  },
  typography: {
    fontFamily: [
      '"SourceHanSansSC"',
      '"Noto Sans CJK SC"',
      '"Hiragino Sans GB"',
      '"Roboto"',
      '"Helvetica"',
      '"Arial"',
      'sans-serif',
      '"STXihei"',
      '"华文细黑"',
      '"Microsoft YaHei"',
      '"Microsoft YaHei UI"',
      '"微软雅黑"',
      '"Heiti"',
      '"黑体"',
      '"冬青黑体简体中文"',
    ].join(','),
    h1: {
      fontSize: '1.75rem',
      lineHeight: 1.2,
      fontWeight: 800,
      letterSpacing: '0.05rem',
      [breakpoints.up('lg')]: {
        fontSize: '4rem',
        lineHeight: '5.5rem',
        fontWeight: 'bold',
        letterSpacing: '0.05rem',
      },
    },
    h2: {
      fontSize: '1.313rem',
      lineHeight: 1.2,
      fontWeight: 800,
      letterSpacing: '-0.06rem',
      [breakpoints.up('lg')]: {
        fontSize: '2.625rem',
        lineHeight: 1.42,
        fontWeight: 'bold',
        letterSpacing: '-0.06rem',
      },
    },
    h3: {
      fontSize: '1.313rem',
      lineHeight: 1.2,
      fontWeight: 800,
      letterSpacing: '-0.06rem',
      [breakpoints.up('lg')]: {
        fontSize: '1.75rem',
        lineHeight: '2.625rem',
        fontWeight: 'bold',
        letterSpacing: '0rem',
      },
    },
    h4: {
      fontSize: '1rem',
      lineHeight: 1.4,
      fontWeight: 300,
      [breakpoints.up('lg')]: {
        fontSize: '1.313rem',
        lineHeight: '2.25rem',
        fontWeight: 550,
        letterSpacing: '0.00735rem',
      },
    },
    body1: {
      fontSize: '0.75rem',
      lineHeight: 1.6,
      fontWeight: 300,
      [breakpoints.up('lg')]: {
        fontSize: '1rem',
        lineHeight: 1.6,
        fontWeight: 300,
      },
    },
    subtitle1: {
      fontSize: '1rem',
      lineHeight: 1.4,
      fontWeight: 300,
      [breakpoints.up('lg')]: {
        fontSize: '1rem',
        lineHeight: 1.6,
        fontWeight: 300,
      },
    },
    subtitle2: {
      fontSize: '1rem',
      lineHeight: 1.4,
      fontWeight: 800,
      [breakpoints.up('lg')]: {
        fontSize: '1rem',
        lineHeight: 1.6,
        fontWeight: 550,
      },
    },
    caption: {
      fontSize: '0.75rem',
      lineHeight: 1.4,
      fontWeight: 300,
      [breakpoints.up('lg')]: {
        fontSize: '0.75rem',
        lineHeight: 1.4,
        fontWeight: 300,
      },
    },
    button: {
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: 1.4,
      [breakpoints.up('lg')]: {
        fontWeight: 400,
        fontSize: '1rem',
        lineHeight: 1.4,
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 50,
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          fontSize: '16px',
        },
        'input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus input:-webkit-autofill, textarea:-webkit-autofill, textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus, select:-webkit-autofill, select:-webkit-autofill:hover, select:-webkit-autofill:focus': {
          border: 'none !important',
          '-webkit-text-fill-color': 'inherit !important',
          '-webkit-box-shadow': '0 0 0px 1000px transparent inset',
          transition: 'background-color 5000s ease-in-out 0s',
        },
        'input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0,
        },
        '*': {
          'scroll-behavior': 'smooth',
        },
        'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0,
        },
        /* Firefox */
        'input[type=number]': {
          '-moz-appearance': 'textfield',
        },
        'div .MuiInputBase-root button': {
          padding: '0px !important',
        },
        '::-webkit-scrollbar': {
          width: 0,
          background: 'transparent',
        },
        a: {
          textDecoration: 'none',
        },
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: '1rem',
      },
    },
    MuiStepConnector: {
      lineHorizontal: {
        borderTopWidth: 2,
        borderTopStyle: 'dashed',
        margin: '-16px -20px 0px -20px',
      },
    },
    MuiTablePagination: {
      toolbar: {
        display: 'flex',
        flexFlow: 'wrap',
      },
    },
    MuiChip: {
      root: {
        color: LifeVantageStaticBlue,
        backgroundColor: LightGreyBG,
        borderRadius: '3px',
      },
    },
    MuiStepIcon: {
      root: {
        '&$completed': {
          color: '#58e0c7',
        },
        '&$active': {
          color: '#00ad96',
        },
        [breakpoints.up('lg')]: {
          zIndex: 1,
          backgroundColor: White,
          color: LifeVantageStaticBlue,
          '&$completed': {
            color: LifeVantageStaticBlue,
          },
          '&$active': {
            color: '#00ad96',
          },
        },
      },
      text: {
        fontWeight: 500,
        fill: 'white',
      },
    },
    MuiDivider: {
      root: {
        border: 'none',
        height: 1,
        margin: 'auto',
        width: '90%',
        backgroundColor: LightGreyBG,
      },
    },
    MuiStepLabel: {
      root: {
        display: 'block',
        '&$completed': {
          color: LifeVantageStaticBlue,
        },
        '&$active': {
          color: LifeVantageStaticBlue,
        },
      },
      iconContainer: {
        width: '24px',
        margin: '0 auto',
      },
      label: {
        marginTop: 13,
        color: Disabled,
        '&$completed': {
          color: LifeVantageStaticBlue,
        },
        '&$active': {
          color: LifeVantageStaticBlue,
        },
        '&$alternativeLabel': {
          marginTop: '0.6rem',
          '&$completed': {
            color: LifeVantageStaticBlue,
          },
          '&$active': {
            color: LifeVantageStaticBlue,
          },
        },
      },
    },
    MuiBadge: {
      dot: {
        height: 10,
        minWidth: 10,
      },
      // [breakpoints.up('lg')]: {
      //   badge: {
      //     border: 'none !important',
      //   },
      // },
    },
    MuiCheckbox: {
      root: {
        width: 48,
        height: 48,
      },
    },
    MuiButton: {
      root: {
        textAlign: 'center',
        borderRadius: '24px',
        fontSize: '16px',
        color: Secondary,
        minWidth: 64,
        boxShadow: 'none !important',
        whiteSpace: 'nowrap',
        [breakpoints.up('lg')]: {
          borderRadius: 0,
          padding: '6px 40px',
        },
      },
      contained: {
        color: White,
        boxShadow: '0px 1px 10px 0px rgba(0, 40, 89, 0.15)',
        backgroundColor: LifeVantageActionBlue,
        '&:hover': {
          backgroundColor: LifeVantageActionBlue,
          color: White,
        },
        [breakpoints.up('lg')]: {
          color: White,
          boxShadow: '0px 1px 10px 0px rgba(0, 40, 89, 0.15)',
          backgroundColor: LifeVantageActionBlue,
          transition: 'ease-out .3s',
          height: 48,
          '&:hover': {
            boxShadow: '0px 1px 10px 0px rgba(0, 40, 89, 0.15)',
            backgroundColor: LifeVantageStaticBlue,
            '@media (hover: none)': {
              boxShadow: '0 1px 10px rgba(0, 0, 0, 0.15)',
              backgroundColor: LifeVantageStaticBlue,
            },
          },
        },
      },
      outlined: {
        color: Warning,
        borderColor: Warning,
        backgroundColor: White,
        [breakpoints.up('lg')]: {
          color: LifeVantageActionBlue,
          borderColor: LifeVantageActionBlue,
          boxShadow: 'none',
          transition: 'ease-out .3s',
          height: 48,
          '&:hover': {
            color: LifeVantageStaticBlue,
            backgroundColor: White,
            border: `1px solid ${LifeVantageStaticBlue}`,
            '@media (hover: none)': {
              backgroundColor: White,
            },
          },
        },
      },
      text: {
        fontSize: '16px',
        paddingLeft: '0',
        paddingRight: '0',
        [breakpoints.up('lg')]: {
          padding: '8px 11px',
          '&:hover': {
            color: Disabled,
            background: 'none',
            '@media (hover: none)': {
              background: 'none',
            },
          },
        },
      },
      containedPrimary: {},
      containedSecondary: {
        color: White,
        borderColor: Secondary,
        boxShadow: 'none',
        backgroundColor: Secondary,
        '&:hover': {
          color: White,
          borderColor: Secondary,
          boxShadow: 'none',
          backgroundColor: Secondary,
        },
      },
      outlinedPrimary: {
        [breakpoints.up('lg')]: {
          color: LifeVantageActionBlue,
          borderColor: LifeVantageActionBlue,
          boxShadow: 'none',
          backgroundColor: White,
          transition: 'ease-out .3s',
          '&:hover': {
            color: LifeVantageStaticBlue,
            backgroundColor: White,
            border: `1px solid ${LifeVantageStaticBlue}`,
            '@media (hover: none)': {
              backgroundColor: White,
            },
          },
        },
      },
      outlinedSecondary: {
        color: Warning,
        borderColor: Warning,
        backgroundColor: White,
        '&:hover': {
          color: Warning,
          borderColor: Warning,
          backgroundColor: White,
        },
      },
      textPrimary: {
        [breakpoints.up('lg')]: {
          color: LifeVantageActionBlue,
          '&:hover': {
            color: LifeVantageStaticBlue,
            backgroundColor: 'none',
            '@media (hover: none)': {
              color: LifeVantageStaticBlue,
              backgroundColor: 'none',
            },
          },
        },
      },
      textSecondary: {
        [breakpoints.up('lg')]: {
          color: LifeVantageStaticBlue,
          '&:hover': {
            color: LifeVantageActionBlue,
            backgroundColor: 'none',
            '@media (hover: none)': {
              color: LifeVantageActionBlue,
              backgroundColor: 'none',
            },
          },
        },
      },
      startIcon: {
        '&.MuiButton-iconSizeSmall': {
          marginLeft: 0,
        },
      },
      endIcon: {
        '&.MuiButton-iconSizeSmall': {
          marginRight: 0,
        },
      },
      iconSizeSmall: {
        '& > *:first-child': {
          fontSize: 14,
        },
      },
      iconSizeMedium: {
        '& > *:first-child': {
          fontSize: 16,
        },
      },
      iconSizeLarge: {
        '& > *:first-child': {
          fontSize: 19,
        },
      },
    },
    MuiIconButton: {
      root: {
        borderRadius: 0,
        '&:not(.MuiSwitch-switchBase)': {
          minWidth: 48,
          height: 48,
        },
      },
    },
    MuiLinearProgress: {
      barColorSecondary: {
        backgroundColor: Warning,
      },
    },
    MuiBottomNavigationAction: {
      wrapper: {
        display: 'flex',
        justifyContent: 'center',
        height: '35px',
        alignSelf: 'center',
      },
      root: {
        '&.Mui-selected': {
          color: LifeVantageStaticBlue,
        },
      },
      label: {
        '&.Mui-selected': {
          fontWeight: 550,
        },
      },
    },
    MuiSwitch: {
      [breakpoints.up('lg')]: {
        root: {
          padding: 6,
        },
        switchBase: {
          '&$checked + $track': {
            opacity: 1,
            backgroundColor: Secondary,
          },
          '&$checked $thumb': {
            backgroundColor: 'hsl(0, 0%, 100%)',
          },
        },
        track: {
          borderRadius: 40,
          backgroundColor: 'hsl(0, 0%, 100%)',
          border: `1px solid ${Secondary}`,
          opacity: 1,
        },
        thumb: {
          boxShadow: 'none',
          backgroundColor: Secondary,
        },
        colorPrimary: {
          '& + $track': {
            backgroundColor: 'hsl(0, 0%, 100%)',
            border: `1px solid ${LifeVantageActionBlue}`,
          },
          '&$checked + $track': {
            backgroundColor: LifeVantageActionBlue,
            border: `1px solid ${LifeVantageActionBlue}`,
          },
          '& $thumb': {
            backgroundColor: LifeVantageActionBlue,
          },
          '&$checked $thumb': {
            backgroundColor: 'hsl(0, 0%, 100%)',
          },
        },
        colorSecondary: {
          '& + $track': {
            backgroundColor: 'hsl(0, 0%, 100%)',
            border: `1px solid ${LifeVantageStaticBlue}`,
          },
          '&$checked + $track': {
            backgroundColor: LifeVantageStaticBlue,
            border: `1px solid ${LifeVantageStaticBlue}`,
          },
          '& $thumb': {
            backgroundColor: LifeVantageStaticBlue,
          },
          '&$checked $thumb': {
            backgroundColor: 'hsl(0, 0%, 100%)',
          },
        },
      },
    },
    MuiDialog: {
      paperWidthMd: {
        [breakpoints.up('lg')]: {
          maxHeight: 548,
          overflow: 'hidden',
          padding: '64px 72px',
          borderRadius: 0,
          height: 548,
        },
      },
    },
    MuiPickersDay: {
      day: {
        [breakpoints.up('lg')]: {
          minWidth: 'auto',
        },
      },
    },
    MuiTextField: {
      root: {
        width: '100%',
        marginTop: -1,
        '& .Mui-disabled': {
          background: LightGreyBG,
          [breakpoints.up('lg')]: {
            background: 'transparent',
          },
        },
      },
    },
    MuiInputBase: {
      input: {
        fontSize: 16,
        color: Primary,
        height: 20,
      },
    },
    MuiInput: {
      input: {
        [breakpoints.up('lg')]: {
          borderRadius: 0,
        },
      },
    },
    MuiLink: {
      underlineHover: {
        '&:hover': {
          [breakpoints.up('lg')]: {
            textDecoration: 'none',
          },
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 0,
        '&:hover': {
          [breakpoints.up('lg')]: {
            zIndex: 1,
          },
        },
      },
      input: {
        padding: '22px 16px',
        '&:hover': {
          [breakpoints.up('lg')]: {
            zIndex: 1,
            cursor: 'pointer',
          },
        },
      },
      adornedStart: {
        color: Disabled,
        paddingLeft: 20,
      },
      inputAdornedStart: {
        paddingLeft: 20,
      },
    },
    MuiSelect: {
      outlined: {
        [breakpoints.up('lg')]: {
          paddingLeft: 40,
        },
      },
    },
    MuiInputLabel: {
      outlined: {
        [breakpoints.up('lg')]: {
          transform: 'translate(16px, 24px) scale(1)',
        },
      },
    },
    MuiAutocomplete: {
      inputRoot: {
        minHeight: 64,
      },
    },
    MuiInputAdornment: {
      positionStart: {
        [breakpoints.up('lg')]: {
          marginLeft: 4,
        },
        positionEnd: {
          [breakpoints.up('lg')]: {
            marginTop: '-8px',
          },
        },
      },
    },
    MuiFormHelperText: {
      contained: {
        [breakpoints.up('lg')]: {
          margin: '8px 14px',
        },
      },
    },
    MuiTabs: {
      root: {
        minHeight: 44,
        borderBottom: `1px solid ${Secondary}`,
      },
      indicator: {
        backgroundColor: LifeVantageStaticBlue,
      },
    },
    MuiTab: {
      root: {
        minHeight: '44px',
        fontWeight: 400,
        textTransform: 'none',
      },
      textColorPrimary: {
        '&.Mui-selected': {
          color: LifeVantageStaticBlue,
        },
      },
    },
    MuiToolbar: {
      root: {
        minHeight: 50,
      },
    },
    MuiList: {
      root: {
        marginTop: -1,
      },
    },
    MuiListItem: {
      root: {
        paddingTop: 13,
        paddingBottom: 13,
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 45,
      },
    },
    MuiSnackbar: {
      root: {
        [breakpoints.up('lg')]: {
          marginTop: 8,
        },
      },
    },
    MuiSnackbarContent: {
      root: {
        [breakpoints.up('lg')]: {
          padding: '4px 14px',
        },
      },
    },
    SnackbarItem: {
      lessPadding: {
        [breakpoints.up('lg')]: {
          paddingLeft: 16,
        },
      },
    },
    MuiTabsIndicator: {
      [breakpoints.up('lg')]: {
        color: 'red',
      },
    },
    MuiTimeline: {
      root: {
        padding: 0,
        marginTop: 0,
      },
    },
    MuiTimelineItem: {
      missingOppositeContent: {
        '&:before': {
          content: 'none',
        },
      },
    },
    MuiTimelineConnector: {
      root: {
        backgroundColor: Disabled,
      },
    },
    MuiTimelineDot: {
      defaultGrey: {
        backgroundColor: Disabled,
      },
    },
    MuiTableCell: {
      root: {
        padding: '16px 4px',
      },
    },
  },
}, zhCN);

export default theme;

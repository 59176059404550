/*eslint-disable*/
/**
 *
 * SmSideNav
 *
 */

import React from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import routes from 'utils/routes';
import clsx from 'clsx';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import useStyles from 'containers/SmInvoice/style';
import messages from 'containers/UserProvider/messages';

function SmSideNav() {
  const accountLinks = [
    {
      label: messages.llcCheck,
      icon: 'users',
      url: routes.SmCustomer,
    },
    {
      label: messages.smInvoice,
      icon: 'file',
      url: routes.SmInvoice,
    },
  ];

  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const handleClick = (url) => {
    history.push(url);
  };
  return (
    <>
      <List component="nav">
        {accountLinks.map((link) => (
          <ListItem
            key={link.url}
            button
            onClick={() => handleClick(link.url)}
            className={
              history?.location?.pathname.includes(link.url)
                ? clsx([classes.link, classes.active])
                : classes.link
            }
          >
            <ListItemIcon className={classes.linkIcon}>
              <FontAwesomeIcon
                icon={[location.pathname.includes(link.url) ? 'fas' : 'far', link.icon]}
              />
            </ListItemIcon>
            <ListItemText primary={<FormattedMessage {...link.label} />} disableTypography />
          </ListItem>
        ))}
      </List>
    </>
  );
}

export default SmSideNav;

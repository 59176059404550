/*
 * AppReducer
 *
 * The reducer takes care of our data. Using actions, we can
 * update our application state. To add a new action,
 * add it to the switch statement in the reducer function
 *
 */

import produce from 'immer';
import { constants } from './actions';

// The initial state of the App
export const initialState = {
  MPLoginVisible: false,
  GDPRConfig: false,
  CookieConfig: false,
};

/* eslint-disable default-case, no-param-reassign */
const appReducer = (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case constants.TOGGLE_MP_LOGIN_VISIBLE:
      draft.MPLoginVisible = action.payload.visible;
      break;
    case constants.SET_GDPR_CONFIG:
      draft.GDPRConfig = action.payload.enabled;
      break;
    case constants.SET_COOKIE_CONFIG:
      draft.CookieConfig = action.payload.enabled;
      break;

    default:
      break;
  }
});

export default appReducer;

/*
 *
 * Order List Page constants
 *
 */

// eslint-disable-next-line import/prefer-default-export
export const ORDER_STATUS_LIST = [
  'pending_payment',
  'pending_shipment',
  'in_transit',
  'complete',
  'reviewed',
];

export const ORDER_STATUS_LIST_DESKTOP = ['pending_payment', 'in_transit', 'complete', 'reviewed'];

/**
 *
 * ConfirmRemoval
 *
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import appMessages from 'containers/App/messages';
import { makeStyles } from '@material-ui/core/styles';
import Buttons from 'components/Buttons/Buttons';
import CenteredDialog from 'components/Dialogs/CenteredDialog';
import messages from '../messages';

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.warning.main,
    borderColor: theme.palette.warning.main,
    '&:hover': {
      color: theme.palette.warning.main,
      borderColor: theme.palette.warning.main,
    },
  },
}));

function ConfirmRemoval({
  open, onClose, onConfirm, loading, title, description,
}) {
  const classes = useStyles();
  const handleClose = () => onClose();
  const handleConfirm = () => onConfirm();
  const renderContent = () => {
    if (loading) {
      return (
        <Box align="center">
          <Typography color="primary"><FormattedMessage {...messages.processing} /></Typography>
          <CircularProgress size={18} style={{ margin: 5 }} />
        </Box>
      );
    }
    if (description && description !== '') {
      return <Typography variant="inherit">{description}</Typography>;
    }
    return <FormattedMessage {...messages.destructiveAction} />;
  };

  return (
    <>
      <Hidden lgUp>
        <CenteredDialog open={open}>
          <Box bgcolor="background.default" p={2}>
            <Typography variant="subtitle1">
              {(title && title !== '') ? title : <FormattedMessage {...messages.deleteItem} />}
            </Typography>
          </Box>
          <Box p={2} borderBottom={1} color="divider">
            <Typography color="textSecondary" variant="caption">{renderContent()}</Typography>
          </Box>
          <Box display="flex" justifyContent="flex-end" p={1}>
            <Buttons
              disabled={loading}
              onClick={onClose}
              messageId={<FormattedMessage {...appMessages.cancel} />}
            />
            <Buttons
              variant="outlined"
              messageId={<FormattedMessage {...appMessages.remove} />}
              onClick={onConfirm}
              loading={loading}
            />
          </Box>
        </CenteredDialog>
      </Hidden>

      <Hidden mdDown>
        <Dialog open={open}>
          <Box bgcolor="background.default" p={2}>
            <Typography variant="subtitle1">
              {title && title !== '' ? title : <FormattedMessage {...messages.deleteItem} />}
            </Typography>
          </Box>
          <Box p={2} borderBottom={1} color="divider">
            <Typography color="textSecondary" variant="caption">
              <FormattedMessage {...messages.destructiveAction} />
            </Typography>
          </Box>
          <Box display="flex" justifyContent="flex-end" p={1} width={270}>
            <Buttons
              disabled={loading}
              onClick={handleClose}
              messageId={<FormattedMessage {...appMessages.cancel} />}
            />
            <Buttons
              variant="outlined"
              messageId={<FormattedMessage {...appMessages.remove} />}
              onClick={handleConfirm}
              loading={loading}
              className={classes.button}
            />
          </Box>
        </Dialog>
      </Hidden>

    </>

  );
}

ConfirmRemoval.defaultProps = {
  loading: false,
};

ConfirmRemoval.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
};

export default memo(ConfirmRemoval);

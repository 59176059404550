import React from 'react';
import { getAuthorization } from 'utils/Auth';
import { Redirect } from 'react-router-dom';
import routes from '../routes';
import { getBuyNow, getProceedToCheckout } from '../Checkout';

export default (WrappedComponent) => (props) => {
  const auth = getAuthorization();
  const buyNow = getBuyNow();
  const proceedToCheckout = getProceedToCheckout();
  if (typeof auth.token !== 'undefined' && auth.type === 'user' && buyNow === '' && proceedToCheckout === '') {
    return <Redirect to={routes.dashboard} />;
  }

  return <WrappedComponent {...props} />;
};

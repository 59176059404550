// eslint-disable-next-line import/no-cycle
import { ORDER_STATUS_LIST } from 'containers/OrderListPage/constants';

export default {
  home: '/',
  myCouponPage: '/account/my-coupons',
  dashboard: '/account/dashboard',
  ordersAll: '/account/orders',
  sharingList: '/account/sharingList',
  brandEmbassador: '/account/brandEmbassador',
  share: '/account/share',
  profile: '/account/profile',
  addressBook: '/account/address-book',
  orders: (status) => (status ? `/account/orders/${status}` : `/account/orders/:status(all|${ORDER_STATUS_LIST.join('|')})`),
  orderDetail: (id) => (id ? `/account/order/${id}` : '/account/order/:id'),
  statistics: (type) => (type ? `/account/statistics/${type}` : '/account/statistics/:type(self|downline)'),
  upgrade: '/account/upgrade',
  privacy: '/account/privacy',
  consentForm: '/account/consent-form',
  downloadAccount: '/account/download-account',
  deleteAccount: '/account/delete-account',
  about: '/about',
  blog: '/blog',
  blogDetailPage: (id) => (id ? `/blog/${id}` : '/blog/:id'),
  cart: '/cart',
  catalogEmpty: '/catalog',
  catalog: (id) => (id ? `/catalog/${id}` : '/catalog/:key'),
  checkout: '/checkout',
  cms: (id) => (id ? `/cms/${id}` : '/cms/:id'),
  coupons: '/coupons',
  flashsale: '/flashsale',
  forgotPassword: '/forgot-password',
  helper: '/helper',
  logIn: '/login',
  OTPLogIn: '/login/otp',
  registration: '/registration',
  search: (query) => (query ? `/search/${query}` : '/search/:query'),
  product: (urlKey) => (urlKey ? `/product/${urlKey}` : '/product/:key'),
  success: (id) => (id ? `/checkout/success/${id}` : '/checkout/success/:id'),
  refundApplication: '/refund/application',
  refundDelivery: '/refund/delivery',
  refundDetail: '/refund/detail',
  SmCustomer: '/smcustomer',
  SmInvoice: '/sminvoice',
  SMLogin: '/social',
  notFound: '',
};

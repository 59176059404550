/*
 * MembershipPage Messages
 *
 * This contains all the text for the MembershipPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.MembershipPage';

export default defineMessages({
  credits: {
    id: `${scope}.credits`,
    defaultMessage: 'Credits',
  },
  successfulySaved: {
    id: `${scope}.successfulySaved`,
    defaultMessage: 'Successfully saved',
  },
  pvProgressToVIP: {
    id: `${scope}.pvProgressToVIP`,
    defaultMessage: 'PV (progress to VIP)',
  },
  pvMonthly: {
    id: `${scope}.pvMonthly`,
    defaultMessage: 'PV (monthly)',
  },
  applyForSM: {
    id: `${scope}.applyForSM`,
    defaultMessage: 'Apply for SM',
  },
  myStatistics: {
    id: `${scope}.myStatistics`,
    defaultMessage: 'My Statistics',
  },
  selectMonth: {
    id: `${scope}.selectMonth`,
    defaultMessage: 'Select Month',
  },
  selfStatistics: {
    id: `${scope}.selfStatistics`,
    defaultMessage: 'Personal',
  },
  downLineStatistics: {
    id: `${scope}.downLineStatistics`,
    defaultMessage: 'Down-Line Statistics',
  },
  monthlyTotalPV: {
    id: `${scope}.monthlyTotalPV`,
    defaultMessage: 'Monthly Total PV',
  },
  accountStatus: {
    id: `${scope}.accountStatus`,
    defaultMessage: 'Status',
  },
  active: {
    id: `${scope}.active`,
    defaultMessage: 'Active',
  },
  inactive: {
    id: `${scope}.inactive`,
    defaultMessage: 'Inactive',
  },
  totalLPVEarned: {
    id: `${scope}.totalLPVEarned`,
    defaultMessage: 'Total LPV Earned',
  },
  totalCredits: {
    id: `${scope}.totalCredits`,
    defaultMessage: 'Credits Earned',
  },
  creditsSpent: {
    id: `${scope}.creditsSpent`,
    defaultMessage: 'Credits Spent',
  },
  creditsAvailable: {
    id: `${scope}.creditsAvailable`,
    defaultMessage: 'Credits Available',
  },
  totalPV: {
    id: `${scope}.totalPV`,
    defaultMessage: 'Total PV',
  },
  status: {
    id: `${scope}.status`,
    defaultMessage: 'Status',
  },
  lpvEarned: {
    id: `${scope}.lpvEarned`,
    defaultMessage: 'LPV Earned',
  },
  monthTotals: {
    id: `${scope}.monthTotals`,
    defaultMessage: '{month} Totals',
  },
  helpCenter: {
    id: `${scope}.helpCenter`,
    defaultMessage: 'Help Center',
  },
  PVofVIP: {
    id: `${scope}.PVofVIP`,
    defaultMessage: 'PV of VIP',
  },
  noReportsYet: {
    id: `${scope}.noReportsYet`,
    defaultMessage: 'No reports yet',
  },
  reportNotAvailable: {
    id: `${scope}.reportNotAvailable`,
    defaultMessage: 'Last month\'s report will be generated within three days in this month. Please contact help center if you have any questions.',
  },
  ibe: {
    id: `${scope}.ibe`,
    defaultMessage: 'IBE',
  },
  smAnalyticTitle: {
    id: `${scope}.smAnalyticTitle`,
    defaultMessage: 'Credits',
  },
  name: {
    id: `${scope}.name`,
    defaultMessage: 'Name',
  },
  totalPv: {
    id: `${scope}.totalPv`,
    defaultMessage: 'Total Pv',
  },
  totalScore: {
    id: `${scope}.totalScore`,
    defaultMessage: 'Total Score',
  },
});

/**
 * Process WeChat Pay payment parameters before redirecting to native payment page
 * @param parameters
 * @param orderID
 */
export default (parameters, orderID) => new Promise((resolve, reject) => {
  if (parameters[0] === 'Ok' && parameters[2]) {
    const params = JSON.parse(parameters[2]);
    const prepayID = params.package.split('=');

    // eslint-disable-next-line no-undef
    wx.miniProgram.navigateTo({
      url: `/pages/payment/payment?orderID=${orderID}&nonceStr=${params.nonceStr}`
        + `&prepayID=${prepayID[1]}&paySign=${params.paySign}&signType=${params.signType}`
        + `&timeStamp=${params.timeStamp}`,
    });

    resolve(true);
  }

  reject(new Error('Fetching paramaters from WeChat failed.'));
});

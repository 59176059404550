/**
 * Various endpoints used for authenticating users
 */

import request from 'utils/request';

/**
 * @api {GET} /customer/logout Invalidate customer session
 * @apiGroup Authorization
 */
export const logOut = () => request('GET', '/customer/logout');

/**
 * @api {GET} /customer/mobile/sendcode/:check/:mobile/:countryCode Send OTP to mobile
 * @apiGroup Authorization
 * check = 0, don't check if this mobile phone exists in data base.check = 1, check and return error if exist. check = 2, check and return error if not exist.
 */
export const sendOTP = ({ check = 2, logInId, countryCode }) => request('GET', `/customer/mobile/sendcode/${check}/${logInId}/${encodeURIComponent(countryCode)}`);

/**
 * @api {POST} /customers/register Create account
 * @apiGroup Authorization
 */
export const register = (payload) => request('POST', '/customers/register', payload);

/**
 * @api {POST} /customer/login/token Request new token by OTP
 * @apiGroup Authorization
 */
export const logInByOTP = (payload) => request('POST', '/customer/login/token', payload);

/**
 * @api {POST} /customer/login/mobile/token Request new token by password
 * @apiGroup Authorization
 */
export const logInByUsername = (payload) => request('POST', '/customer/login/mobile_password/token', payload);

/**
 * @api {POST} /customer/resetpassword/token Reset a password
 * @apiGroup Authorization
 */
export const changePassword = (payload) => request('POST', '/customer/resetpassword/token', payload);

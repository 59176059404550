/*
 *
 * Notification actions
 *
 */

const base = 'Notifications';

export const constants = {
  ENQUEUE_SNACKBAR: `${base}/ENQUEUE_SNACKBAR`,
  CLOSE_SNACKBAR: `${base}/CLOSE_SNACKBAR`,
  REMOVE_SNACKBAR: `${base}/REMOVE_SNACKBAR`,
};

export const actions = {
  enqueueSnackbar: (notification) => {
    const key = notification.options && notification.options.key;
    return {
      type: constants.ENQUEUE_SNACKBAR,
      notification: {
        ...notification,
        key: key || new Date().getTime() + Math.random(),
      },
    };
  },
  closeSnackbar: (key) => ({ type: constants.CLOSE_SNACKBAR, dismissAll: !key, key }),
  removeSnackbar: (key) => ({ type: constants.REMOVE_SNACKBAR, key }),
};

/* eslint-disable no-console */
import React from 'react';
import { eventChannel, END } from 'redux-saga';
import store from 'store';
import {
  all, call, put, takeLatest, takeLeading, take, fork, cancel, cancelled,
} from 'redux-saga/effects';
import { push } from 'connected-react-router/immutable';
import { FormattedMessage } from 'react-intl';
import {
  deleteAuthorization, getAuthorization, setAuthorization, isSignedIn,
} from 'utils/Auth';
import {
  deleteBuyNow, deleteProceedToCheckout, getBuyNow, getProceedToCheckout,
} from 'utils/Checkout';
import routes from 'utils/routes';
import { actions as notificationActions } from 'utils/Notifications/actions';
import CloseNotification from 'utils/Notifications/CloseNotification';
import determineEnvironment from 'utils/WeChat/determineEnvironment';
import messages from 'containers/UserProvider/messages';
import { constants as cartConstants, RESET_CART } from 'containers/CartProvider/actions';
import { createGuestCart } from 'containers/CartProvider/api';
import { constants as checkoutConstants, RESET_CHECKOUT } from 'containers/CheckoutPage/actions';
import { constants as appConstants } from 'containers/App/actions';

import authMessages from 'containers/AuthorizationProvider/messages';
import { constants as authConstants } from './actions';
import { DEFAULT_TIMER_DURATION } from './reducer';
import {
  logOut, sendOTP, register, logInByUsername, changePassword, logInByOTP,
} from './api';

/**
 * Handle countdown for verification code request
 */
const countdown = (secs) => eventChannel((emit) => {
  let seconds = secs;
  const counter = setInterval(() => {
    seconds -= 1;
    emit(seconds >= 0 ? seconds : END);
  }, 1000);
  return () => clearInterval(counter);
});

function* countdownTask(secs) {
  const chan = yield call(countdown, secs);
  try {
    while (true) {
      const sec = yield take(chan);
      yield put({ type: authConstants.TICK, payload: sec });
    }
  } finally {
    if (yield cancelled()) {
      chan.close();
    } else {
      yield put({ type: authConstants.STOP_TIMER });
    }
  }
}

function* watchCountdown() {
  while (true) {
    const { payload } = yield take(authConstants.START_TIMER);

    const task = yield fork(countdownTask, (payload ? payload.secs : DEFAULT_TIMER_DURATION));

    const { type } = yield take(authConstants.STOP_TIMER);
    if (type === authConstants.STOP_TIMER) {
      yield cancel(task);
    }
  }
}

/**
 * Request a verification code based on a phone number and a country code prefix
 */
export function* handleGetAuthorizationCode(action) {
  const {
    body, complete, error, secondCode,
  } = action.payload;
  try {
    yield call(sendOTP, body);

    if (!secondCode) {
      yield put({ type: authConstants.START_TIMER });
    }

    yield put({ type: authConstants.GET_CODE_SUCCESS });

    yield put(notificationActions.enqueueSnackbar({
      message: <FormattedMessage {...authMessages.codeSent} />,
      options: {
        key: new Date().getTime() + Math.random(),
        variant: 'success',
        action: (key) => <CloseNotification notificationKey={key} />,
      },
    }));

    if (complete) complete();
  } catch (err) {
    console.log('Error: ', err);
    yield put({ type: authConstants.GET_CODE_FAILURE });

    if (error) {
      if (err.response && err.response.data) {
        error(err.response.data);
        if (err.response.data.code === 9000) {
          if (!secondCode) {
            yield put({ type: authConstants.START_TIMER });
          }
        }
      } else {
        error('Authentication failed. Please contact support.');
      }
    }
  }
}

/**
 * Handle recurring post-login actions
 *
 * @param {string} token
 * @param {boolean} toDashboard
 */
function* postLogIn(token, toDashboard = true) {
  deleteAuthorization();
  setAuthorization(token);
  if (toDashboard) {
    yield put(push(routes.dashboard));
  } else {
    yield put(push(routes.home));
  }
  yield put({ type: cartConstants.GET_CART_QUOTE_REQUEST });
  yield put({ type: checkoutConstants.GET_CHECKOUT_QUOTE_REQUEST });
}

/**
 * Log in an existing customer
 */
export function* handleLogIn(action) {
  const {
    body, isOTP, token, complete, error, checkPassword,
  } = action.payload;
  const auth = getAuthorization();
  try {
    let newToken = token;

    if (body) {
      const payload = {
        ...body,
        ...(auth.token ? { cartId: auth.token } : {}),
      };

      const processor = isOTP ? logInByOTP : logInByUsername;
      newToken = yield call(processor, payload);
    }

    yield put({ type: authConstants.SET_AUTH, payload: { type: 'user', newToken } });

    yield call(postLogIn, newToken, false);

    yield put({ type: appConstants.GET_GDPR_CONFIG });

    // Check if a Buy Now or Proceed to Checkout was initiated before log in
    const buyNow = getBuyNow();

    const proceedToCheckout = getProceedToCheckout();

    if (buyNow || proceedToCheckout) {
      yield take(cartConstants.GET_CART_QUOTE_SUCCESS);
      // yield take(checkoutConstants.GET_CHECKOUT_QUOTE_SUCCESS);

      if (buyNow) {
        yield put({ type: checkoutConstants.BUY_NOW_REQUEST, payload: buyNow });
      } else if (proceedToCheckout) {
        yield put({ type: cartConstants.CLEAR_SELECTED_CART_ITEMS });
        yield put({ type: checkoutConstants.PROCEED_TO_CHECKOUT_REQUEST, payload: proceedToCheckout });
        yield take([checkoutConstants.PROCEED_TO_CHECKOUT_SUCCESS, checkoutConstants.PROCEED_TO_CHECKOUT_FAILURE]);
      }

      deleteBuyNow();
      deleteProceedToCheckout();
    } else if (!checkPassword) {
      yield put(push(routes.home));
    }
    yield put({ type: authConstants.LOG_IN_SUCCESS });

    if (complete) complete();
  } catch (err) {
    console.log('Error: ', err);
    yield put({ type: authConstants.LOG_IN_FAILURE });

    if (Array.isArray(err)) {
      const invalidToken = err.some((e) => e.message === 'The current customer isn\'t authorized.');
      if (invalidToken) {
        yield put(push(routes.home));
      }
    }

    if (error && err.response && err.response.data) {
      error(err.response.data);
    }
  }
}

/**
 * Register a new customer
 */
export function* handleRegistration(action) {
  const { body, error, complete } = action.payload;
  const storeConfigs = store.get('store_configs');
  try {
    body.customer.website_id = storeConfigs?.website_id || '1';

    const token = yield call(register, body);

    yield put({ type: authConstants.SET_AUTH, payload: { type: 'user', token } });

    yield call(postLogIn, token);
    if (complete) {
      complete();
    }
  } catch (err) {
    console.log('Error: ', err);

    if (error && err.response && err.response.data) {
      error(err.response.data);
    }
  }
}

/**
 * Register a new customer
 */
export function* handleLogOut() {
  const { location } = window;
  const protectedRoutes = [routes.checkout, routes.dashboard, routes.ordersAll,
    routes.orders, routes.orderDetail, routes.profile, routes.notifications,
    routes.addressBook, routes.myCouponPage, routes.refundApplication,
    routes.refundDetail, routes.share, routes.sharingList, routes.brandEmbassador,
    routes.statistics, routes.success, routes.upgrade, routes.refundDelivery,
  ];

  if (isSignedIn()) {
    try {
      yield call(logOut);
    } catch (err) {
      console.log('Log out call failed: ', err);
    }
  }

  try {
    // Remove logged in user and their token from local storage before proceeding
    deleteAuthorization();

    // Reset store state - TODO ADD MORE
    yield put({ type: cartConstants.TOGGLE_ALL_CART_ITEMS, checked: false });
    yield put({ type: RESET_CART });
    yield put({ type: RESET_CHECKOUT });

    // Create a new guest cart and send new token to store and local storage
    const token = yield call(createGuestCart);
    setAuthorization(token, 'guest');
    yield put({ type: authConstants.SET_AUTH, payload: { type: 'guest', token } });

    const currentRoute = location?.pathname.match(/^((?!\/\d+).)*/);
    if (currentRoute && protectedRoutes.includes(currentRoute[0])) {
      if (determineEnvironment()) {
        yield put(push(routes.home));
      } else {
        yield put(push(routes.logIn));
      }
    }

    // Fetch the guest's cart
    yield put({ type: cartConstants.GET_CART_QUOTE_REQUEST });
  } catch (err) {
    console.log('Error: ', err);
  }
}

/**
 * Reset a password and log in
 */
export function* handlePasswordReset(action) {
  const { body, complete, error } = action.payload;
  try {
    const result = yield call(changePassword, body);

    // Prevent log in functionality if autoLogin is false - resetting from profile for example
    if (body.autoLogin === true) {
      yield put({ type: authConstants.SET_AUTH, payload: { type: 'user', result } });

      yield call(postLogIn, result);
    }

    if (body.autoLogin === false && result === 'OK') {
      yield put(notificationActions.enqueueSnackbar({
        message: <FormattedMessage {...messages.passwordChangedSuccessfully} />,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
          action: (key) => <CloseNotification notificationKey={key} />,
        },
      }));
    }

    if (complete) complete();
  } catch (err) {
    console.log('Error: ', err);

    if (error && err.response && err.response.data) {
      error(err.response.data);
    }
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* rootSaga() {
  yield all([
    takeLatest(authConstants.GET_CODE_REQUEST, handleGetAuthorizationCode),
    takeLeading(authConstants.LOG_IN_REQUEST, handleLogIn),
    takeLatest(authConstants.REGISTER, handleRegistration),
    takeLeading(authConstants.LOG_OUT, handleLogOut),
    takeLeading(authConstants.RESET_PASSWORD, handlePasswordReset),
  ]);
  yield fork(watchCountdown);
}

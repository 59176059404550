/**
 *
 * Asynchronously loads the component for OrderDetail
 *
 */

import loadable from 'utils/loadable';

export const OrderSuccessPage = loadable(() => import('./components/OrderSuccessPage'));

export default loadable(() => import('./index'));

/* eslint-disable no-console */
/**
 *
 * ImageUpload
 *
 */

import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import useNotificationSnackbar from 'utils/Hooks/useNotificationSnackbar';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Buttons from 'components/Buttons/Buttons';
import messages from 'containers/App/messages';
import isEmpty from 'lodash/isEmpty';

function ImageUpload({
  onChange, onRemove, label, limit, maxAttachmentSize, error, id, image,
}) {
  const notificationSnackbar = useNotificationSnackbar();
  const fileLimit = parseInt(limit, 10);
  const sizeLimit = parseInt(maxAttachmentSize, 10);
  const [files, setFiles] = useState([]);

  useEffect(() => image && setFiles([image]), []);

  useEffect(() => () => {
    // Revoke the data uris to avoid memory leaks
    files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  const handleOnDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length === 0) return;

    if (acceptedFiles[0].size > sizeLimit) {
      notificationSnackbar(
        <FormattedMessage {...messages.imageSizeRestriction} values={{ size: `${Math.ceil((acceptedFiles[0].size / 1024 / 1024) * 100) / 100}MB` }} />,
        'info',
      );
      return;
    }

    if (acceptedFiles.length > (fileLimit - files.length)) {
      notificationSnackbar(
        <FormattedMessage {...messages.imageLimitRestriction} values={{ limit }} />,
        'info',
      );
    } else {
      const promises = Array.from(acceptedFiles).map((file) => (
        new Promise((resolve) => {
          const img = new Image();
          img.src = URL.createObjectURL(file);
          img.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);
            const newDataUrl = canvas.toDataURL(file.type, 0.1);
            // eslint-disable-next-line func-names
            canvas.toBlob((blob) => {
              // eslint-disable-next-line no-console
              const f2 = new File([blob], file.name);
              resolve({
                name: f2.name,
                type: file.type,
                preview: URL.createObjectURL(file),
                base64_encoded_data: newDataUrl,
                size: f2.size,
              });
            }, file.type, 0.9);
          };
        })
      ));

      Promise.all(promises).then((fileContents) => {
        const parsedFiles = files.concat(fileContents);
        setFiles(parsedFiles);
        onChange(parsedFiles);
      }, (err) => {
        notificationSnackbar(
          err,
          'error',
        );
      }).catch((err) => {
        notificationSnackbar(
          err,
          'error',
        );
      });
    }
  }, [files]);

  const removeAttachment = (index) => () => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
    if (onRemove) {
      onRemove(newFiles);
    }
  };

  const thumbs = files.map((file, index) => (
    <Box display="flex" justifyContent="space-between" alignItems="center" width={1} key={index.toString()}>
      <Box
        mr={1}
        style={{
          background: image && isEmpty(file.name)
            ? `url(${process.env.BASE_MEDIA}/customer${image}) center/cover no-repeat`
            : `url(${file.preview || file}) center/cover no-repeat`,
          height: '45px',
          width: '100%',
          maxWidth: 65,
        }}
      />
      <Box overflow="hidden">
        <Typography variant="subtitle2" gutterBottom color="textSecondary">
          {label}
        </Typography>
        <Typography variant="subtitle2">
          {file.name || image}
        </Typography>
        {!image
          && (
          <Typography variant="caption" component="p">
            {`${Math.ceil((file.size / 1024 / 1024) * 100) / 100}MB`}
          </Typography>
          )}
      </Box>

      <Buttons
        onClick={removeAttachment(index)}
        messageId={<FontAwesomeIcon icon={['fal', 'trash-alt']} />}
      />
    </Box>
  ));

  return (
    <Box my={1.5}>
      <Box width={1} px={2} py={1} style={{ border: '1px solid #a0a0a0', borderRadius: '6px' }}>
        {thumbs}
        {files.length < fileLimit
        && (
        <label htmlFor={id} style={{ display: 'flex', justifyContent: 'space-between' }}>
          <input
            id={id}
            type="file"
            onChange={(e) => handleOnDrop(e.target.files)}
            style={{ display: 'none' }}
            accept="image/jpeg, image/png, image/jpg"
          />
          <Box>
            <Typography variant="subtitle2" gutterBottom color="textSecondary">
              {label}
            </Typography>
            <Typography variant="subtitle1">
              <FormattedMessage {...messages.uploadPhoto} />
            </Typography>
          </Box>
          <Buttons
            size="large"
            color="primary"
            component="a"
            icon={<FontAwesomeIcon icon={['fal', 'camera']} />}
            messageId={<FormattedMessage {...messages.uploadTakePhoto} />}
          />
        </label>
        )}
      </Box>
      <p>{error && <Typography variant="subtitle1" color="error">{error}</Typography>}</p>
      <Box>
        <Typography variant="caption" color="textSecondary">
          <FormattedMessage
            {...messages.attachmentRestrictions}
            values={{
              size: <b>{`${Math.ceil((sizeLimit / 1024 / 1024) * 100) / 100}MB`}</b>,
              formats: <b>JPEG/PNG/GIF</b>,
            }}
          />
        </Typography>
      </Box>
    </Box>
  );
}

ImageUpload.defaultProps = {
  limit: 1,
  maxAttachmentSize: 20971520,
};

ImageUpload.propTypes = {
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  id: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]),
  limit: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  maxAttachmentSize: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  error: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]),
  image: PropTypes.string,
};

export default ImageUpload;

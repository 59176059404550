/**
 * The global state selectors
 */

import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './reducer';

const selectGlobalDomain = (state) => state.global || initialState;

const selectRouterDomain = (state) => state.router;

const makeSelectRouterLocation = () => createSelector(
  selectRouterDomain,
  (routerState) => routerState.location,
);

const makeSelectMPLoginVisible = () => createSelector(
  selectGlobalDomain,
  (globalState) => globalState.MPLoginVisible,
);

const makeGDPRConfig = () => createSelector(
  selectGlobalDomain,
  (globalState) => globalState.GDPRConfig,
);

const makeCookieConfig = () => createSelector(
  selectGlobalDomain,
  (globalState) => globalState.CookieConfig,
);

export default selectGlobalDomain;
export {
  makeSelectRouterLocation,
  makeSelectMPLoginVisible,
  makeGDPRConfig,
  makeCookieConfig,
};

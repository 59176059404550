/*
 *
 * Checkout reducer
 *
 */
import produce from 'immer';
import { constants, RESET_CHECKOUT } from './actions';

export const initialState = {
  quoteId: '',
  quote: {},
  selectedAddress: {},
  shippingMethods: [],
  selectedShippingMethod: {},
  paymentMethods: [],
  selectedPaymentMethod: {},
  totals: {},
  selectedCard: {},
};

/* eslint-disable default-case, no-param-reassign, consistent-return */
const checkoutReducer = (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case RESET_CHECKOUT:
      return initialState;

    case constants.SET_CHECKOUT_QUOTE:
      draft.quote = action.quote;
      draft.quoteId = action.quote.id;
      break;

    case constants.BUY_NOW_SET:
      draft.quote.items = [action.quoteItem];
      draft.quote.items_qty = action.quoteItem.qty;
      break;

    case constants.SELECT_SHIPPING_ADDRESS:
      draft.selectedAddress = action.address;
      break;

    case constants.SET_SHIPPING_METHODS:
      draft.shippingMethods = action.shippingMethods;
      break;

    case constants.SELECT_SHIPPING_METHOD:
      draft.selectedShippingMethod = action.method;
      break;

    case constants.SET_PAYMENT_METHODS:
      draft.paymentMethods = action.paymentMethods;
      break;

    case constants.SELECT_PAYMENT_METHOD:
      draft.selectedPaymentMethod = action.paymentMethod;
      break;

    case constants.SET_TOTALS:
      draft.totals = action.totals;
      break;

    case constants.TOOGLE_LOCK_CART:
      draft.lock = action.lock;
      break;

    default:
      break;
  }
});

export default checkoutReducer;

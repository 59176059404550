import React from 'react';
import axios from 'axios';
import { actions } from 'containers/AuthorizationProvider/actions';
import { sprintf } from 'utils/helpers';
import { actions as notificationActions } from 'utils/Notifications/actions';
import CloseNotification from 'utils/Notifications/CloseNotification';

export default ({ dispatch }, queryClient) => axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response: { config, status, data } } = error;
    if (
      status === 401
      && data.message === 'The consumer isn\'t authorized to access %resources.'
      && !config.url.endsWith('/logout')
    ) {
      // Specific to the user's token being expired
      dispatch(actions.logOut());
      queryClient.removeQueries('couponPage.checkout', { exact: true });
      queryClient.removeQueries('couponPage.myCoupons', { exact: true });
      queryClient.removeQueries('orderListPage.orders', { exact: true });
      queryClient.removeQueries('loyalty.pointsPayout', { exact: true });
      queryClient.removeQueries('MembershipPage');
      queryClient.removeQueries('userProvider.customer', { exact: true });
      queryClient.removeQueries('SocialMarketerUpgrade.upgradeSMData', { exact: true });
      queryClient.removeQueries('sharingProvider.myShareDownline', { exact: true });
    }
    if (status >= 400 && status !== 401
      && data.message !== 'The consumer isn\'t authorized to access %resources.'
      && !config.url.includes('loyalty/use-points')) {
      dispatch(
        notificationActions.enqueueSnackbar({
          message: sprintf(data.message, data.parameters),
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'default',
            action: (key) => <CloseNotification notificationKey={key} />,
          },
        }),
      );
    }
    return Promise.reject(error);
  },
);

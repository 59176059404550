/* eslint-disable */

/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React, { Component, Suspense, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Helmet } from 'react-helmet-async';
import { createStructuredSelector } from 'reselect';
import { Switch, Route } from 'react-router-dom';
import get from 'lodash/get';
import isNull from 'lodash/isNull';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/en';
import 'dayjs/locale/zh-cn';
import { SnackbarProvider } from 'notistack';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import { withStyles, styled } from '@material-ui/core/styles';
import routes from 'utils/routes';
import { DEFAULT_STORE_NAME } from 'utils/constants';
import ScrollToTop from 'utils/RouterListener';
import Notifier from 'utils/Notifications';

import MainNavigation from 'containers/App/components/MainNavigation';
import Footer from 'components/Footer/Loadable';
import Header from 'components/Header/Loadable';
import {
  AccountLogInPage, RegistrationPage, ResetPasswordPage, OTPLogInPage,
} from 'containers/AuthorizationProvider/Loadable';
import AddressBookPage from 'containers/AddressBookPage/Loadable';
import Blog, { BlogDetailPage } from 'containers/Blog/Loadable';
import CartPage from 'containers/CartProvider/Loadable';
import CatalogPage, { FlashSale, SearchResultPage } from 'containers/CatalogPage/Loadable';
import CheckoutPage from 'containers/CheckoutPage/Loadable';
import CmsPage, { About } from 'containers/CmsPage/Loadable';
import CouponPage, { MyCouponPage } from 'containers/CouponPage/Loadable';
import DashboardPage from 'containers/DashboardPage/Loadable';
import Helper from 'containers/Helper';
import HomePage from 'containers/HomePage/Loadable';
import MembershipStatisticsPage from 'containers/MembershipPage/Loadable';
import NotFoundPage from 'containers/NotFoundPage/Loadable';
import OrderListPage from 'containers/OrderListPage/Loadable';
import OrderDetailPage, { OrderSuccessPage } from 'containers/OrderDetailPage/Loadable';
import ProductPage from 'containers/ProductPage/Loadable';
import ProfilePage from 'containers/ProfilePage/Loadable';
import RefundDetail, { OrderRefundApplicationPage, OrderRefundCourierPage } from 'containers/RefundPage/Loadable';
import SharingList, { CustomizeSharing, BrandEmbassador } from 'containers/SharingProvider/Loadable';
import SocialMarketerUpgradePage from 'containers/SocialMarketerUpgrade/Loadable';
import SMLogin from 'containers/SmLogin/Loadable';
import SmCustomer from 'containers/SmCustomer';
import SmInvoice from 'containers/SmInvoice';
import {
  PrivacyCenterPage,
  ConsentForms,
  DownloadAccount,
  DeleteAccount,
} from 'containers/PrivacyCenterPage/Loadable';

import theme, { LifeVantageActionBlue } from 'muiTheme';
import EnvRenderer from 'components/EnvRenderer';
import { actions } from 'containers/App/actions';
import { makeSelectMPLoginVisible } from 'containers/App/selectors';
import VerifyAuthentication from 'utils/Auth/VerifyAuthentication';
import RequireAuthentication from 'utils/Auth/RequireAuthentication';
import { actions as authActions } from 'containers/AuthorizationProvider/actions';
import PageLoader from 'components/PageLoader';
import useLogin from 'utils/Hooks/useLogin';
import useOpenedInWeapp from 'utils/Hooks/useOpenedInWeapp';

// Set up DayJS - Date Formatting
dayjs.locale('en');
dayjs.locale('zh-cn');
dayjs.extend(LocalizedFormat);
dayjs.extend(utc);

const VerifiedLogInPage = VerifyAuthentication(AccountLogInPage);
const VerifiedOTPLogInPage = VerifyAuthentication(OTPLogInPage);
const VerifiedResetPasswordPage = VerifyAuthentication(ResetPasswordPage);
const VerifiedRegistrationPage = VerifyAuthentication(RegistrationPage);

const ProtectedAddressBookPage = RequireAuthentication(AddressBookPage);
const ProtectedCheckoutPage = RequireAuthentication(CheckoutPage);
const ProtectedDashboardPage = RequireAuthentication(DashboardPage);
const ProtectedOrderSuccessPage = RequireAuthentication(OrderSuccessPage);
const ProtectedMyCouponPage = RequireAuthentication(MyCouponPage);
const ProtectedOrderListPage = RequireAuthentication(OrderListPage);
const ProtectedOrderDetailPage = RequireAuthentication(OrderDetailPage);
const ProtectedOrderRefundApplication = RequireAuthentication(OrderRefundApplicationPage);
const ProtectedOrderRefundCourier = RequireAuthentication(OrderRefundCourierPage);
const ProtectedRefundDetail = RequireAuthentication(RefundDetail);
const ProtectedProfilePage = RequireAuthentication(ProfilePage);
const ProtectedMembershipStatisticsPage = RequireAuthentication(MembershipStatisticsPage);
const ProtectedSharingList = RequireAuthentication(SharingList);
const ProtectedBrandEmbassador = RequireAuthentication(BrandEmbassador);
const ProtectedCustomizeSharing = RequireAuthentication(CustomizeSharing);
const ProtectedSocialMarketerUpgradePage = RequireAuthentication(SocialMarketerUpgradePage);
const ProtectedPrivacyCenterPage = RequireAuthentication(PrivacyCenterPage);
const ProtectedConsentFormsPage = RequireAuthentication(ConsentForms);
const ProtectedDownloadAccount = RequireAuthentication(DownloadAccount);
const ProtectedDeleteAccount = RequireAuthentication(DeleteAccount);

const snackbarStyles = {
  lessPadding: { paddingLeft: 16 },
  info: { backgroundColor: 'rgb(50, 50, 50)' },
  warning: { color: 'rgb(50, 50, 50)' },
};

const AppWrapper = styled(Box)(() => ({
  margin: '0 auto',
  display: 'flex',
  minHeight: '100vh',
  overflowX: 'hidden',
  flexDirection: 'column',
  backgroundColor: 'white',
  [theme.breakpoints.up('lg')]: {
    backgroundColor: (props) => (props.color === 'primary' ? LifeVantageActionBlue : 'hsl(0, 0%, 100%)'),
    minWidth: '1280px',
    maxWidth: '1920px',
    overflowX: 'hidden',
    minHeight: '100vh',
    padding: 0,
  },
}));

const getUrlParameter = (search, name) => {
  /* eslint-disable no-useless-escape */
  const parsed = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp(`[\?&]${parsed}=([^&#]*)`);
  const results = regex.exec(search);
  return isNull(results) ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
  /* eslint-enable no-useless-escape */
};

class App extends Component {
  componentDidMount() {
    // Check for token from MP
    const search = get(window.location, 'search', '');
    const token = getUrlParameter(search, 'token');
    // console.log('window.location.href', window.location.href);
    // console.log('location', window.location);
    // const userId = getUrlParameter(window.location.href, 'userId');
    // if (userId) {
    //   window['_paq'].push(['setUserId', userId]);
    // }
    if (!isNull(token) && token !== '') {
      this.props.logIn({
        token,
        complete: () => this.setUpApp(),
      });
    } else {
      this.setUpApp();
    }
    this.props.getGDPRConfig();
  }

  setUpApp() {
    this.props.setUpApp();
  }

  render() {
    const { classes, openedInWeappWebview } = this.props;
    return (
      <>
        <AppWrapper>
          <SnackbarProvider
            classes={{
              variantInfo: classes.info,
              variantWarning: classes.warning,
              lessPadding: classes.lessPadding,
            }}
            dense
            preventDuplicate
          >
            <Suspense
              fallback={(
                <Box width="100vw" height="100vh" display="flex">
                  <PageLoader />
                </Box>
              )}
            >

              <ScrollToTop />
              <CssBaseline />

              <Helmet titleTemplate={`%s - ${DEFAULT_STORE_NAME}`} defaultTitle={DEFAULT_STORE_NAME}>
                <meta name="description" content={DEFAULT_STORE_NAME} />
              </Helmet>

              <Header />

              <Switch>
                <Route path={routes.blogDetailPage()} />
                <Route exact path={routes.checkout} />
                <Route path={routes.coupons} />
                <Route path={routes.myCouponPage} />
                <Route path={routes.orderDetail()} />
                <Route path={routes.orders()} />
                <Route path={routes.ordersAll} />
                <Route path={routes.product()} />
                <Route path={routes.profile} />
                <Route path={routes.refundApplication} />
                <Route path={routes.refundDelivery} />
                <Route path={routes.refundDetail} />
                <Route path={routes.share} />
                <Route path={routes.sharingList} />
                <Route path={routes.upgrade} />
                <Route path={routes.privacy} />
                <Route path={routes.consentForm} />
                <Route path={routes.downloadAccount} />
                <Route path={routes.deleteAccount} />
                {!openedInWeappWebview && <Route path="" component={MainNavigation} />}
              </Switch>

              <Switch>
                <Route exact path={routes.blog} component={Blog} />
                <Route exact path={routes.helper} component={Helper} />
                <Route exact path={routes.SMLogin} component={SMLogin} />
                <Route exact path={routes.SmCustomer} component={SmCustomer} />
                <Route exact path={routes.SmInvoice} component={SmInvoice} />
                <Route exact path={routes.about} component={About} />
                <Route path={routes.addressBook} component={ProtectedAddressBookPage} />
                <Route exact path={routes.blogDetailPage()} component={BlogDetailPage} />
                <Route path={routes.catalog()} component={CatalogPage} />
                <Route exact path={routes.catalogEmpty} component={CatalogPage} />
                <Route exact path={routes.checkout} component={ProtectedCheckoutPage} />
                <Route path={routes.cart} component={CartPage} />
                <Route path={routes.cms()} component={CmsPage} />
                <Route path={routes.coupons} component={CouponPage} />
                <Route path={routes.dashboard} component={ProtectedDashboardPage} />
                <Route path={routes.forgotPassword} component={VerifiedResetPasswordPage} />
                <Route exact path={routes.flashsale} component={FlashSale} />
                <Route exact path={routes.home} component={HomePage} />
                <Route exact path={routes.logIn} component={VerifiedLogInPage} />
                <Route path={routes.myCouponPage} component={ProtectedMyCouponPage} />
                <Route path={routes.orderDetail()} component={ProtectedOrderDetailPage} />
                <Route path={[routes.orders(), routes.ordersAll]} component={ProtectedOrderListPage} />
                <Route path={routes.OTPLogIn} component={VerifiedOTPLogInPage} />
                <Route path={routes.product()} component={ProductPage} />
                <Route path={routes.profile} component={ProtectedProfilePage} />
                <Route path={routes.registration} component={VerifiedRegistrationPage} />
                <Route path={routes.refundApplication} component={ProtectedOrderRefundApplication} />
                <Route path={routes.refundDelivery} component={ProtectedOrderRefundCourier} />
                <Route path={routes.refundDetail} component={ProtectedRefundDetail} />
                <Route path={routes.search()} component={SearchResultPage} />
                <Route path={routes.share} component={ProtectedCustomizeSharing} />
                <Route path={routes.sharingList} component={ProtectedSharingList} />
                <Route path={routes.brandEmbassador} component={ProtectedBrandEmbassador} />
                <Route path={routes.statistics()} component={ProtectedMembershipStatisticsPage} />
                <Route path={routes.success()} component={ProtectedOrderSuccessPage} />
                <Route path={routes.upgrade} component={ProtectedSocialMarketerUpgradePage} />
                <Route path={routes.privacy} component={ProtectedPrivacyCenterPage} />
                <Route path={routes.consentForm} component={ProtectedConsentFormsPage} />
                <Route path={routes.downloadAccount} component={ProtectedDownloadAccount} />
                <Route path={routes.deleteAccount} component={ProtectedDeleteAccount} />
                <EnvRenderer env="wechat-mp" hide>
                  <Route path={routes.notFound} component={NotFoundPage} />
                </EnvRenderer>
              </Switch>

              <Notifier />
            </Suspense>

          </SnackbarProvider>

        </AppWrapper>
        <Footer />
      </>
    );
  }
}

App.propTypes = {
  setUpApp: PropTypes.func.isRequired,
  logIn: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  getGDPRConfig: PropTypes.func.isRequired,
};

const GlobalApp = ({ MPLoginVisible, toggleMPLoginVisible, ...other }) => {
  const [navigateToLogin] = useLogin();
  const openedInWeappWebview = useOpenedInWeapp();

  useEffect(() => {
    if (MPLoginVisible) {
      navigateToLogin();
      setTimeout(() => {
        toggleMPLoginVisible({ visible: false });
      }, 5000);
    }
  }, [MPLoginVisible]);

  return <App {...other} openedInWeappWebview={openedInWeappWebview} />;
};

GlobalApp.propTypes = {
  MPLoginVisible: PropTypes.bool.isRequired,
  toggleMPLoginVisible: PropTypes.func.isRequired,

};

const mapStateToProps = createStructuredSelector({
  MPLoginVisible: makeSelectMPLoginVisible(),
});

function mapDispatchToProps(dispatch) {
  return {
    setUpApp: () => dispatch(actions.setUpApp()),
    logIn: (payload) => dispatch(authActions.logIn(payload)),
    toggleMPLoginVisible: (payload) => dispatch(actions.toggleMPLoginVisible(payload)),
    getGDPRConfig: () => dispatch(actions.getGDPRConfig()),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withStyles(snackbarStyles),
  withConnect,
)(GlobalApp);

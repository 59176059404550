/*
 * App Actions
 */

const base = 'App';

export const GET_STORE_CONFIGS = `${base}/STORE_CONFIGS/GET`;

export const constants = {
  SET_UP_APP: `${base}/SET_UP`,
  GET_STORE_CONFIGS_REQUEST: `${GET_STORE_CONFIGS}_REQUEST`,
  TOGGLE_MP_LOGIN_VISIBLE: `${base}/TOGGLE_MP_LOGIN_VISIBLE`,
  SET_GDPR_CONFIG: `${base}/SET_GDPR_CONFIG`,
  GET_GDPR_CONFIG: `${base}/GET_GDPR_CONFIG`,
  SET_COOKIE_CONFIG: `${base}/SET_COOKIE_CONFIG`,
  GET_COOKIE_CONFIG: `${base}/GET_COOKIE_CONFIG`,
};

export const actions = {
  setUpApp: () => ({ type: constants.SET_UP_APP }),
  getStoreConfigs: () => ({ type: constants.GET_STORE_CONFIGS_REQUEST }),
  toggleMPLoginVisible: (payload = {}) => ({ type: constants.TOGGLE_MP_LOGIN_VISIBLE, payload }),
  setGDPRConfig: (payload = {}) => ({ type: constants.SET_GDPR_CONFIG, payload }),
  getGDPRConfig: () => ({ type: constants.GET_GDPR_CONFIG }),
  setCookieConfig: (payload = {}) => ({ type: constants.SET_COOKIE_CONFIG, payload }),
  getCookieConfig: () => ({ type: constants.GET_COOKIE_CONFIG }),
};

/**
 *
 * Asynchronously loads the component for Blog
 *
 */

import loadable from 'utils/loadable';

export const BlogDetailPage = loadable(() => import('./components/BlogDetailPage'));

export default loadable(() => import('./index'));

/* eslint-disable no-console */
/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import 'react-app-polyfill/stable';

// Import all the third party stuff
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser/esm';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import history from 'utils/history';
import { HelmetProvider } from 'react-helmet-async';
import axiosErrorInterceptor from 'utils/Auth/axiosErrorIntercepter';
import { LastLocationProvider } from 'react-router-last-location';
import { library } from '@fortawesome/fontawesome-svg-core';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { MuiThemeProvider } from '@material-ui/core/styles';
// Import root app
import App from 'containers/App';
// Sentry error reporting boundary
import ErrorBoundary from 'utils/Sentry/ErrorBoundary';
// Import Language Provider
import LanguageProvider from 'containers/LanguageProvider';
// Import Roboto Typeface
import './fonts/index.css';
// Load the favicon
// eslint-disable-next-line import/no-webpack-loader-syntax
import '!file-loader?name=[name].[ext]!images/favicon.ico';
import determineEnvironment from 'utils/WeChat/determineEnvironment';
import configureStore from './configureStore';
// Import i18n messages
import { translationMessages } from './i18n';
// Import Material UI custom theme
import theme from './muiTheme';
// Import Font Awesome 5 icons
import { ICONS } from './registerIcons';

library.add(ICONS);

// Holds Helmet state specific to each request
const helmetContext = {};

// Create redux store with history
const initialState = {};
const store = configureStore(initialState, history);
const queryClient = new QueryClient();

axiosErrorInterceptor(store, queryClient);
const MOUNT_NODE = document.getElementById('app');

// Initialize Sentry error reporting
if (process.env.PROJECT_ENV === 'staging' || process.env.PROJECT_ENV === 'production') {
  Sentry.init({
    dsn: `https://${process.env.SENTRY_DSN_TOKEN}@${process.env.SENTRY_DSN_URL}/${process.env.SENTRY_DSN_PROJECT}`,
    environment: `${process.env.PROJECT_ENV} - mobile`,
    maxBreadcrumbs: 50,
    debug: true,
  });
}

if (determineEnvironment()) {
  const wxScript = document.createElement('script');
  wxScript.async = true;
  wxScript.src = 'https://res.wx.qq.com/open/js/jweixin-1.3.2.js';
  const s0 = document.getElementsByTagName('script')[0];
  s0.parentNode.insertBefore(wxScript, s0);
}

const width = window.innerWidth;
if (width < 680) {
  setTimeout(() => {
    const sNew = document.createElement('script');
    sNew.async = true;
    sNew.src = 'https://cdn.grata.cn/domestic/js/js/chat-init.js?callback=730885602078';
    const s0 = document.getElementsByTagName('script')[0];
    s0.parentNode.insertBefore(sNew, s0);
  }, 10000);
}

const render = (messages) => {
  ReactDOM.render(
    <QueryClientProvider client={queryClient}>
      <HelmetProvider context={helmetContext}>
        <Provider store={store}>
          <LanguageProvider messages={messages}>
            <ConnectedRouter history={history}>
              <LastLocationProvider>
                <MuiThemeProvider theme={theme}>
                  <ErrorBoundary>
                    <App />
                  </ErrorBoundary>
                </MuiThemeProvider>
              </LastLocationProvider>
            </ConnectedRouter>
          </LanguageProvider>
        </Provider>
      </HelmetProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>,
    MOUNT_NODE,
  );
};

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept([
    './i18n',
    'containers/AuthorizationProvider/components/AccountLogInPage',
    'containers/AuthorizationProvider/components/RegistrationPage',
    'containers/AuthorizationProvider/components/ResetPasswordPage',
    'containers/AuthorizationProvider/components/OTPLogInPage',
    'containers/AddressBookPage',
    'containers/App',
    'containers/CatalogPage',
    'containers/CheckoutPage',
    'containers/CmsPage',
    'containers/DashboardPage',
    'containers/HomePage',
    'containers/LanguageProvider',
    'containers/MembershipPage',
    'containers/CartProvider',
    'containers/NotFoundPage',
    'containers/OrderDetailPage',
    'containers/OrderDetailPage/components/OrderSuccessPage',
    'containers/OrderListPage',
    'containers/ProductPage',
    'containers/ProfilePage',
    'containers/UserProvider',
  ], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render(translationMessages);
  });
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise((resolve) => {
    resolve(import('intl'));
  })
    .then(() => Promise.all([
      import('intl/locale-data/jsonp/en'),
      import('intl/locale-data/jsonp/zh'),
    ]))
    .then(() => render(translationMessages))
    .catch((err) => {
      throw err;
    });
} else {
  render(translationMessages);
}

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
if (process.env.NODE_ENV === 'production') {
  // eslint-disable-next-line global-require
  const runtime = require('offline-plugin/runtime');
  runtime.install({
    onUpdating: () => {
      console.log('SW Event:', 'onUpdating');
    },
    onUpdateReady: () => {
      console.log('SW Event:', 'onUpdateReady');
      // Tells to new SW to take control immediately
      runtime.applyUpdate();
    },
    onUpdated: () => {
      console.log('SW Event:', 'onUpdated');
      // Reload the webpage to load into the new version
      window.location.reload();
    },
    onUpdateFailed: () => {
      console.log('SW Event:', 'onUpdateFailed');
    },
  });
}

export default store;

/* eslint-disable import/prefer-default-export */
import { useQuery, useMutation } from 'react-query';
import UPGRADE_SM_DATA from 'containers/SocialMarketerUpgrade/gql/queryUpgradeData';
import MUTATE_SM_DATA from 'containers/SocialMarketerUpgrade/gql/mutationUpdateUpgradeData';
import requestGql, { querySetting } from 'utils/requestGql';
import request from 'utils/request';
import get from 'lodash/get';

const getUpgradeSMDataQuery = () => requestGql(UPGRADE_SM_DATA);
const updateSmDataQuery = (data) => requestGql(MUTATE_SM_DATA(data));
const uploadImagesForSMUpgrade = (type, payload) => request('POST', `/customer/${type}/image`, payload);
const getBankTypes = () => request('GET', '/yongyou/BankDotQuery_bankTypeQuery');
const getBankBranches = (type, query) => request('GET',
  `/yongyou/BankDotQuery_bankDotQuery?bankType=${encodeURIComponent(type)}&queryName=${encodeURIComponent(query)}`);

const getSMApplicationGDPR = () => request('GET', '/tmo/gdpr/customer/sm/application');

export const useGetUpgradeSmData = () => useQuery('SocialMarketerUpgrade.upgradeSMData',
  () => getUpgradeSMDataQuery().then((response) => get(response, 'customer', {})), querySetting);

export const useUpdateSmData = () => useMutation((customer) => updateSmDataQuery(customer));

export const useUploadImagesForSMUpgrade = () => useMutation(({ key, payload }) => uploadImagesForSMUpgrade(key, payload));

export const useGetBankTypes = () => useQuery('SocialMarketerUpgrade.bankTypes', () => getBankTypes().then((response) => {
  if (JSON.parse(response).status === '0') {
    const data = JSON.parse(response).result;
    const parsedBanks = Object.keys(data).map((key) => ({ value: key, label: data[key] }));
    parsedBanks.unshift({ value: 10000, label: '其他' });
    return parsedBanks;
  }
  return null;
}), querySetting);

export const useGetBankBranches = (bankType, query) => useQuery(bankType !== '0' && query !== '' && ['SocialMarketerUpgrade.bankBranches', `${bankType}-${query}`],
  () => getBankBranches(bankType, query).then((response) => {
    if (JSON.parse(response).status === '0') {
      return JSON.parse(response).result;
    }
    return null;
  }), querySetting);

export const useGetSMApplicationGDPR = () => useQuery('SocialMarketerUpgrade.getSMApplicationGDPR', () => getSMApplicationGDPR(), querySetting);

/*
 * SmInvoice Messages
 *
 * This contains all the text for the SmInvoice container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.SmInvoice';

export default defineMessages({
  searchPlaceholder: {
    id: `${scope}.searchPlaceholder`,
    defaultMessage: 'Search Phone or ID',
  },
  copySuccess: {
    id: `${scope}.copySuccess`,
    defaultMessage: 'Copy Success',
  },
});

/**
 *
 * SmInvoice
 *
 */

import React, { useState } from 'react';
import store from 'store';

import { useIntl } from 'react-intl';
import routes from 'utils/routes';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import request from 'utils/request';
import { getLocalTime } from 'utils/helpers';
import DayJSUtils from '@date-io/dayjs';
import dayjs from 'dayjs';
import { getAuthorization } from 'utils/Auth';
import { useGetRefundList } from 'containers/SmInvoice/queries';

import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import PageLoader from 'components/PageLoader';
import InvoiceForm from 'containers/SmInvoice/components/InvoiceForm';
import InvoiceHeader from 'containers/SmInvoice/components/InvoiceHeader';
import PaginationSimple from 'containers/SmInvoice/components/PaginationSimple';
import EmptyState from 'components/EmptyState';
import FormTextField from 'components/Inputs/FormTextField';
import SmSideNav from 'components/Layouts/SmSideNav';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Buttons from 'components/Buttons/Buttons';
import messages from './messages';
import useStyle from './style';

const initialFilter = {
  page: 1,
  firstname: '',
  lastname: '',
  customer_id: '',
  keywords: '',
  page_size: 10,
  status: 0,
  month: '',
};

export function SmInvoice() {
  const classes = useStyle();
  const auth = getAuthorization();
  const intl = useIntl();
  const [filter, setFilter] = useState(initialFilter);
  const [value, setValue] = React.useState(0);
  const [keywords, setKeywords] = useState('');
  const [selectedPeriod, setSelectedPeriod] = useState(getLocalTime(dayjs.utc().format()));
  const history = useHistory();
  const { data: smInvoice, isLoading } = useGetRefundList(filter);

  if (auth.type !== 'socialmarketer') {
    history.push(routes.SMLogin);
  }

  const hanldeExport = (month) => {
    const base = process.env.API_URL;
    const version = process.env.API_VERSION;
    const storeConfigs = store.get('store_configs');
    const apiUrl = `${base}${storeConfigs?.code || 'chn_zh'}/${version}`;

    const path = `${apiUrl}/customer_invoice/export?month=${month}`;
    return request('GET', path).then((response) => {
      const blob = new Blob([response], { type: 'application/octet-stream' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'Report.csv';
      link.click();
    });
  };

  const handleGetList = () => {
    setFilter(initialFilter);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    filter.status = newValue;
    setFilter(filter);
  };

  const changePage = (page) => {
    filter.page += page;
    setFilter(filter);
  };

  const hanldeChangeKeyword = (e) => {
    setKeywords(e.target.value);
  };

  const handleSearch = () => {
    filter.page = 1;
    filter.keywords = keywords;
    setFilter(filter);
  };

  const handlesSelectedPeriod = (e) => {
    setSelectedPeriod(e);
    const selectedMonth = e.format('YYYYMM');
    filter.month = selectedMonth;
    setFilter(filter);
  };

  if (selectedPeriod) {
    filter.month = selectedPeriod.format('YYYYMM');
  }
  return (
    <Box bgcolor="background.default" minHeight="100vh">

      <div className={classes.root}>
        <div className={classes.sidebar}>
          <SmSideNav />
        </div>
        <Grid className={classes.table}>
          <Typography className={classes.title} variant="h4" id="tableTitle" component="h4">
            SM发票管理
          </Typography>
          <Grid container className={classes.searchContainer}>
            <Grid item xs={6}>
              <MuiPickersUtilsProvider utils={DayJSUtils}>
                {selectedPeriod === 'qwe' ? (
                  <>
                    <div className={classes.datapickerlabel}>商业周期 (BP):</div>
                    <div className={classes.datePickerRoot}>所有</div>
                  </>
                ) : (
                  <>
                    <div className={classes.datapickerlabel}>商业周期 (BP):</div>
                    <DatePicker
                      animateYearScrolling
                      format="MMMM (YYYY)"
                      views={['year', 'month']}
                      disableFuture
                      value={selectedPeriod}
                      onChange={handlesSelectedPeriod}
                      minDate="2020-01"
                      className={classes.datePickerRoot}
                      InputLabelProps={{
                        classes: {
                          root: classes.periodInputLabel,
                        },
                      }}
                      InputProps={{
                        classes: {
                          input: classes.periodInputInput,
                          underline: classes.periodUnderline,
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <FontAwesomeIcon icon={['fas', 'caret-down']} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </>
                )}
              </MuiPickersUtilsProvider>

              <Buttons
                onClick={() => hanldeExport(filter.month)}
                messageId="导出全部"
                startIcon={<FontAwesomeIcon icon={['fal', 'download']} size="sm" />}
                color="primary"
                value="Submit"
                style={{ marginLeft: 20 }}
              />

              <Box className={classes.paginationTotal}>
                {!isLoading && smInvoice.items.length > 0 && (
                  <>
                    {smInvoice.total_count}
                    个结果，第
                    {smInvoice.page_info.current_page}
                    页
                  </>
                )}
              </Box>
            </Grid>
            <Grid item xs={6} className={classes.searchFormBox}>
              <FormTextField
                className={classes.searchForm}
                id="outlined-secondary"
                onChange={hanldeChangeKeyword}
                placeholder={intl.formatMessage(messages.searchPlaceholder)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FontAwesomeIcon icon={['fal', 'search']} size="sm" />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                color="secondary"
              />
              <Buttons
                onClick={handleSearch}
                messageId="搜索"
                startIcon={<FontAwesomeIcon icon={['fal', 'search']} />}
                color="primary"
                value="search"
                style={{ padding: 10 }}
              />
            </Grid>
          </Grid>

          <div className={classes.tabs}>
            <Tabs value={value} onChange={handleChange} className={classes.tabsRoot}>
              <Tab label="全部" />
              <Tab label="待处理" />
              <Tab label="已通过" />
              <Tab label="未通过" />
              <Tab label="已转账" />
            </Tabs>
            <Box className={classes.invoiceForm}>
              <InvoiceHeader />
              {!isLoading && smInvoice.items.length > 0
              && smInvoice.items.map((item) => (
                <InvoiceForm refresh={handleGetList} status={value} item={item} key={item.id} />
              ))}
              {isLoading && <Box height={636} width={960} display="flex"><PageLoader isLoading /></Box>}
              {!isLoading && smInvoice.items.length <= 0
                && <EmptyState title="没有记录" icon={<FontAwesomeIcon icon={['fal', 'map']} />} />}
            </Box>
            {!isLoading && smInvoice.items.length > 0 && (
              <PaginationSimple changePage={changePage} pageInfo={smInvoice.page_info} />
            )}
          </div>
        </Grid>
      </div>
    </Box>
  );
}

export default SmInvoice;

import React from 'react';
import Container from '@material-ui/core/Container';
import useStyle from 'containers/SmInvoice/style';

function InvoiceHeader() {
  const classes = useStyle();

  return (
    <Container className={classes.formContainer}>
      <ul className={`${classes.formRow} ${classes.rowHeader}`}>
        <li>客户ID</li>
        <li>时间</li>
        <li style={{ width: 160 }}>姓名</li>
        <li>SM用户组</li>
        <li>奖金数额</li>
        <li>需要发票</li>
        <li style={{ width: 200 }}>状态</li>
        <li>操作</li>
      </ul>
    </Container>
  );
}

export default InvoiceHeader;

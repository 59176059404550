import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    width: 1200,
    marginTop: 150,
    margin: '0 auto',
  },
  title: {
    fontSize: 21,
    fontWeight: 'bold',
    marginBottom: 8,
  },
  tabs: {
    width: 1000,
    float: 'right',
    marginBottom: 100,
    backgroundColor: theme.palette.primary.white,
    minHeight: '500px',
    padding: 20,
  },
  searchContainer: {
    width: 1000,
    float: 'right',
    height: 64,
    marginBottom: 24,
  },
  datapickerlabel: {
    fontWeight: 500,
    fontSize: 16,
    width: '110px',
    float: 'left',
    lineHeight: '30px',
  },
  table: {},
  sidebar: {
    width: 200,
    float: 'left',
    marginTop: -10,
  },
  formContainer: {
    borderBottom: '1px solid #ddd',
  },
  formRow: {
    height: 60,
    lineHeight: '60px',
    cursor: 'pointer',
    display: 'flex',
    fontSize: 16,
    color: theme.palette.text.secondary,
    listStyle: 'none',
    paddingLeft: 0,
    margin: 0,
    '& li': {
      minWidth: 90,
      padding: '0px 10px',
      overflow: 'hidden',
    },
  },
  rowHeader: {
    fontSize: '12px',
    color: theme.palette.primary.disabled,
  },
  formCol: {
    minWidth: 90,
    padding: '0px 10px',
    color: theme.palette.text.secondary,
  },
  form: {
    display: 'none',
    width: '100%',
    margin: 10,
    marginTop: 0,
    transition: '0.3s',
    animationTimingFunction: 'ease-in-out',
  },
  formContent: {
    transition: '0.3s',
    backgroundColor: theme.palette.background.default,
    borderRadius: '4px',
    padding: 20,
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  pagination: {
    float: 'right',
    marginTop: 10,
    fontSize: 14,
    color: '#757575',
  },
  iconBtn: {
    fontSize: 14,
  },
  formGroupRoot: {
    flexDirection: 'initial !important',
  },
  formLabel: {
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: '48px',
  },
  payRadio: {
    transition: '0.3s',
    backgroundColor: theme.palette.background.default,
    minHeight: 32,
    borderRadius: '4px',
    padding: 20,
    marginTop: 10,
  },
  formControlLabelTitle: {
    fontWeight: '500',
    marginRight: 20,
    color: theme.palette.text.secondary,
  },
  handleBtn: {
    height: 48,
    width: 182,
    border: '1px solid #a0a0a0',
    backgroundColor: theme.palette.primary.white,
    color: theme.palette.primary.disabled,
    margin: '6px',
  },
  paginationTotal: {
    color: theme.palette.text.secondary,
    fontSize: 12,
    lineHeight: '12px',
    marginTop: 18,
  },
  exportBtn: {
    fontSize: 16,
    fontWeight: 500,
    padding: 2,
    marginLeft: 20,
  },
  searchFormBox: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  searchForm: {
    width: 250,
    backgroundColor: theme.palette.primary.white,
  },
  invoiceForm: {
    minHeight: 700,
  },
  reasonField: {
    backgroundColor: theme.palette.primary.white,
    margin: '6px 0px',
    width: '376px',
    height: '64px',
    marginLeft: '6px',
  },
  selectField: {
    height: '48px',
    zIndex: 'inherit !important',
  },
  radioIcon: {
    display: 'inline-flex',
    alignItems: 'center',
    verticalAlign: 'middle',
    fontSize: 24,
    marginRight: '18px',
    color: theme.palette.text.secondary,
  },
  submit: {
    width: '180px',
    height: '48px',
    float: 'right',
    margin: '16px',
    marginRight: '0px',
    marginBottom: 0,
  },
  rejectBtn: {
    '&:hover': {
      color: theme.palette.warning.main,
      border: '1px solid #ea3f3f',
      backgroundColor: theme.palette.primary.white,
    },
  },
  passBtn: {
    '&:hover': {
      color: theme.palette.secondary.main,
      border: '1px solid #006dba',
      backgroundColor: theme.palette.primary.white,
    },
  },
  rejectBtnSelected: {
    color: theme.palette.warning.main,
    border: '1px solid #ea3f3f',
    backgroundColor: theme.palette.primary.white,
  },
  passBtnSelected: {
    color: theme.palette.secondary.main,
    border: '1px solid #006dba',
    backgroundColor: theme.palette.primary.white,
  },
  select: {
    cursor: 'pointer',
  },
  link: {
    color: theme.palette.text.secondary,
    fontWeight: 500,
    fontSize: '16px',
    textTransform: 'uppercase',
    padding: '0px',
    margin: 0,
    marginBottom: 32,
    '&:hover': {
      backgroundColor: theme.palette.primary.transparent,
    },
    '&:active': {
      backgroundColor: theme.palette.primary.transparent,
    },
    '&:focus': {
      backgroundColor: theme.palette.primary.transparent,
    },
  },
  linkIcon: {
    minWidth: 30,
    color: theme.palette.text.secondary,
  },
  active: {
    color: theme.palette.secondary.main,
    '& svg': {
      color: theme.palette.secondary.main,
    },
  },
  tabsRoot: {
    borderBottom: '0px !important',
  },
  periodInputLabel: {
    '&.Mui-focused': {
      color: theme.palette.text.secondary,
    },
  },
  periodInputInput: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
    fontSize: 16,
    maxWidth: 85,
    fontWeight: theme.typography.fontWeightMedium,
  },
  periodUnderline: {
    color: theme.palette.primary.main,
    '&::before': {
      display: 'none',
    },
    '&::after': {
      display: 'none',
    },
  },
  datePickerRoot: {
    width: 110,
    fontWeight: 500,
    fontSize: 16,
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
}));

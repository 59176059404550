/**
 * Endpoints for managing the checkout quote (uses the default Cart endpoints)
 */

import request from 'utils/request';

/**
 * @api {POST} /carts/mine Create checkout quote
 * @apiGroup Checkout
 */
export const createCheckoutQuote = () => request('POST', '/carts/mine');

/**
 * @api {GET} /carts/mine Fetch checkout quote
 * @apiGroup Checkout
 */
export const getCheckoutQuote = () => request('GET', '/carts/mine');

/**
 * @api {POST} /carts/mine/items Add item to checkout quote
 * @apiGroup Checkout
 */
export const addToCheckoutQuote = (payload) => request('POST', '/carts/mine/items', payload);

/**
 * Process Wechat QrPay payment
 * @param id
 * @param isOrder
 * @param payload
 * @returns {Promise<Object>}
 */
export const fetchWeChatQrPayParamaters = (id, isOrder = true, payload) => request('GET', `/wechatpay/getqrcode/${id}/?quoteIdIsOrderId=${isOrder.toString()}`, payload);

/**
 * @api {POST} /partial-checkout/checkout/add Move items from cart to checkout quote
 * @apiGroup Checkout
 */
export const moveToCheckoutQuote = (payload) => request('POST', '/partial-checkout/checkout/add', payload);

/**
 * @api {PUT} /partial-checkout/checkout/clear Clear checkout quote
 * @apiGroup Checkout
 */
export const clearCheckoutQuote = () => request('PUT', '/partial-checkout/checkout/clear');

/**
 * @api {POST} /carts/mine/estimate-shipping-methods Estimate shipping methods
 * @apiDescription Returns available shipping methods based on a shipping address.
 * @apiGroup Checkout
 */
export const estimateShippingCosts = (payload) => request('POST', '/carts/mine/estimate-shipping-methods-by-address-id', payload);

/**
 * @api {POST} /carts/mine/shipping-information Fetch checkout quote
 * @apiDescription Returns totals and available payment methods based on a shipping and a billing address and a shipping method.
 * @apiGroup Checkout
 */
export const addShippingInformation = (payload) => request('POST', '/carts/mine/shipping-information', payload);

/**
 * @api {POST} /carts/mine/billing-address Add billing information to checkout quote
 * @apiGroup Checkout
 */
export const addBillingInformation = (payload) => request('POST', '/carts/mine/billing-address', payload);

/**
 * @api {GET} /carts/mine/totals Fetch checkout quote totals
 * @apiGroup Checkout
 */
export const getCheckoutTotals = () => request('GET', '/carts/mine/totals');

// Order Processing

/**
 * @api {PUT} /carts/mine/order Place order
 * @apiGroup Checkout
 */
export const placeOrder = (payload) => request('PUT', '/carts/mine/order', payload);

/**
 * Process WeChat Pay Payment
 * @param {string} id           Should be either a (checkout) quote ID or and order ID
 * @param {boolean} isOrder     Whether the passed ID is from a quote or an order
 *
 * @api {GET} /wechatpay/geturl/:orderId/?quoteIdIsOrderId=:isOrder Fetch JS parameters to process a native WeChat Pay payment
 * @apiGroup Checkout
 */
export const fetchWeChatPayParamaters = (id, isOrder = true, payload) => request('GET', `/wechatpay/geturl/${id}/?quoteIdIsOrderId=${isOrder.toString()}`, payload);

// Coupon or discount related APIs

/**
 * @api {GET} /loyalty/use-points Check if can use credits to pay current quote
 * @apiGroup Checkout
 */
export const checkPoints = () => request('GET', '/loyalty/use-points');

/**
 * @api {PUT} /loyalty/use-points Use credits to pay
 * @apiGroup Checkout
 */
export const deployPoints = () => request('PUT', '/loyalty/use-points');

/**
 * @api {DELETE} /loyalty/use-points Remove credits to pay
 * @apiGroup Checkout
 */
export const removePoints = () => request('DELETE', '/loyalty/use-points');

import { createSelector } from '@reduxjs/toolkit';
import get from 'lodash/get';
import { initialState } from './reducer';

/**
 * Direct selector to the cart state domain
 */

const selectCartDomain = (state) => state.cart || initialState;

/**
 * Other specific selectors
 */

const makeSelectCartAllSelected = () => createSelector(
  selectCartDomain,
  (cart) => {
    let result = 0;
    if (cart.quote && cart.quote.items) {
      cart.selectedItems.forEach((i) => {
        cart.quote.items.forEach((i2) => {
          if (i === i2.item_id) {
            result += 1;
          }
        });
      });
      // result = cart.selectedItems.length === cart.quote.items.length;
    }
    return result === cart.quote.items.length;
  },
);

const makeSelectCartQuoteId = () => createSelector(
  selectCartDomain,
  (substate) => substate.quote.id,
);

const makeSelectCartItems = () => createSelector(
  selectCartDomain,
  (substate) => (substate.quote.items ? substate.quote.items : null),
);

const makeSelectSingleCartItem = (itemId) => createSelector(
  selectCartDomain,
  (substate) => (substate.quote.items
    ? substate.quote.items.find((i) => i.item_id === itemId) : null),
);

const makeSelectCartQuantity = () => createSelector(
  selectCartDomain,
  (substate) => {
    const cartItems = substate.quote.items;
    if (cartItems.length > 0) {
      return cartItems.reduce((acc, curr) => acc + curr.qty, 0);
    }
    return 0;
  },
);

const makeSelectCartQuantitySelected = () => createSelector(
  selectCartDomain,
  (cart) => {
    if (cart.selectedItems && cart.selectedItems.length > 0) {
      const cartItems = get(cart, 'quote.items', []);
      const selectedCartItems = cartItems.filter((i) => cart.selectedItems.indexOf(i.item_id) !== -1);
      return selectedCartItems.reduce((acc, curr) => acc + curr.qty, 0);
    }
    return 0;
  },
);

const makeSelectCartSelectedItems = () => createSelector(
  selectCartDomain,
  (substate) => substate.selectedItems,
);

const makeSelectCartItemIsSelected = (itemId) => createSelector(
  selectCartDomain,
  (substate) => substate.selectedItems.some((i) => i === itemId),
);

const makeSelectEditingCartItems = () => createSelector(
  selectCartDomain,
  (substate) => substate.editingItems,
);

const makeSelectSavingCartItems = () => createSelector(
  selectCartDomain,
  (substate) => substate.savingItems,
);

const makeSelectCartSubTotal = () => createSelector(
  selectCartDomain,
  (cart) => {
    let subTotal = 0;
    if (cart.selectedItems && cart.selectedItems.length > 0) {
      const selectedCartItems = cart.quote.items.filter((i) => cart.selectedItems.indexOf(i.item_id) !== -1);
      subTotal = selectedCartItems.reduce((acc, curr) => acc + (curr.qty * get(curr,
        'extension_attributes.calculated_price', curr.price)), subTotal);
    }
    return subTotal;
  },
);

const makeSelectCartPvTotal = () => createSelector(
  selectCartDomain,
  (cart) => {
    let pvTotal = 0;
    if (cart.selectedItems && cart.selectedItems.length > 0) {
      const selectedCartItems = cart.quote.items.filter((i) => cart.selectedItems.indexOf(i.item_id) !== -1);
      pvTotal = selectedCartItems.reduce((acc, curr) => acc + (get(curr,
        'extension_attributes.point_value', 0)), pvTotal);
    }
    return pvTotal;
  },
);

/**
 * Default selector used by Cart
 */

const makeSelectCart = () => createSelector(
  selectCartDomain,
  (substate) => substate,
);

const makeSelectLockCheckout = () => createSelector(
  selectCartDomain,
  (substate) => substate.lock,
);

const makeSelectLock1000 = () => createSelector(
  selectCartDomain,
  (substate) => substate.lock1000,
);

const makeSelectedCartPayload = () => createSelector(
  selectCartDomain,
  (cart) => {
    const payload = [];
    if (cart.selectedItems && cart.selectedItems.length > 0) {
      const cartItems = get(cart, 'quote.items', []);
      cartItems.forEach((i) => {
        if (cart.selectedItems.indexOf(i.item_id) !== -1) {
          // payload[i.item_id] = i.qty;
          payload.push({ item_id: i.item_id, qty: i.qty, sku: i.sku });// [i.item_id] = i.qty;
          return true;
        }
        return false;
      });
    }
    return payload;
  },
);
export default makeSelectCart;
export {
  makeSelectLock1000,
  makeSelectLockCheckout,
  selectCartDomain,
  makeSelectCartQuoteId,
  makeSelectCartItems,
  makeSelectSingleCartItem,
  makeSelectCartQuantity,
  makeSelectCartQuantitySelected,
  makeSelectCartSelectedItems,
  makeSelectCartAllSelected,
  makeSelectCartItemIsSelected,
  makeSelectEditingCartItems,
  makeSelectSavingCartItems,
  makeSelectCartSubTotal,
  makeSelectCartPvTotal,
  makeSelectedCartPayload,
};

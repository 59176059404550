import axios from 'axios';
import * as Sentry from '@sentry/browser';
import { getAuthorization } from 'utils/Auth';

/**
 * Basic query settings
 */

export const querySetting = {
  initialStale: true,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  retry: false,
};

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response.data.data;
  }

  const error = new Error(response.statusText);
  error.response = response;

  Sentry.withScope((scope) => {
    scope.setUser({ id: 'user' });
    scope.setTag('api.status', response.status);
    scope.setTag('api.method', response.config.method);
    scope.setTag('api.url', response.config.method);
    scope.setExtra('payload', response.config.data);
    Sentry.captureException(response.data);
  });

  throw error;
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {object} [data]    The options we want to pass to the request
 *
 * @return {object}           The response data
 */
export default function requestGql(data) {
  const auth = getAuthorization();

  const apiUrl = process.env.GQL_URL;

  return axios({
    method: 'POST',
    url: apiUrl,
    data: { query: data },
    withCredentials: true,
    ...(auth.type === 'user' || auth.type === 'socialmarketer'
      ? {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          'Content-Type': 'application/json',
        },
      }
      : { headers: { 'Content-Type': 'application/json' } }),
  }).then(checkStatus);
}

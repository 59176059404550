/*
 *
 * Checkout actions
 *
 */

const base = 'Checkout';
export const RESET_CHECKOUT = `${base}/RESET`;
export const GET_CHECKOUT_QUOTE = `${base}/QUOTE/GET`;
export const BUY_NOW = `${base}/BUY_NOW`;
export const PROCEED_TO_CHECKOUT = `${base}/PROCEED_TO_CHECKOUT`;

export const GET_ACTIVE_COUPONS = `${base}/COUPON/GET`;
export const TOGGLE_COUPON = `${base}/COUPON/TOGGLE`;

export const GET_SHIPPING_METHODS = `${base}/SHIPPING/METHODS/GET`;
export const ADD_SHIPPING_INFO = `${base}/SHIPPING/ADD`;
export const GET_TOTALS = `${base}/TOTALS/GET`;

export const PLACE_ORDER = `${base}/ORDER/CREATE`;
export const DELETE_CARD = `${base}/CC/DELETE`;
export const PROCESS_PAYMENT = `${base}/ORDER/PAY`;

export const USER_CREDIT = `${base}/ORDER/USER_CREDIT`;

export const constants = {
  GET_CHECKOUT_QUOTE_REQUEST: `${GET_CHECKOUT_QUOTE}_REQUEST`,
  GET_CHECKOUT_QUOTE_SUCCESS: `${GET_CHECKOUT_QUOTE}_SUCCESS`,
  GET_CHECKOUT_QUOTE_FAILURE: `${GET_CHECKOUT_QUOTE}_FAILURE`,
  SET_CHECKOUT_QUOTE: `${base}/QUOTE/SET`,
  CLEAR_CHECKOUT_QUOTE: `${base}/QUOTE/CLEAR`,
  CLEAR_CHECKOUT_QUOTE_COMPLETE: `${base}/QUOTE/CLEARED`,

  BUY_NOW_REQUEST: `${BUY_NOW}_REQUEST`,
  BUY_NOW_SUCCESS: `${BUY_NOW}_SUCCESS`,
  BUY_NOW_FAILURE: `${BUY_NOW}_FAILURE`,
  BUY_NOW_SET: `${BUY_NOW}/SET`,

  PROCEED_TO_CHECKOUT_REQUEST: `${PROCEED_TO_CHECKOUT}_REQUEST`,
  PROCEED_TO_CHECKOUT_SUCCESS: `${PROCEED_TO_CHECKOUT}_SUCCESS`,
  PROCEED_TO_CHECKOUT_FAILURE: `${PROCEED_TO_CHECKOUT}_FAILURE`,

  SELECT_SHIPPING_ADDRESS: `${base}/SHIPPING/ADDRESS/SELECT`,

  GET_SHIPPING_METHODS_REQUEST: `${GET_SHIPPING_METHODS}_REQUEST`,
  GET_SHIPPING_METHODS_SUCCESS: `${GET_SHIPPING_METHODS}_SUCCESS`,
  GET_SHIPPING_METHODS_FAILURE: `${GET_SHIPPING_METHODS}_FAILURE`,
  SET_SHIPPING_METHODS: `${base}/SHIPPING/METHODS/SET`,
  SELECT_SHIPPING_METHOD: `${base}/SHIPPING/METHODS/SELECT`,

  ADD_SHIPPING_INFO_REQUEST: `${ADD_SHIPPING_INFO}_REQUEST`,
  ADD_SHIPPING_INFO_SUCCESS: `${ADD_SHIPPING_INFO}_SUCCESS`,
  ADD_SHIPPING_INFO_FAILURE: `${ADD_SHIPPING_INFO}_FAILURE`,

  SET_PAYMENT_METHODS: `${base}/PAYMENT/METHODS/SET`,
  SELECT_PAYMENT_METHOD: `${base}/PAYMENT/METHODS/SELECT`,

  GET_TOTALS_REQUEST: `${GET_TOTALS}_REQUEST`,
  GET_TOTALS_SUCCESS: `${GET_TOTALS}_SUCCESS`,
  GET_TOTALS_FAILURE: `${GET_TOTALS}_FAILURE`,
  SET_TOTALS: `${base}/QUOTE/TOTALS/SET`,

  PLACE_ORDER_REQUEST: `${PLACE_ORDER}_REQUEST`,
  PLACE_ORDER_SUCCESS: `${PLACE_ORDER}_SUCCESS`,
  PLACE_ORDER_FAILURE: `${PLACE_ORDER}_FAILURE`,

  PLACE_ORDER_REQUEST_DESKTOP: `${PLACE_ORDER}_REQUEST_DESKTOP`,
  PLACE_ORDER_SUCCESS_DESKTOP: `${PLACE_ORDER}_SUCCESS_DESKTOP`,
  PLACE_ORDER_FAILURE_DESKTOP: `${PLACE_ORDER}_FAILURE_DESKTOP`,

  USER_CREDIT_REQUEST: `${USER_CREDIT}_REQUEST`,
  USER_CREDIT_SUCCESS: `${USER_CREDIT}_SUCCESS`,
  USER_CREDIT_FAILURE: `${USER_CREDIT}_FAILURE`,

  PROCESS_PAYMENT_REQUEST: `${PROCESS_PAYMENT}_REQUEST`,
  PROCESS_PAYMENT_SUCCESS: `${PROCESS_PAYMENT}_SUCCESS`,
  PROCESS_PAYMENT_FAILURE: `${PROCESS_PAYMENT}_FAILURE`,

  PROCESS_PAYMENT_REQUEST_DESKTOP: `${PROCESS_PAYMENT}_REQUEST_DESKTOP`,
  PROCESS_PAYMENT_SUCCESS_DESKTOP: `${PROCESS_PAYMENT}_SUCCESS_DESKTOP`,
  PROCESS_PAYMENT_FAILURE_DESKTOP: `${PROCESS_PAYMENT}_FAILURE_DESKTOP`,

  TOOGLE_LOCK_CART: `${PROCESS_PAYMENT}/TOOGLELOCKCART`,
};

export const actions = {
  toggleLockCart: (lock = false) => ({ type: constants.TOOGLE_LOCK_CART, lock }),
  getCheckoutQuote: (payload = {}) => ({ type: constants.GET_CHECKOUT_QUOTE_REQUEST, payload }),
  clearCheckoutQuote: () => ({ type: constants.CLEAR_CHECKOUT_QUOTE }),
  buyNow: (payload = {}) => ({ type: constants.BUY_NOW_REQUEST, payload }),
  proceedToCheckout: (itemIds = []) => ({ type: constants.PROCEED_TO_CHECKOUT_REQUEST, itemIds }),
  selectShippingAddress: (address) => ({ type: constants.SELECT_SHIPPING_ADDRESS, address }),
  selectShippingMethod: (method = {}) => ({ type: constants.SELECT_SHIPPING_METHOD, method }),
  addShippingInformation: (payload = {}) => ({ type: constants.ADD_SHIPPING_INFO_REQUEST, payload }),
  selectPaymentMethod: (paymentMethod) => ({ type: constants.SELECT_PAYMENT_METHOD, paymentMethod }),
  getTotals: () => ({ type: constants.GET_TOTALS_REQUEST }),
  toggleUseCredits: (payload) => ({ type: constants.USER_CREDIT_REQUEST, payload }),
  placeOrder: (complete) => ({ type: constants.PLACE_ORDER_REQUEST, complete }),
  placeOrderDesktop: (payload) => ({ type: constants.PLACE_ORDER_REQUEST_DESKTOP, payload }),
  processPayment: (payload = {}) => ({ type: constants.PROCESS_PAYMENT_REQUEST, payload }),
  processPaymentDesktop: (payload = {}) => ({ type: constants.PROCESS_PAYMENT_REQUEST_DESKTOP, payload }),
};

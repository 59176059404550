import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useStyle from 'containers/SmInvoice/style';
import Buttons from 'components/Buttons/Buttons';

function PaginationSimple({ changePage, pageInfo }) {
  const classes = useStyle();

  return (
    <>
      <Box className={classes.pagination}>
        {pageInfo.current_page}
        {' '}
        of
        {pageInfo.total_pages}
        <Buttons
          onClick={() => changePage(-1)}
          disabled={pageInfo.current_page <= 1}
          messageId={<FontAwesomeIcon icon={['far', 'chevron-left']} size="lg" />}
        />
        <Buttons
          onClick={() => changePage(1)}
          disabled={pageInfo.current_page >= pageInfo.total_pages}
          messageId={<FontAwesomeIcon icon={['far', 'chevron-right']} size="lg" />}
        />

      </Box>
    </>
  );
}

PaginationSimple.propTypes = {
  changePage: PropTypes.func,
  pageInfo: PropTypes.object,
};

export default PaginationSimple;

/**
 *
 * SmCustomer
 *
 */

import React, { useState } from 'react';

import routes from 'utils/routes';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getAuthorization } from 'utils/Auth';
import { FormattedMessage, useIntl } from 'react-intl';
import { useGetRefundList } from 'containers/SmCustomer/queries';
import useLogoutAction from 'utils/Hooks/useLogout';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormTextField from 'components/Inputs/FormTextField';
import Buttons from 'components/Buttons/Buttons';
import SmSideNav from 'components/Layouts/SmSideNav';
import upgradeMessages from 'containers/SocialMarketerUpgrade/messages';
import messages from 'containers/UserProvider/messages';
import Table from './components/Table';

const useStyle = makeStyles((theme) => ({
  root: {
    maxWidth: 1280,
    margin: '0 auto',
    flexGrow: 1,
    display: 'flex',
    marginTop: 150,
    '& > *': {
      borderBottom: 'unset',
    },
  },
  sidebar: {
    width: 200,
    float: 'left',
    marginTop: -10,
  },
  container: {
    minHeight: '60vh',
    maxWidth: '1000px',
    width: '100%',
  },
  searchInput: {
    backgroundColor: theme.palette.primary.white,
  },
  row: {
    display: 'flex',
    marginBottom: '20px',
    flexGrow: 1,
  },
}));

export function SmCustomer() {
  const classes = useStyle();
  const logOut = useLogoutAction();
  const auth = getAuthorization();
  const history = useHistory();
  const [formStatus, setFormStatus] = useState(-1);
  const [formKey, setFormKey] = useState('');
  const [filter, setFilter] = useState(
    'searchCriteria[filterGroups][0][filters][0][field]=llc_status&searchCriteria[filterGroups][0][filters][0][value]=0&searchCriteria[filterGroups][0][filters][0][conditionType]=gt&',
  );
  const { data: lists, refetch: refetchList } = useGetRefundList({ page: 1, filter });

  const intl = useIntl();

  if (auth.type !== 'socialmarketer') {
    history.push(routes.SMLogin);
  }

  const handleRefresh = () => {
    refetchList();
  };

  const handleLogout = () => {
    logOut();
    history.push(routes.SMLogin);
  };

  const handleChange = (e) => {
    setFormStatus(e.target.value);
  };
  const handleChangeKeywords = (e) => {
    setFormKey(e.target.value);
  };
  const handleSearch = () => {
    let str = '';
    if (formStatus === 0) {
      str
        += 'searchCriteria[filterGroups][0][filters][1][field]=llc_status&searchCriteria[filterGroups][0][filters][1][value]=4&';
    } else if (formStatus > -1) {
      str += `searchCriteria[filterGroups][0][filters][0][field]=llc_status&searchCriteria[filterGroups][0][filters][0][value]=${formStatus}&searchCriteria[filterGroups][0][filters][0][conditionType]=eq&`;
    } else {
      str = 'searchCriteria[filterGroups][0][filters][0][field]=llc_status&searchCriteria[filterGroups][0][filters][0][value]=0&searchCriteria[filterGroups][0][filters][0][conditionType]=gt&';
    }
    if (formKey) {
      str += `searchCriteria[filterGroups][1][filters][0][field]=mobile&searchCriteria[filterGroups][1][filters][0][value]=86${formKey}&searchCriteria[filterGroups][1][filters][0][conditionType]=like&`;
    }
    setFilter(str);
  };
  return (
    <Box bgcolor="background.default" minHeight="100vh">
      <Box className={classes.root}>
        <div className={classes.sidebar}>
          <SmSideNav />
        </div>
        <Box className={classes.container}>
          <div className={classes.row}>
            <Grid item xs={5} xl={5}>
              <Typography variant="h4" component="h4" id="tableTitle" gutterBottom>
                <FormattedMessage {...messages.smList} />
              </Typography>
              <Buttons
                onClick={() => handleRefresh()}
                messageId={<FormattedMessage {...messages.refresh} />}
                color="primary"
              />
              <Buttons
                onClick={handleLogout}
                messageId={<FormattedMessage {...messages.logOut} />}
                icon={<FontAwesomeIcon icon={['fal', 'sign-out']} />}
                color="primary"
              />
            </Grid>
            <Grid item xs={7} xl={7}>
              <div className={classes.margin} style={{ float: 'right' }}>
                <Grid container spacing={1}>
                  <Grid item>
                    <FormTextField
                      className={classes.searchInput}
                      id="outlined-secondary"
                      placeholder={intl.formatMessage(messages.mobileNumber)}
                      onChange={handleChangeKeywords}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <FontAwesomeIcon icon={['fal', 'search']} size="sm" />
                          </InputAdornment>
                        ),
                      }}
                      variant="outlined"
                      color="secondary"
                    />
                  </Grid>
                  <Grid item>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <Select
                        className={classes.searchInput}
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={formStatus}
                        style={{ maxHeight: 64 }}
                        onChange={handleChange}
                      >
                        <MenuItem value={-1}>
                          <FormattedMessage {...messages.allStatus} />
                        </MenuItem>
                        <MenuItem value={0}>
                          <FormattedMessage {...upgradeMessages.statusPending} />
                        </MenuItem>
                        <MenuItem value={1}>
                          <FormattedMessage {...upgradeMessages.statusApproved} />
                        </MenuItem>
                        <MenuItem value={2}>
                          <FormattedMessage {...upgradeMessages.statusDeclined} />
                        </MenuItem>
                        <MenuItem value={3}>
                          <FormattedMessage {...upgradeMessages.statusExpired} />
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Buttons
                    onClick={handleSearch}
                    messageId={<FormattedMessage {...messages.search} />}
                    startIcon={<FontAwesomeIcon icon={['fal', 'search']} />}
                    color="primary"
                    value="search"
                  />
                </Grid>
              </div>
            </Grid>
          </div>
          <Box display="flex">
            {lists && <Table handleLists={handleRefresh} lists={lists} />}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default SmCustomer;

/*
 *
 * Authorization actions
 *
 */

const base = 'Auth';

export const GET_CODE = `${base}/CODE/GET`;
export const VERIFY_PASSWORD = `${base}/PASSWORD/VERIFY`;
export const LOG_IN = `${base}/LOG_IN`;

export const constants = {
  SET_AUTH: `${base}/TOKEN/SET`,

  CLEAR_LOG_IN_INFO: `${base}/LOG_IN_INFO/CLEAR`,

  GET_CODE_REQUEST: `${GET_CODE}_REQUEST`,
  GET_CODE_SUCCESS: `${GET_CODE}_SUCCESS`,
  GET_CODE_FAILURE: `${GET_CODE}_FAILURE`,

  START_TIMER: `${base}/TIMER/START`,
  STOP_TIMER: `${base}/TIMER/STOP`,
  TICK: `${base}/TIMER/TICK`,

  REGISTER: `${base}/REGISTER`,

  LOG_IN_REQUEST: `${LOG_IN}_REQUEST`,
  LOG_IN_SUCCESS: `${LOG_IN}_SUCCESS`,
  LOG_IN_FAILURE: `${LOG_IN}_FAILURE`,

  RESET_PASSWORD: `${base}/RESET_PASSWORD`,

  LOG_OUT: `${base}/LOG_OUT`,
};

export const actions = {
  clearLogInInfo: () => ({ type: constants.CLEAR_LOG_IN_INFO }),
  getCode: (payload = {}) => ({ type: constants.GET_CODE_REQUEST, payload }),
  register: (payload = {}) => ({ type: constants.REGISTER, payload }),
  logIn: (payload = {}) => ({ type: constants.LOG_IN_REQUEST, payload }),
  logOut: () => ({ type: constants.LOG_OUT }),
  resetPassword: (payload = {}) => ({ type: constants.RESET_PASSWORD, payload }),
};

import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectShowMiniCart, toggleMinicart } from 'containers/App/slices';
import ReactGA from 'react-ga';

function RouterListener() {
  const { location } = useHistory();
  const dispatch = useDispatch();
  const showMiniCart = useSelector(selectShowMiniCart);

  useEffect(() => {
    if (process.env.PROJECT_ENV === 'production') {
      ReactGA.initialize('UA-170646874-1');
    } else {
      ReactGA.initialize('UA-165717540-1');
    }
    ReactGA.ga('set', 'currencyCode', 'CNY'); // Set currency to RMB.
    ReactGA.plugin.require('ec');
    ReactGA.pageview(window.location.pathname + window.location.search);
    window.scrollTo(0, 0);
    if (showMiniCart) {
      dispatch(toggleMinicart(false));
    }
  }, [location.pathname, location.search]);

  return null;
}

export default RouterListener;

/**
 *
 * Asynchronously loads the component for Authorization
 *
 */

import loadable from 'utils/loadable';

export const AccountLogInPage = loadable(() => import('./components/AccountLogInPage'));
export const OTPLogInPage = loadable(() => import('./components/OTPLogInPage'));
export const RegistrationPage = loadable(() => import('./components/RegistrationPage'));
export const ResetPasswordPage = loadable(() => import('./components/ResetPasswordPage'));

/**
 *
 * EmptyState
 *
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Buttons from 'components/Buttons/Buttons';
import { makeStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';

const useStyles = makeStyles((theme) => ({
  EmptyStateBox: {
    padding: theme.spacing(0, 2),
    display: 'flex',
    height: '70vh',
    textAlign: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0.05, 0.2),
      width: 360,
      margin: theme.spacing(0, 'auto'),
      textAlign: 'center',
      height: '50vh',
    },
  },
}));

function EmptyState({
  title, body, icon, action,
}) {
  const classes = useStyles();

  return (
    <Box className={classes.EmptyStateBox}>
      <Hidden lgUp>
        {title && <Typography variant="h1" paragraph>{title}</Typography>}
        {icon && <Box mb={1} fontSize="88px" color="primary.disabled">{icon}</Box>}
        {body && <Box color="primary.disabled" mb={5.25}><Typography variant="h2" color="inherit">{body}</Typography></Box>}
      </Hidden>

      <Hidden mdDown>
        {icon && <Box mt={5} mb={3} fontSize="120px" color="primary.disabled">{icon}</Box>}
        {title && <Box mb={6}><Typography variant="h2" component="h2" color="textSecondary" paragraph>{title}</Typography></Box>}
        {body && <Box mb={2}><Typography style={{ fontWeight: 700 }} variant="subtitle1" component="p">{body}</Typography></Box>}
      </Hidden>

      {action && typeof action.event === 'string'
        && (
        <Buttons
          variant="contained"
          route={action.event || '/'}
          messageId={action.label}
          endIcon={<FontAwesomeIcon icon={['fal', 'chevron-right']} />}
        />
        )}

      {action && typeof action.event === 'function'
        && (
        <Buttons
          variant="contained"
          onClick={action.event}
          messageId={action.label}
          endIcon={<FontAwesomeIcon icon={['fal', 'chevron-right']} />}
        />
        )}
    </Box>
  );
}

EmptyState.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  icon: PropTypes.node,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  action: PropTypes.exact({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    event: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  }),
};

export default memo(EmptyState);

/*
 * SocialMarketerUpgrade Messages
 *
 * This contains all the text for the SocialMarketerUpgrade container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.SocialMarketerUpgrade';

export default defineMessages({
  upgradeToSM: {
    id: `${scope}.upgradeToSM`,
    defaultMessage: 'Upgrade to SM',
  },
  readAndAgree: {
    id: `${scope}.readAndAgree`,
    defaultMessage: 'Have read and agree to our',
  },
  termsAndConditions: {
    id: `${scope}.termsAndConditions`,
    defaultMessage: 'Terms and laws',
  },
  applyType: {
    id: `${scope}.applyType`,
    defaultMessage: 'Apply Type',
  },
  others: {
    id: `${scope}.others`,
    defaultMessage: 'Others',
  },
  pleaseChangeInfobeforeSubmit: {
    id: `${scope}.pleaseChangeInfobeforeSubmit`,
    defaultMessage: 'Please Change the Information before Submitting',
  },
  statusWaitting: {
    id: `${scope}.statusWaitting`,
    defaultMessage: 'Wiatting',
  },
  welcomeToSMApplication: {
    id: `${scope}.welcomeToSMApplication`,
    defaultMessage: 'Welcome to SM application',
  },
  licenseTips: {
    id: `${scope}.licenseTips`,
    defaultMessage: '*If you do not have a business license, you can contact Lihua to help you proceed.',
  },
  pleaseEnterEmailAddress: {
    id: `${scope}.pleaseEnterEmailAddress`,
    defaultMessage: 'Please enter your email address first',
  },
  updateEmailAddress: {
    id: `${scope}.updateEmailAddress`,
    defaultMessage: 'Update email address',
  },
  adjustEmailUsedForApplication: {
    id: `${scope}.adjustEmailUsedForApplication`,
    defaultMessage: 'Adjust the email address used for your SM application',
  },
  requiredDocuments: {
    id: `${scope}.requiredDocuments`,
    defaultMessage: 'Required Documents',
  },
  youMustSubmitAllInformation: {
    id: `${scope}.youMustSubmitAllInformation`,
    defaultMessage: 'You must submit all information to apply for SM',
  },
  pleaseInputInfoWithCare: {
    id: `${scope}.pleaseInputInfoWithCare`,
    defaultMessage: 'Please input all information with care',
  },
  optionalParentheses: {
    id: `${scope}.optionalParentheses`,
    defaultMessage: '(optional)',
  },
  chineseIDInformation: {
    id: `${scope}.chineseIDInformation`,
    defaultMessage: 'Chinese ID Information',
  },
  frontOfID: {
    id: `${scope}.frontOfID`,
    defaultMessage: 'Front of ID Card',
  },
  backOfID: {
    id: `${scope}.backOfID`,
    defaultMessage: 'Back of ID Card',
  },
  pleaseUploadOrTakePhoto: {
    id: `${scope}.pleaseUploadOrTakePhoto`,
    defaultMessage: 'Please Upload or Take a Photo',
  },
  pleaseAddBothFrontAndBack: {
    id: `${scope}.pleaseAddBothFrontAndBack`,
    defaultMessage: 'Please add both front and back pictures',
  },
  chinaLLCInformation: {
    id: `${scope}.chinaLLCInformation`,
    defaultMessage: 'China LLC Information',
  },
  fullLegalName: {
    id: `${scope}.fullLegalName`,
    defaultMessage: 'Full legal name',
  },
  idNumber: {
    id: `${scope}.idNumber`,
    defaultMessage: '18-digit ID number',
  },
  companyName: {
    id: `${scope}.companyName`,
    defaultMessage: 'Company name',
  },
  businessLicense: {
    id: `${scope}.businessLicense`,
    defaultMessage: 'Business license',
  },
  businessLicenseNumber: {
    id: `${scope}.businessLicenseNumber`,
    defaultMessage: 'Business license number',
  },
  relatedCertificate: {
    id: `${scope}.relatedCertificate`,
    defaultMessage: 'Related Certificate',
  },
  certificateType: {
    id: `${scope}.certificateType`,
    defaultMessage: 'Certificate type',
  },
  certificate: {
    id: `${scope}.certificate`,
    defaultMessage: 'Certificate',
  },
  bankDepositInformation: {
    id: `${scope}.bankDepositInformation`,
    defaultMessage: 'Bank Deposit Information',
  },
  bankName: {
    id: `${scope}.bankName`,
    defaultMessage: 'Bank Name',
  },
  bankBranchName: {
    id: `${scope}.bankBranchName`,
    defaultMessage: 'Bank Branch Name',
  },
  bankNamePlaceholder: {
    id: `${scope}.bankNamePlaceholder`,
    defaultMessage: 'Select bank name',
  },
  bankBranchNamePlaceholder: {
    id: `${scope}.bankBranchNamePlaceholder`,
    defaultMessage: 'Enter province and city',
  },
  bankBranchNameNote: {
    id: `${scope}.bankBranchNameNote`,
    defaultMessage: 'Please provide province and city information and we can more easily help you find your branch name.',
  },
  receivingAccountName: {
    id: `${scope}.receivingAccountName`,
    defaultMessage: 'Receiving Account Name',
  },
  receivingAccountNumber: {
    id: `${scope}.receivingAccountNumber`,
    defaultMessage: 'Receiving Account Number',
  },
  previousUploads: {
    id: `${scope}.previousUploads`,
    defaultMessage: 'Previous Upload(s)',
  },
  submissionIsUnderReview: {
    id: `${scope}.submissionIsUnderReview`,
    defaultMessage: 'Your submission is under review, please be patient.',
  },
  statusSubmit: {
    id: `${scope}.statusSubmit`,
    defaultMessage: 'Submited',
  },
  statusPending: {
    id: `${scope}.statusPending`,
    defaultMessage: 'Under Review',
  },
  statusDeclined: {
    id: `${scope}.statusDeclined`,
    defaultMessage: 'Declined',
  },
  statusExpired: {
    id: `${scope}.statusExpired`,
    defaultMessage: 'Expired',
  },
  statusApproved: {
    id: `${scope}.statusApproved`,
    defaultMessage: 'Approved',
  },
  statusWaiting: {
    id: `${scope}.statusWaiting`,
    defaultMessage: 'Waiting',
  },
  backToEdit: {
    id: `${scope}.backToEdit`,
    defaultMessage: 'Back to Edit',
  },
  individualApplication: {
    id: `${scope}.individualApplication`,
    defaultMessage: 'Individual Application',
  },
  IBEApplication: {
    id: `${scope}.IBEApplication`,
    defaultMessage: 'IBE Application',
  },
  input: {
    id: `${scope}.input`,
    defaultMessage: 'Input',
  },
  submitAndReturn: {
    id: `${scope}.submitAndReturn`,
    defaultMessage: 'Submit And Return',
  },
  expireTitle: {
    id: `${scope}.expireTitle`,
    defaultMessage: 'Reason',
  },
  SMsubmit: {
    id: `${scope}.SMsubmit`,
    defaultMessage: 'Submit',
  },
  copySuccess: {
    id: `${scope}.copySuccess`,
    defaultMessage: 'Copy Success',
  },
  id: {
    id: `${scope}.id`,
    defaultMessage: 'Upgrade to SM',
  },
  enterInformation: {
    id: `${scope}.enterInformation`,
    defaultMessage: 'Enter Information',
  },
  saveAndNextStep: {
    id: `${scope}.saveAndNextStep`,
    defaultMessage: 'Save and Next Step',
  },
  closeAway: {
    id: `${scope}.closeAway`,
    defaultMessage: 'Enter Information',
  },
  pleaseEnterInfobeforeSubmit: {
    id: `${scope}.pleaseEnterInfobeforeSubmit`,
    defaultMessage: 'Please Enter Complete Information before Submitting',
  },
  returnToSMStatistic: {
    id: `${scope}.returnToSMStatistic`,
    defaultMessage: 'Return to SM Statistics Center',
  },
  applicationUnderReview: {
    id: `${scope}.applicationUnderReview`,
    defaultMessage: 'Your Application is being Reviewed by our Professionals',
  },
  fillApplicationInfo: {
    id: `${scope}.fillApplicationInfo`,
    defaultMessage: '2. Fill in your Application Information',
  },
  authFaildResubmit: {
    id: `${scope}.authFaildResubmit`,
    defaultMessage: 'Some Authentication Failed, Please Resubmit',
  },
  expiredData: {
    id: `${scope}.expiredData`,
    defaultMessage: 'Please Resubmit the Information Below',
  },
  resubmit: {
    id: `${scope}.resubmit`,
    defaultMessage: 'Please Resubmit',
  },
  fillAndResubmit: {
    id: `${scope}.fillAndResubmit`,
    defaultMessage: 'Please Fill in the Problematic Part and Resubmit',
  },
  name: {
    id: `${scope}.name`,
    defaultMessage: 'Name',
  },
  mobile: {
    id: `${scope}.mobile`,
    defaultMessage: 'Mobile',
  },
  ibeid: {
    id: `${scope}.ibeid`,
    defaultMessage: 'IBE Number',
  },
  status: {
    id: `${scope}.status`,
    defaultMessage: 'Status',
  },
  createdAt: {
    id: `${scope}.createdAt`,
    defaultMessage: 'Created At',
  },
  operation: {
    id: `${scope}.operation`,
    defaultMessage: 'Operation',
  },
  number: {
    id: `${scope}.number`,
    defaultMessage: 'ID',
  },
  update: {
    id: `${scope}.update`,
    defaultMessage: 'Update',
  },
  pass: {
    id: `${scope}.pass`,
    defaultMessage: 'Pass',
  },
  reject: {
    id: `${scope}.reject`,
    defaultMessage: 'Reject',
  },
  rejectReason: {
    id: `${scope}.rejectReason`,
    defaultMessage: 'Reject Reason',
  },
  smAgreement: {
    id: `${scope}.smAgreement`,
    defaultMessage: 'Terms and laws',
  },
});

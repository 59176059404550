import { actions } from 'containers/AuthorizationProvider/actions';
import { useDispatch } from 'react-redux';
import { useQueryClient } from 'react-query';

const useLogoutAction = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const logOut = () => {
    dispatch(actions.logOut());
    queryClient.removeQueries('couponPage.checkout', { exact: true });
    queryClient.removeQueries('couponPage.myCoupons', { exact: true });
    queryClient.removeQueries('orderListPage.orders', { exact: true });
    queryClient.removeQueries('loyalty.pointsPayout', { exact: true });
    queryClient.removeQueries('userProvider.customer', { exact: true });
    queryClient.removeQueries('MembershipPage');
    queryClient.removeQueries('SocialMarketerUpgrade.upgradeSMData', { exact: true });
    queryClient.removeQueries('sharingProvider.myShareDownline', { exact: true });
  };
  return logOut;
};

export default useLogoutAction;

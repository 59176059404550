/* eslint-disable no-console */
import React from 'react';
import {
  takeEvery, takeLeading, select, call, put, all, debounce,
} from 'redux-saga/effects';
import { FormattedMessage } from 'react-intl';
import isUndefined from 'lodash/isUndefined';
import { sprintf } from 'utils/helpers';
import get from 'lodash/get';
import set from 'lodash/set';
import CloseNotification from 'utils/Notifications/CloseNotification';
import { actions as notificationActions } from 'utils/Notifications/actions';
import { getAuthorization, setAuthorization } from 'utils/Auth';
import { constants as authConstants } from 'containers/AuthorizationProvider/actions';
import { makeSelectLockCart } from 'containers/CheckoutPage/selectors';
import { constants } from './actions';
import {
  makeSelectCartAllSelected, makeSelectCartItems, makeSelectSingleCartItem,
  makeSelectCartQuoteId, makeSelectEditingCartItems,
} from './selectors';
import {
  addToCartQuote, addToCartQuoteArray, addToGuestCart, createGuestCart, getCartQuote,
  getGuestCart, removeFromCartQuote, removeFromGuestCart, updateCartQuoteItem,
  updateGuestCartItem,
} from './api';
import messages from './messages';

/**
 * Set up cart by fetching cart quote and creating a guest quote if tokens are unavailable
 */
export function* fetchCartQuote() {
  const auth = getAuthorization();
  let quote = {};
  try {
    if (!isUndefined(auth.token)) {
      if (auth.type === 'user') {
        quote = yield call(getCartQuote);
      } else {
        quote = yield call(getGuestCart, auth.token);
        yield put({ type: authConstants.SET_AUTH, payload: { type: 'guest', token: auth.token } });
      }
    } else {
      const token = yield call(createGuestCart, auth.token);
      yield put({ type: authConstants.SET_AUTH, payload: { type: 'guest', token } });
      setAuthorization(token, 'guest');
      quote = yield call(getGuestCart, token);
    }

    yield put({ type: constants.SET_CART_QUOTE, quote });
    yield put({ type: constants.GET_CART_QUOTE_SUCCESS });
  } catch (err) {
    console.log('Error: ', err);
    yield put({ type: constants.GET_CART_QUOTE_FAILURE });
  }
}

/**
 * Request/response handler to add products to the Cart Quote
 */
export function* handleAddToCartQuote(action) {
  const {
    data, complete, error, calculatedPrice,
  } = action.payload;
  // for limit buynow quantity
  // check if over limit 5000yuan
  if (calculatedPrice && calculatedPrice * data.cartItem.qty > 5000) {
    yield put(notificationActions.enqueueSnackbar({
      message: sprintf('单个订单金额不能超过5000元', ''),
      options: {
        key: new Date().getTime() + Math.random(),
        variant: 'warning',
        action: (key) => <CloseNotification notificationKey={key} />,
      },
    }));
    yield put({ type: constants.ADD_TO_CART_FAILURE });
    if (error) error();
    return;
  }

  const lockCart = yield select(makeSelectLockCart());
  if (lockCart) {
    yield put(notificationActions.enqueueSnackbar({
      message: <FormattedMessage {...messages.pleaseWaiting} />,
      options: {
        key: new Date().getTime() + Math.random(),
        variant: 'error',
        action: (key) => <CloseNotification notificationKey={key} />,
      },
    }));

    yield put({ type: constants.ADD_TO_CART_FAILURE });
    if (complete) complete();
    return;
  }

  yield put({ type: constants.TOOGLE_LOCK_CHECKOUT, lock: true });

  const auth = getAuthorization();
  const cartId = yield select(makeSelectCartQuoteId());
  try {
    let item = {};
    // eslint-disable-next-line prefer-const
    let newData = JSON.parse(JSON.stringify(data));
    if (auth.type === 'user') {
      newData.cartItem.quote_id = cartId;
      item = yield call(addToCartQuote, newData, auth.token);
    } else {
      newData.cartItem.quote_id = auth.token;
      item = yield call(addToGuestCart, newData, auth.token);
    }

    yield put(notificationActions.enqueueSnackbar({
      message: <FormattedMessage {...messages.productHasBeenAddedToCart} values={{ name: item.name }} />,
      options: {
        key: new Date().getTime() + Math.random(),
        variant: 'success',
        action: (key) => <CloseNotification notificationKey={key} />,
      },
    }));

    yield put({ type: constants.TOGGLE_CART_ITEM, itemId: item.item_id });

    yield put({ type: constants.ADD_TO_CART_SUCCESS });
    yield put({ type: constants.TOOGLE_LOCK_CHECKOUT, lock: false });
    if (complete) complete();
  } catch (err) {
    console.log('Error: ', err);
    yield put({ type: constants.ADD_TO_CART_FAILURE });
    yield put({ type: constants.TOOGLE_LOCK_CHECKOUT, lock: false });
    if (error) error();
  } finally {
    yield put({ type: constants.GET_CART_QUOTE_REQUEST, payload: {} });
  }
}

/**
 * Request/response handler to update existing Cart Quote items
 */
export function* handleCartQuoteItemUpdate(action) {
  const { data, complete, error } = action.payload;

  yield put({ type: constants.TOOGLE_LOCK_1000, lock: false });

  yield put({ type: constants.TOOGLE_LOCK_CHECKOUT, lock: true });
  const auth = getAuthorization();
  const cartId = yield select(makeSelectCartQuoteId());
  const initial = yield select(makeSelectSingleCartItem(data.cartItem.item_id));
  try {
    yield put({ type: constants.SET_CART_UPDATE, cartItem: data.cartItem });
    yield put({ type: constants.SAVING_SINGLE, itemId: data.cartItem.item_id });

    let item = {};
    // eslint-disable-next-line prefer-const
    let newData = JSON.parse(JSON.stringify(data));
    if (auth.type === 'user') {
      newData.cartItem.quote_id = cartId;
      item = yield call(updateCartQuoteItem, newData.cartItem.item_id, newData);
    } else {
      newData.cartItem.quote_id = auth.token;
      item = yield call(updateGuestCartItem, newData.cartItem.item_id, newData, auth.token);
    }

    yield put({ type: constants.SET_CART_UPDATE, cartItem: item });
    yield put({ type: constants.CLEAR_FROM_EDIT, itemId: item.item_id });
    yield put({ type: constants.UPDATE_CART_SUCCESS });
    yield put({ type: constants.FINISH_SINGLE_SAVE, itemId: item.item_id });
    yield put({ type: constants.TOOGLE_LOCK_CHECKOUT, lock: false });
    yield put({ type: constants.GET_CART_QUOTE_REQUEST, payload: {} });

    if (complete) complete();
  } catch (err) {
    console.log('Error: ', err);
    yield put({ type: constants.TOOGLE_LOCK_CHECKOUT, lock: false });
    yield put({ type: constants.UPDATE_CART_FAILURE });
    yield put({ type: constants.SET_CART_UPDATE, cartItem: initial });
    yield put({ type: constants.CLEAR_FROM_EDIT, itemId: data.cartItem.item_id });
    yield put({ type: constants.FINISH_SINGLE_SAVE, itemId: data.cartItem.item_id });
    if (error) error();
  }
}

/**
 * Request/response handler to remove item from Cart Quote
 */
export function* handleCartQuoteItemRemoval(action) {
  const { itemId, complete } = action.payload;
  const auth = getAuthorization();
  yield put({ type: constants.TOOGLE_LOCK_CHECKOUT, lock: true });
  const allSelected = yield select(makeSelectCartAllSelected());
  try {
    let result;
    if (auth.type === 'user') {
      result = yield call(removeFromCartQuote, itemId);
    } else {
      result = yield call(removeFromGuestCart, itemId, auth.token);
    }

    if (result === true) {
      yield put({ type: constants.SET_CART_REMOVAL, itemId });
      if (allSelected) {
        yield put({ type: constants.TOGGLE_ALL_CART_ITEMS, checked: true });
      }
    }

    yield put({ type: constants.TOOGLE_LOCK_CHECKOUT, lock: false });
    if (complete) complete(result);
  } catch (err) {
    yield put({ type: constants.TOOGLE_LOCK_CHECKOUT, lock: false });
    console.log('Error: ', err);
  }
}

/**
 * Request/response handler to update a set of Cart Quote items
 */

/* eslint-disable */
export function* handleCartQuoteItemUpdateMultiple() {
  const cartItems = yield select(makeSelectCartItems());
  const editingCartItems = yield select(makeSelectEditingCartItems());
  try {
    yield all([...cartItems.map((i) => {
      if (editingCartItems[i.item_id]) {
        if (editingCartItems[i.item_id].qty !== i.qty) {
          return put({
            type: constants.UPDATE_CART_REQUEST,
            payload: { data: { cartItem: editingCartItems[i.item_id] } }
          });
        } else {
          return put({ type: constants.CLEAR_FROM_EDIT, itemId: i.item_id });
        }
      }
    })]);

    yield put({ type: constants.SAVE_ALL_SUCCESS });

  } catch (err) {
    console.log('Error: ', err);
    yield put({ type: constants.SAVE_ALL_FAILURE });
  }
}

/**
 * Request/response handler to update existing Cart Quote items
 */
export function* handleCartQuoteItemUpdateLocal(action) {
  const { data, complete, error } = action.payload;
  yield put({ type: constants.TOOGLE_LOCK_1000, lock: false });
  yield put({ type: constants.TOOGLE_LOCK_CHECKOUT, lock: true });

  const lockCart = yield select(makeSelectLockCart());

  if (lockCart) {
    // normally user can't go there
    return;
  }
  const initial = yield select(makeSelectSingleCartItem(data.cartItem.item_id));
  // eslint-disable-next-line no-mixed-operators
  const pv = get(initial, 'extension_attributes.point_value', 0) / initial.qty * data.cartItem.qty;
  set(data.cartItem, 'extension_attributes.point_value', pv);
  try {
    yield put({ type: constants.SET_CART_UPDATE, cartItem: data.cartItem });
    yield put({ type: constants.UPDATE_CART_SUCCESS });
    yield put({ type: constants.CLEAR_FROM_EDIT, itemId: data.item_id });
    yield put({ type: constants.UPDATE_CART_SUCCESS });

    yield put({
      type: constants.EDIT_SINGLE,
      payload: {
        item: data.cartItem, quantity: data.cartItem.qty
      }
    });

    yield put({ type: constants.FINISH_SINGLE_SAVE, itemId: data.item_id });
    yield put({ type: constants.TOOGLE_LOCK_CHECKOUT, lock: false });
    if (complete) {
      complete();
    }
  } catch (err) {
    console.log('Error: ', err);
    yield put({ type: constants.TOOGLE_LOCK_CHECKOUT, lock: false });
    yield put({ type: constants.UPDATE_CART_FAILURE });
    yield put({ type: constants.SET_CART_UPDATE, cartItem: initial });
    yield put({ type: constants.CLEAR_FROM_EDIT, itemId: data.cartItem.item_id });
    yield put({ type: constants.FINISH_SINGLE_SAVE, itemId: data.cartItem.item_id });

    if (error) error();
  }
}

export function* handleCartSynchronize(action) {
  const { complete } = action.payload;
  yield put({ type: constants.TOOGLE_LOCK_CHECKOUT, lock: true });
  const cartItems = yield select(makeSelectCartItems());
  const editingCartItems = yield select(makeSelectEditingCartItems());
  const auth = getAuthorization();
  try {
    let results = [];
    yield all([...cartItems.map((i) => {
      if (editingCartItems[i.item_id]) {
        results.push(i);
        return put({ type: constants.CLEAR_FROM_EDIT, itemId: i.item_id });
      }

    })]);
    if (results.length > 0) {
      if (auth.type === 'user') {
        yield call(addToCartQuoteArray, { cartItemArray: results }, auth.token);
      }
    }
    yield put({ type: constants.SAVE_ALL_SUCCESS });
    yield put({ type: constants.TOOGLE_LOCK_CHECKOUT, lock: false });
    if (complete) complete(results);

  } catch (err) {
    console.log('Error: ', err);
    yield put({ type: constants.TOOGLE_LOCK_CHECKOUT, lock: false });
    yield put({ type: constants.SAVE_ALL_FAILURE });
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* rootSaga() {
  yield all([
    takeEvery(constants.GET_CART_QUOTE_REQUEST, fetchCartQuote),
    takeEvery(constants.ADD_TO_CART_REQUEST, handleAddToCartQuote),
    debounce(0, constants.UPDATE_CART_REQUEST, handleCartQuoteItemUpdate),
    takeLeading(constants.REMOVE_FROM_CART_REQUEST, handleCartQuoteItemRemoval),
    takeLeading(constants.SAVE_ALL_REQUEST, handleCartSynchronize),
    takeEvery(constants.UPDATE_CART_REQUEST_LOCAL, handleCartQuoteItemUpdateLocal)
  ]);
}

import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { getAuthorization } from 'utils/Auth';
import determineEnvironment from 'utils/WeChat/determineEnvironment';
import routes from 'utils/routes';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions as appActions } from 'containers/App/actions';

const RequireAuthentication = (WrappedComponent) => {
  const Child = ({ quoteID, toggleMPLoginVisible, ...other }) => {
    const auth = getAuthorization();
    if (typeof auth.token !== 'undefined' && auth.type === 'user') {
      return <WrappedComponent {...other} />;
    }

    if (determineEnvironment()) {
      toggleMPLoginVisible({ visible: true });
      return <Redirect to={routes.home} />;
    }

    return <Redirect to={routes.logIn} />;
  };

  Child.propTypes = {
    quoteID: PropTypes.number,
    toggleMPLoginVisible: PropTypes.func,
  };

  return Child;
};

RequireAuthentication.propTypes = {
  WrappedComponent: PropTypes.element.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    toggleMPLoginVisible: (payload) => dispatch(appActions.toggleMPLoginVisible(payload)),
  };
}

const withConnect = connect(
  null,
  mapDispatchToProps,
);

export default compose(withConnect, RequireAuthentication);

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Lightbox } from 'react-modal-image';
import Buttons from 'components/Buttons/Buttons';
import messages from 'containers/SocialMarketerUpgrade/messages';
import appMessages from 'containers/App/messages';

const useStyles = makeStyles((theme) => ({
  label: {
    display: 'block',
    marginBottom: theme.spacing(1),
  },
  list: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridGap: theme.spacing(1),
  },
  attachment: {
    borderRadius: 4,
    boxShadow: '0 1px 10px 0 rgba(0, 0, 0, 0.15)',
    border: `1px solid ${theme.palette.divider}`,
  },
  preview: {
    marginRight: theme.spacing(1),
    width: '100%',
    height: '5vw',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundColor: theme.palette.background.default,
    borderRadius: 3,
  },
  zoom: {
    '& .__react_modal_image__modal_container': {
      backgroundColor: theme.palette.grey[900],
    },
  },
}));

const width = 60 * window.devicePixelRatio;
const height = 30 * window.devicePixelRatio;

function Attachments({ image }) {
  const classes = useStyles();
  const [zoom, setZoom] = useState(false);
  const [zoomURL, setZoomURL] = useState('');

  const handleZoom = (url) => () => {
    setZoomURL(url);
    setZoom(true);
  };

  const handleCloseZoom = () => {
    setZoomURL('');
    setZoom(false);
  };

  if (!image || image.length < 1) return <></>;

  return (
    <Box style={{ marginBottom: 5 }}>
      <Typography variant="caption" color="textSecondary" className={classes.label}>
        <FormattedMessage {...messages.previousUploads} />
      </Typography>
      <Box className={classes.list}>
        <Box
          className={classes.attachment}
          onClick={handleZoom(`${process.env.BASE_MEDIA}/customer${image}`)}
        >
          <Box
            className={classes.preview}
            style={{
              backgroundImage: `url(${process.env.BASE_MEDIA}/customer${image})`,
            }}
          />

          <Buttons
            messageId={<FormattedMessage {...appMessages.view} />}
            icon={<FontAwesomeIcon icon={['fal', 'search']} />}
            color="secondary"
          />

        </Box>
      </Box>
      {zoom && zoomURL && (
        <Lightbox
          small={`${zoomURL}?w=${width}&h=${height}`}
          medium={`${zoomURL}`}
          large={`${zoomURL}`}
          alt=""
          className={classes.zoom}
          hideDownload
          imageBackgroundColor="transparent"
          onClose={handleCloseZoom}
        />
      )}
    </Box>
  );
}

Attachments.propTypes = {
  image: PropTypes.string,
};

export default Attachments;

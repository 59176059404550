/*
 * Checkout Messages
 *
 * This contains all the text for the Checkout container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.helper';

export default defineMessages({
  helpCenter: {
    id: `${scope}.helpCenter`,
    defaultMessage: 'Welcome to the LifeVantage® Help Center',
  },
  subHelpCenter: {
    id: `${scope}.subHelpCenter`,
    defaultMessage: 'If you can’t solve the problem, please contact our staff',
  },
  customerFAQ: {
    id: `${scope}.customerFAQ`,
    defaultMessage: 'Customer FAQ',
  },
});

/*
 * User Messages
 *
 * This contains all the text for the User container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.User';

export default defineMessages({
  dashboard: {
    id: `${scope}.dashboard`,
    defaultMessage: 'Dashboard',
  },
  brandEmbassador: {
    id: `${scope}.brandEmbassador`,
    defaultMessage: 'Brand ambassador',
  },
  myInvitation: {
    id: `${scope}.myInvitation`,
    defaultMessage: 'My Invitations',
  },
  inviteFriends: {
    id: `${scope}.inviteFriends`,
    defaultMessage: 'Invite Friends',
  },
  editAccountInfo: {
    id: `${scope}.editAccountInfo`,
    defaultMessage: 'Edit Account Info',
  },
  report: {
    id: `${scope}.report`,
    defaultMessage: 'Report',
  },
  bankInfoUploadSuccess: {
    id: `${scope}.bankInfoUploadSuccess`,
    defaultMessage: 'Bank Info Upload Success',
  },
  welcomeBack: {
    id: `${scope}.welcomeBack`,
    defaultMessage: 'Welcome Back!',
  },
  orders: {
    id: `${scope}.orders`,
    defaultMessage: 'Orders',
  },
  haveNotPlacedOrdersYet: {
    id: `${scope}.haveNotPlacedOrdersYet`,
    defaultMessage: "You haven't placed any orders yet. Why not start now?",
  },
  myProfile: {
    id: `${scope}.myProfile`,
    defaultMessage: 'My Profile',
  },
  myCoupons: {
    id: `${scope}.myCoupons`,
    defaultMessage: 'My Coupons',
  },
  addressBook: {
    id: `${scope}.addressBook`,
    defaultMessage: 'Address Book',
  },
  youHaveNoAddresses: {
    id: `${scope}.youHaveNoAddresses`,
    defaultMessage: 'You have no addresses yet, why not start by adding one?',
  },
  favourites: {
    id: `${scope}.favourites`,
    defaultMessage: 'Favourites',
  },
  phoneNumberChangedSuccessfully: {
    id: `${scope}.phoneNumberChangedSuccessfully`,
    defaultMessage: 'Phone Number Successfully Changed',
  },
  logOut: {
    id: `${scope}.logOut`,
    defaultMessage: 'Log Out',
  },
  notifications: {
    id: `${scope}.notifications`,
    defaultMessage: 'Notifications',
  },
  editProfile: {
    id: `${scope}.editProfile`,
    defaultMessage: 'Edit Profile',
  },
  guest: {
    id: `${scope}.guest`,
    defaultMessage: 'Guest',
  },
  customer: {
    id: `${scope}.customer`,
    defaultMessage: 'Customer',
  },
  vip: {
    id: `${scope}.vip`,
    defaultMessage: 'VIP',
  },
  customerText: {
    id: `${scope}.customerText`,
    defaultMessage: 'You can purchase products by registering as our favorite users',
  },
  guestText: {
    id: `${scope}.guestText`,
    defaultMessage: 'You can purchase products by registering as our favorite users',
  },
  vipText: {
    id: `${scope}.vipText`,
    defaultMessage:
      'You can get product redemption points by sharing your experience with others around you.',
  },
  smibeText: {
    id: `${scope}.smibeText`,
    defaultMessage:
      'Social e-commerce can have the opportunity to get promotion bonuses by helping us promote good product usage and a healthy lifestyle.',
  },
  smpersonalText: {
    id: `${scope}.smpersonalText`,
    defaultMessage:
      'Social e-commerce can have the opportunity to get promotion bonuses by helping us promote good product usage and a healthy lifestyle.',
  },
  smpersonal: {
    id: `${scope}.sm`,
    defaultMessage: 'SM',
  },
  sm: {
    id: `${scope}.sm`,
    defaultMessage: 'SM',
  },
  smibe: {
    id: `${scope}.sm`,
    defaultMessage: 'SM',
  },

  settings: {
    id: `${scope}.settings`,
    defaultMessage: 'Settings',
  },
  orderIncomplete: {
    id: `${scope}.orderIncomplete`,
    defaultMessage: ' {count, plural, =0 {} one {Order Incomplete!} other {Orders Incomplete!}}',
  },
  userType: {
    id: `${scope}.userType`,
    defaultMessage: '{group} User',
  },
  accountID: {
    id: `${scope}.accountID`,
    defaultMessage: 'Account ID',
  },
  pointsValue: {
    id: `${scope}.pointsValue`,
    defaultMessage: 'Points Value',
  },
  recentlyAddedFavourites: {
    id: `${scope}.recentlyAddedFavourites`,
    defaultMessage: 'Recently Added Favourites',
  },
  favouritesIsEmpty: {
    id: `${scope}.favouritesIsEmpty`,
    defaultMessage: 'You have no favourites',
  },
  defaultAddress: {
    id: `${scope}.defaultAddress`,
    defaultMessage: 'Default Address',
  },
  addressesIsEmpty: {
    id: `${scope}.addressesIsEmpty`,
    defaultMessage: 'Add your first address',
  },
  profileUpdatedSuccessfully: {
    id: `${scope}.profileUpdatedSuccessfully`,
    defaultMessage: 'Your profile has been updated successfully',
  },
  passwordChangedSuccessfully: {
    id: `${scope}.passwordChangedSuccessfully`,
    defaultMessage: 'Your password has been changed successfully',
  },
  addressAddedSuccessfully: {
    id: `${scope}.addressAddedSuccessfully`,
    defaultMessage: 'Your address has been added successfully',
  },
  addressUpdatedSuccessfully: {
    id: `${scope}.addressUpdatedSuccessfully`,
    defaultMessage: 'Your address has been changed successfully',
  },
  addressDeletedSuccessfully: {
    id: `${scope}.addressDeletedSuccessfully`,
    defaultMessage: 'Your address has been deleted successfully',
  },
  recentOrders: {
    id: `${scope}.recentOrders`,
    defaultMessage: 'Recent Orders',
  },
  myOrders: {
    id: `${scope}.myOrders`,
    defaultMessage: 'Recent Orders',
  },
  moreOpportunities: {
    id: `${scope}.moreOpportunities`,
    defaultMessage: 'Join Social Marketer system now! Get more offers and more opportunities.',
  },
  viewMoreOrders: {
    id: `${scope}.viewMoreOrders`,
    defaultMessage: 'Please go to order list page and view more orders.',
  },
  allStatus: {
    id: `${scope}.allStatus`,
    defaultMessage: 'All Status',
  },
  search: {
    id: `${scope}.search`,
    defaultMessage: 'Search',
  },
  mobileNumber: {
    id: `${scope}.mobileNumber`,
    defaultMessage: 'Mobile Number',
  },
  smList: {
    id: `${scope}.smList`,
    defaultMessage: 'SocialMarket Apply List',
  },
  refresh: {
    id: `${scope}.refresh`,
    defaultMessage: 'Refresh',
  },
  llcCheck: {
    id: `${scope}.llcCheck`,
    defaultMessage: 'LLC Information',
  },
  smInvoice: {
    id: `${scope}.smInvoice`,
    defaultMessage: 'SM Invoice',
  },
  ibe: {
    id: `${scope}.ibe`,
    defaultMessage: 'IBE',
  },
  credits: {
    id: `${scope}.credits`,
    defaultMessage: 'Credits',
  },
  smAppliedSuccessfully: {
    id: `${scope}.smAppliedSuccessfully`,
    defaultMessage: 'You have applied SM successfully',
  },
  privacyCenter: {
    id: `${scope}.privacyCenter`,
    defaultMessage: 'Privacy Center',
  },
});

/**
 *
 * FormTextField
 *
 */
import React, { forwardRef, memo } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

const FormTextField = forwardRef(({
  id, InputProps, label, errors, ...other
}, ref) => (
  <TextField
    id={id}
    name={id}
    label={label}
    inputRef={ref}
    InputProps={InputProps}
    error={!!(errors && errors[id])}
    {...other}
  />
));

FormTextField.defaultProps = {
  InputProps: {},
  errors: {},
};

FormTextField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
  ]),
  InputProps: PropTypes.object,
  errors: PropTypes.object,
};

export default memo(FormTextField);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import * as Sentry from '@sentry/browser';
import { FormattedMessage, injectIntl } from 'react-intl';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { styled } from '@material-ui/core/styles';
import { makeSelectRouterLocation } from 'containers/App/selectors';
import appMessages from 'containers/App/messages';
import EmptyState from 'components/EmptyState/Loadable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Buttons from 'components/Buttons/Buttons';
import routes from 'utils/routes';
import theme, { LifeVantageActionBlue } from 'muiTheme';

const AppWrapper = styled(Box)({
  margin: '0 auto',
  display: 'flex',
  minHeight: 'calc(100vh - var(--vh-offset, 0px))',
  overflowX: 'hidden',
  flexDirection: 'column',
  backgroundColor: 'white',
  [theme.breakpoints.up('lg')]: {
    backgroundColor: (props) => (props.color === 'primary' ? LifeVantageActionBlue : 'hsl(0, 0%, 100%)'),
    minWidth: '1280px',
    maxWidth: '1920px',
    overflowX: 'initial',
    minHeight: 'calc(100vh - var(--vh-offset, 0px))',
    padding: 0,
  },
});

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventId: null,
      isDev: process.env.PROJECT_ENV === 'development',
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidUpdate(prevProps) {
    const { router } = this.props;
    if (prevProps.router.pathname !== router.pathname) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ hasError: false });
    }
  }

  componentDidCatch(error, errorInfo) {
    if (!this.state.isDev) {
      const { router } = this.props;
      Sentry.withScope((scope) => {
        scope.setUser({ id: 'user' });
        scope.setTag('customer.type', 'user');
        scope.setTag('page', router.pathname);
        scope.setExtras(errorInfo);
        const eventId = Sentry.captureException(error);
        this.setState({ eventId });
      });
    }
  }

  showFeedbackForm = () => {
    const { intl } = this.props;
    Sentry.showReportDialog({
      eventId: this.state.eventId,
      title: intl.formatMessage({ id: 'sentry.title' }),
      subtitle: intl.formatMessage({ id: 'sentry.subtitle' }),
      subtitle2: intl.formatMessage({ id: 'sentry.subtitle2' }),
      labelName: intl.formatMessage({ id: 'sentry.labelName' }),
      labelEmail: intl.formatMessage({ id: 'sentry.labelEmail' }),
      labelComments: intl.formatMessage({ id: 'sentry.labelComments' }),
      labelClose: intl.formatMessage({ id: 'sentry.labelClose' }),
      labelSubmit: intl.formatMessage({ id: 'sentry.labelSubmit' }),
      errorGeneric: intl.formatMessage({ id: 'sentry.errorGeneric' }),
      errorFormEntry: intl.formatMessage({ id: 'sentry.errorFormEntry' }),
      successMessage: intl.formatMessage({ id: 'sentry.successMessage' }),
    });
  };

  render() {
    if (!this.state.isDev && this.state.hasError) {
      // render fallback UI
      return (
        <AppWrapper>
          <CssBaseline />
          <EmptyState
            icon={<FontAwesomeIcon icon={['fal', 'exclamation-circle']} />}
            body={(
              <Typography variant="body1" color="textSecondary" component="span">
                <FormattedMessage {...appMessages.ourTeamHasBeenNotified} />
              </Typography>
            )}
            action={{
              label: <FormattedMessage {...appMessages.reportFeedback} />,
              event: this.showFeedbackForm,
            }}
          />

          <Buttons
            fullWidth
            route={routes.home}
            style={{ marginTop: -50 }}
            messageId="首页"
            startIcon={<FontAwesomeIcon icon={['fal', 'chevron-left']} />}
          />
        </AppWrapper>
      );
    }

    // when there's not an error, render children untouched
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  intl: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  router: makeSelectRouterLocation(),
});

const withConnect = connect(mapStateToProps);

export default compose(
  injectIntl,
  withConnect,
)(ErrorBoundary);

/*
 * Copyright © TMO Group. All rights reserved.
 * @package: {PACKAGE NAME}
 * @subpackage: {SUBPACKAGE NAME}
 * @description:
 * @author: tmogroup.asia
 */
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import useMPBridge from 'utils/Hooks/useMPBridge';
import { actions as appActions } from 'containers/App/actions';
import { actions as authActions } from 'containers/AuthorizationProvider/actions';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import { useHistory } from 'react-router-dom';
import { getAuthorization } from 'utils/Auth';

const useLogin = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const logIn = useCallback((payload) => dispatch(authActions.logIn(payload)), [dispatch]);
  const setUpApp = useCallback((payload) => dispatch(appActions.setUpApp(payload)), [dispatch]);

  const auth = getAuthorization();

  const [MPLogin] = useMPBridge('login', ({ token, redirectUrl }) => {
    if (!isNull(token) && !isUndefined(token) && token !== '') {
      logIn({
        token,
        complete: () => setUpApp(),
      });
    } else if (redirectUrl) {
      history.replace(redirectUrl);
      return;
    } else {
      setUpApp();
    }
    history.goBack();
  }, { path: '/pages/login/login' });

  const navigateToLogin = useCallback((options) => {
    MPLogin({ ...options, params: { ...(auth.token ? { cartID: auth.token } : {}) } });
  }, [MPLogin, auth.token]);

  return [navigateToLogin];
};

export default useLogin;

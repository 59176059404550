/**
 * @api {QUERY} customer Get upgrade-related data from customer
 * @apiGroup Membership
 */
export default `
{
    customer {
            id
            apply_type
            bank_account_name
            bank_account_number
            bank_branch
            bank_name
            bank_reason
            bank_status
            certificate_photo
            certificate_reason
            certificate_status
            certificate_type
            email
            email_changed
            firstname
            id_back
            id_front
            id_reason
            id_status
            lastname
            llc_business_id
            llc_business_name
            llc_license
            llc_reason
            llc_representative_id
            llc_representative_name
            llc_status
            sm_status
            submit_status
      }
}`;

/**
 *
 * MainNavigation
 *
 */

import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeCookieConfig } from 'containers/App/selectors';
import debounce from 'lodash/debounce';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect, useSelector, useDispatch } from 'react-redux';
import { selectShowMiniCart, toggleMinicart } from 'containers/App/slices';
import routes from 'utils/routes';
import { getAuthorization } from 'utils/Auth';
import determineEnvironment from 'utils/WeChat/determineEnvironment';
import { actions as appActions } from 'containers/App/actions';

import AppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import MiniCart from 'containers/CartProvider';
import CookieUpdateBottom from 'containers/PrivacyCenterPage/components/CookieUpdateBottom';
import messages from '../messages';

const useStyles = makeStyles((theme) => ({
  root: {
    top: 'auto',
    bottom: 0,
    padding: 0,
  },
  toolbar: {
    padding: 0,
    paddingBottom: '35px',
    background: '#fff',
  },
  nav: {
    width: '100%',
    height: theme.mixins.toolbar.minHeight,
  },
  icon: {
    marginBottom: 3,
  },
  button: {
    minWidth: 0,
    padding: theme.spacing(0.5, 1),
    color: theme.palette.text.primary,
    fontSize: 22,
  },
}));

const navigationLocations = [
  { url: routes.home, icon: 'home', message: messages.home },
  { url: routes.catalogEmpty, icon: 'shopping-bag', message: messages.category },
  { component: MiniCart, message: messages.cart },
  { url: routes.blog, icon: 'book-heart', message: messages.blog },
  { url: routes.dashboard, icon: 'user', message: messages.account },
];

function MainNavigation({ toggleMPLoginVisible, enableCookie }) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const showMiniCart = useSelector(selectShowMiniCart);
  const [value, setValue] = useState(0);

  useEffect(() => {
    const index = navigationLocations.findIndex((l) => history?.location?.pathname === l.url);
    setValue(index === -1 ? 999 : index);

    const accountArray = ['/login', '/login/otp', '/registration', '/account/dashboard'];
    if (accountArray.includes(history?.location?.pathname)) {
      setValue(4);
    }
    if (history?.location?.pathname.includes('catalog')) {
      setValue(1);
    }
    if (showMiniCart) {
      dispatch(toggleMinicart(false));
    }
  }, [history?.location?.pathname]);

  useEffect(() => {
    if (showMiniCart) {
      setValue(999);
    }
  }, [showMiniCart]);

  const handleNavigation = debounce((event, index) => {
    const auth = getAuthorization();
    if (index === 4 && determineEnvironment() && auth.type === 'guest') {
      toggleMPLoginVisible({ visible: true });
    } else {
      history.push(navigationLocations[index].url);
    }
  }, 500);

  return (
    <>
      <Hidden lgUp>
        <AppBar
          position="fixed"
          color="default"
          component="nav"
          className={classes.root}
        >
          <Toolbar className={classes.toolbar}>
            <BottomNavigation
              value={value}
              onChange={handleNavigation}
              showLabels
              className={classes.nav}
            >
              {navigationLocations.map((l, index) => {
                const Component = l.component || BottomNavigationAction;
                return (
                  <Component
                    className={classes.button}
                    style={{ marginTop: 3 }}
                    key={index.toString()}
                    label={<FormattedMessage {...l.message} />}
                    icon={(
                      <FontAwesomeIcon
                        fixedWidth
                        icon={[`${value === index ? 'fas' : 'fal'}`, l.icon]}
                        className={classes.icon}
                      />
                  )}
                  />
                );
              })}
            </BottomNavigation>
          </Toolbar>
        </AppBar>
      </Hidden>
      {enableCookie && <CookieUpdateBottom />}
    </>
  );
}

MainNavigation.propTypes = {
  toggleMPLoginVisible: PropTypes.func,
  enableCookie: PropTypes.bool.isRequired,
};

const mapStateToProps = createStructuredSelector({
  enableCookie: makeCookieConfig(),
});

function mapDispatchToProps(dispatch) {
  return {
    toggleMPLoginVisible: (payload) => dispatch(appActions.toggleMPLoginVisible(payload)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  memo,
)(MainNavigation);

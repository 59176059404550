/**
 *
 * Asynchronously loads the component for CouponPage
 *
 */

import loadable from 'utils/loadable';

export const MyCouponPage = loadable(() => import('./components/MyCouponPage'));

export default loadable(() => import('./index'));

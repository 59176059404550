import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { FormattedMessage } from 'react-intl';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import messages from 'containers/SocialMarketerUpgrade/messages';
import PageLoader from 'components/PageLoader';
import CheckForm from './CheckForm';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'name',
    sortDirection: false,
    numeric: false,
    disablePadding: true,
    label: <FormattedMessage {...messages.name} />,
  },
  {
    id: 'id',
    numeric: true,
    disablePadding: false,
    label: <FormattedMessage {...messages.number} />,
  },
  {
    id: 'mobile',
    sortDirection: false,
    numeric: true,
    disablePadding: false,
    label: <FormattedMessage {...messages.mobile} />,
  },
  {
    id: 'ibe',
    sortDirection: false,
    numeric: true,
    disablePadding: false,
    label: <FormattedMessage {...messages.ibeid} />,
  },
  {
    id: 'llc_status',
    numeric: false,
    disablePadding: false,
    label: <FormattedMessage {...messages.status} />,
  },
  {
    id: 'created_at',
    numeric: false,
    disablePadding: false,
    label: <FormattedMessage {...messages.createdAt} />,
  },
  {
    id: 'protein',
    sortDirection: false,
    numeric: true,
    disablePadding: false,
    label: <FormattedMessage {...messages.operation} />,
  },
];

function EnhancedTableHead(props) {
  const {
    classes, order, orderBy, onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const statusStyles = makeStyles((theme) => ({
  status_0: {
    // approved
    color: theme.palette.text.secondary,
  },
  status_1: {
    // approved
    color: theme.palette.success.main,
  },
  status_2: {
    // declined
    color: theme.palette.warning.main,
  },
  status_3: {
    // expired
    color: theme.palette.warning.main,
  },
  status_4: {
    // pending
    color: theme.palette.primary.pending,
  },
}));

const StatusLabel = ({ status }) => {
  let message;
  const classes = statusStyles();
  const intStatus = parseInt(status, 10);
  switch (intStatus) {
    case 0:
      message = messages.statusPending;
      break;
    case 1:
      message = messages.statusApproved;
      break;
    case 2:
      message = messages.statusDeclined;
      break;
    case 3:
      message = messages.statusExpired;
      break;
    case 4:
      message = messages.statusPending;
      break;
    default:
      message = messages.statusPending;
      break;
  }
  return message ? (
    <Typography className={classes[`status_${status}`]}>
      <FormattedMessage {...message} />
    </Typography>
  ) : (
    undefined
  );
};

StatusLabel.propTypes = {
  status: PropTypes.string,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    cursor: 'pointer',
    borderTop: '2px solid #ddd',
    fontWeight: 'bold',
  },
  tableCell: {
    fontWeight: 'bold',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    padding: 20,
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

function Row(props) {
  const { row, handleLists } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const customAttributes = get(row, 'custom_attributes', []);
  let smStatus = '';
  if (customAttributes.find((a) => a.attribute_code === 'llc_status')) {
    smStatus = customAttributes.find((a) => a.attribute_code === 'llc_status').value;
  }
  let ibeNumber = '';
  if (customAttributes.find((a) => a.attribute_code === 'ibe_number')) {
    ibeNumber = customAttributes.find((a) => a.attribute_code === 'ibe_number').value;
  }
  let mobile = '';
  if (customAttributes.find((a) => a.attribute_code === 'mobile')) {
    mobile = customAttributes.find((a) => a.attribute_code === 'mobile').value;
  }
  return (
    <>
      <TableRow className={classes.root} onClick={() => setOpen(!open)}>
        <TableCell className={classes.tableCell} component="th" scope="row" padding="none">
          {row.firstname + row.lastname}
        </TableCell>
        <TableCell className={classes.tableCell} align="left">
          {row.id}
        </TableCell>
        <TableCell className={classes.tableCell} align="left">
          {mobile}
        </TableCell>
        <TableCell className={classes.tableCell} align="left">
          {ibeNumber}
        </TableCell>
        <TableCell className={classes.tableCell} align="left">
          {' '}
          <StatusLabel status={smStatus} />
        </TableCell>
        <TableCell className={classes.tableCell} align="left">
          {row.created_at}
        </TableCell>
        <TableCell className={classes.tableCell} align="left" />
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={0}>
              <CheckForm
                handleLists={handleLists}
                customAttributes={customAttributes}
                customer={row}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

Row.propTypes = {
  row: PropTypes.object,
  handleLists: PropTypes.func,
};

export default function EnhancedTable({ lists, handleLists }) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  if (!lists.items) {
    return (
      <Paper className={classes.paper} style={{ height: 738, display: 'flex' }}>
        <PageLoader loading />
      </Paper>
    );
  }
  const rows = [];
  for (let i = 0; i < lists.items.length; i += 1) {
    let tmp = lists.items[i];
    const customAttributes = get(tmp, 'custom_attributes', []);
    let smStatus = '';
    if (customAttributes.find((a) => a.attribute_code === 'llc_status')) {
      smStatus = customAttributes.find((a) => a.attribute_code === 'llc_status').value;
    }
    tmp = JSON.parse(JSON.stringify(tmp));

    tmp.llc_status = smStatus;
    rows.push(tmp);
  }
  // const rows = lists.items;
  EnhancedTable.propTypes = {
    lists: PropTypes.object,
    handleLists: PropTypes.func,
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={lists.items.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <Row handleLists={handleLists} key={row.id} row={row} />
                ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={lists.total_count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

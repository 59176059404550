/**
 * Buttons component bit.dev
 *
 * @param {bool} loading loading (trigger disabled and loading progress icon)
 * @param {object|string} messageId button name (pass the string or <FormatedMessage ...>)
 * @param {string} color control the color of the Button
 * @param {...} other pass the necessary event (Link or onSubmit)
 *
 * @return {node} Returns Buttons, all styling managed by Material UI Theme
 */

import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

function Buttons({
  variant, loading, messageId, icon, route, ...others
}) {
  return (
    <Button
      to={route}
      disabled={loading}
      size="large"
      component={route && Link}
      endIcon={icon || (loading && <CircularProgress size={18} color="inherit" />)}
      variant={variant}
      type={variant === 'contained' ? 'submit' : 'button'}
      fullWidth={variant === 'contained'}
      {...others}
    >
      {messageId}
    </Button>
  );
}

Buttons.propTypes = {
  loading: PropTypes.bool,
  variant: PropTypes.string,
  route: PropTypes.string,
  icon: PropTypes.object,
  messageId: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
};

export default Buttons;

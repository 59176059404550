import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useOpenedInWeapp = () => {
  const [inWeappWebview, setInWeappWebview] = useState(false);
  const { search } = useLocation();
  console.log(search);

  useEffect(() => {
    const flag = search.includes('source=weapp');
    setInWeappWebview(flag);
  }, []);

  return inWeappWebview;
};

export default useOpenedInWeapp;

/**
 * Determine if current environment is WeChat MiniProgram
 *
 * @returns {bool}  True if the cross-env type as well as the WeChat environment return miniprogram.
 */

// eslint-disable-next-line arrow-body-style
const determineEnvironment = () => {
  // eslint-disable-next-line no-underscore-dangle
  return navigator.userAgent.match(/miniprogram/i) || window.__wxjs_environment === 'miniprogram';
};

export default determineEnvironment;

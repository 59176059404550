/*
 *
 * Checkout Page constants
 *
 */

// eslint-disable-next-line import/prefer-default-export
export const AVAILABLE_PAYMENT_METHODS = [
  'wechatpay',
];

import { useGetStoreConfig } from 'containers/App/queries';
import { useGetCustomer } from 'containers/UserProvider/queries';

const useGetCustomerGroup = () => {
  const { data: storeConfig } = useGetStoreConfig();
  const { data: customer } = useGetCustomer();

  let group = 'guest';
  if (customer?.group_id && storeConfig?.customer_groups) {
    const groupName = storeConfig?.customer_groups.find((g) => parseInt(g.id, 10) === parseInt(customer?.group_id, 10));
    if (groupName && groupName.code) {
      group = groupName.code.toLowerCase();
    }
  }
  return group;
};

export default useGetCustomerGroup;

/**
 * @api {QUERY} countries Request list of available countries
 * @apiGroup General
 */
export default `
  query storeCountries {
    countries {
      id
      three_letter_abbreviation
      two_letter_abbreviation
      full_name_locale
      full_name_english
      extension_attributes {
          telcode
          top_destination
      }
      available_regions {
          code
          id
          name
      }
    }
  }
`;

/**
 *
 * Asynchronously loads the component for Dashboard
 *
 */

import loadable from 'utils/loadable';

export const PrivacyCenterPage = loadable(() => import('./index'));

export const ConsentForms = loadable(() => import('./components/ConsentForms'));

export const DownloadAccount = loadable(() => import('./components/DownloadAccount'));

export const DeleteAccount = loadable(() => import('./components/DeleteAccount'));

/**
 * @api {QUERY} customer Get customer
 * @apiGroup User
 */
export default `
  {
    customer {
      account_id
      id
      group_id
      created_at
      email
      firstname
      lastname
      middlename
      gender
      dob
      mobile
      mobile_prefix
      default_billing
      default_shipping
      credit_active
      credits_available
      lpv_available
      point_value
      bank_account_name
      bank_account_number
      bank_branch
      bank_name
      bank_reason
      bank_status
      ibe_number
      __typename
      addresses {
            city
            company
            country_id
            custom_attributes {
                attribute_code
                value
            }
            customer_id
            default_billing
            default_shipping
            extension_attributes {
                attribute_code
                value
            }
            fax
            firstname
            id
            lastname
            middlename
            postcode
            prefix
            region {
                region
                region_id
                region_code
            }
            region_id
            street
            suffix
            telephone
            vat_id
        }
    }
  }
`;

/*
 *
 * Cart reducer
 *
 */
import produce from 'immer';
import isEmpty from 'lodash/isEmpty';
import indexOf from 'lodash/indexOf';
import union from 'lodash/union';
import without from 'lodash/without';
import { constants, RESET_CART } from 'containers/CartProvider/actions';

const toggle = (a, b) => (indexOf(a, b) === -1 ? union(a, [b]) : without(a, b));

export const initialState = {
  cartQuoteId: '',
  quote: { items: [] },
  selectedItems: [],
  editingItems: {},
  lock: 0,
  savingItems: [],
};

/* eslint-disable default-case, no-param-reassign, consistent-return */
const cartReducer = (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case RESET_CART:
      return initialState;

    case constants.SET_CART_QUOTE:
      draft.quote = action.quote;
      draft.cartQuoteId = action.quote.id;
      // if already in cart didn't update qty;
      if (action.quote && !isEmpty(action.quote)) {
        draft.editingItems = {};
        // If the selectedItem does not exist in the shopping cart, remove it
        draft.selectedItems.forEach((i) => {
          const index = action.quote.items.findIndex((item) => i === item.item_id);
          if (index === -1) {
            draft.selectedItems.splice(index, 1);
          }
        });
      }

      break;

    case constants.SET_CART_UPDATE:
      draft.quote.items[draft.quote.items.findIndex((i) => i.item_id === action.cartItem.item_id)] = action.cartItem;
      draft.quote.items_qty = draft.quote.items.reduce((acc, curr) => acc + curr.qty, 0);
      break;

    case constants.SET_CART_REMOVAL:
      draft.quote.items.splice(draft.quote.items.findIndex((i) => i.item_id === action.itemId), 1);
      draft.selectedItems.splice(draft.selectedItems.findIndex((i) => i === action.itemId), 1);
      draft.quote.items_qty = draft.quote.items.reduce((acc, curr) => acc + curr.qty, 0);
      delete draft.editingItems[action.itemId];
      break;

    case constants.TOGGLE_CART_ITEM:
      draft.selectedItems = toggle(draft.selectedItems, action.itemId);
      break;

    case constants.TOGGLE_ALL_CART_ITEMS:
      draft.selectedItems = [];
      if (action.checked === true) {
        draft.quote.items.forEach((i) => {
          draft.selectedItems.push(i.item_id);
        });
      }
      break;

    case constants.CLEAR_SELECTED_CART_ITEMS:
      draft.selectedItems = [];
      break;

    case constants.EDIT_ALL:
      draft.quote.items.forEach((i) => {
        draft.editingItems[i.item_id] = i;
      });
      break;

    case constants.TOOGLE_LOCK_CHECKOUT:
      if (action.lock) {
        draft.lock += 1;
      } else {
        draft.lock -= 1;
      }
      break;

    case constants.TOOGLE_LOCK_1000:
      draft.lock1000 = action.lock1000;
      break;

    case constants.CLEAR_ALL:
      draft.editingItems = {};
      break;

    case constants.EDIT_SINGLE:
      // eslint-disable-next-line no-case-declarations
      const { item, quantity } = action.payload;
      draft.editingItems[item.item_id] = {
        ...item,
        ...(quantity ? { qty: quantity } : {}),
      };
      break;

    case constants.CLEAR_FROM_EDIT:
      delete draft.editingItems[action.itemId];
      break;

    case constants.SAVING_SINGLE:
      draft.savingItems.push(action.itemId);
      break;

    case constants.FINISH_SINGLE_SAVE:
      draft.savingItems.splice(draft.savingItems.findIndex((i) => i === action.itemId), 1);
      break;

    default:
      break;
  }
});

export default cartReducer;

/**
 *
 * PageLoader
 *
 */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import loader from 'images/loader.gif';
import messages from 'containers/App/messages';

function PageLoader({ loading, title }) {
  if (!loading) return <></>;
  return (
    <Box m="auto">
      <img src={loader} alt="loader" style={{ width: 60 }} />
      <Typography color="primary">{title || (<FormattedMessage {...messages.loading} />)}</Typography>
    </Box>
  );
}

PageLoader.defaultProps = {
  loading: false,
};

PageLoader.propTypes = {
  loading: PropTypes.bool,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
};

export default memo(PageLoader);

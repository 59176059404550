import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

const useLocalStyles = makeStyles((theme) => ({
  panelRoot: {
    borderTop: '2px solid #cfcfcf',
    boxShadow: 'none',
    background: theme.palette.primary.white,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:last-child': {
      borderBottom: '3px solid #cfcfcf',
    },
    '&:before': {
      display: 'none',
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: '500',
    color: theme.palette.text.primary,
  },
  panelSummaryRoot: {
    backgroundColor: theme.palette.primary.white,
    border: 'none',
    marginBottom: -1,
    minHeight: 56,
    paddingLeft: '0px',
    paddingRight: '0px',
    '&:last-child': {
      borderBottom: '2px solid #cfcfcf',
    },
  },
  panelSummaryExpandIcon: {
    fontSize: '16px',
  },
  panelDetailsRoot: {
    padding: '0px',
  },
}));

const Faq = ({ item }) => {
  const [expanded, setExpanded] = React.useState({});
  const localClasses = useLocalStyles();
  const icon = expanded ? ['far', 'plus'] : ['far', 'minus'];
  return (
    <Accordion
      onChange={() => setExpanded(!expanded)}
      classes={{
        root: localClasses.panelRoot,
      }}
    >
      <AccordionSummary
        classes={{
          root: localClasses.panelSummaryRoot,
          expandIcon: localClasses.panelSummaryExpandIcon,
          content: localClasses.panelSummaryContent,
        }}
        expandIcon={<FontAwesomeIcon icon={icon} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>{item.questions}</Typography>
      </AccordionSummary>
      <AccordionDetails classes={{ root: localClasses.panelDetailsRoot }}>
        <Typography
          dangerouslySetInnerHTML={{ __html: item.answers }}
        />
      </AccordionDetails>
    </Accordion>
  );
};

Faq.propTypes = {
  item: PropTypes.shape({
    questions: PropTypes.string,
    answers: PropTypes.string,
  }),
};

export default Faq;

import { useQuery, useMutation } from 'react-query';
import request from 'utils/request';
import { querySetting } from 'utils/requestGql';

export const listCustomer = (payload) => request(
  'GET',
  `/customers/search?searchCriteria[sortOrders][0][field]=created_at&searchCriteria[sortOrders][0][direction]=desc&searchCriteria[pageSize]=1000&searchCriteria[currentPage]=${
    payload.page
  }&${payload.filter}`,
);
export const updateCustomer = (payload) => request('PUT', `/customers/${payload.id}`, { customer: payload.data });

export const useGetRefundList = (payload) => useQuery(['smCustomer.list'],
  () => listCustomer(payload), querySetting);

export const useUpdateSmCustomer = () => useMutation((payload) => updateCustomer(payload));

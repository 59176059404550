/**
 *
 * Helper
 *
 */
import React from 'react';

import isEmpty from 'lodash/isEmpty';
import { FormattedMessage } from 'react-intl';
import { useGetListFaq } from 'containers/Helper/queries';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Picture from 'components/Picture';
import supportBanner from 'images/aboutus.jpg';
import messages from './messages';
import Faq from './Faq';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    background: theme.palette.primary.white,
    minHeight: '900px',
  },
  h3: {
    margin: '42px 0 24px',
  },
  banner: {
    color: theme.palette.primary.white,
    height: 340,
    display: 'flex',
    marginTop: 88,
  },
  backgroundBanner: {
    width: '100%',
    position: 'absolute',
    height: 340,
  },
}));

export function Helper() {
  const { isLoading, error, data } = useGetListFaq();

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.banner}>
        <Picture
          src={`${process.env.BASE_URL_APP}${supportBanner}`}
          title=""
          alt=""
          className={classes.backgroundBanner}
          op="fit"
          params={{
            width: 1280,
            height: 848,
          }}
        />
        <Box zIndex={1} m="auto">
          <Typography variant="h1" component="h1">
            <FormattedMessage {...messages.helpCenter} />
          </Typography>
          <Typography variant="subtitle1" component="p">
            <FormattedMessage {...messages.subHelpCenter} />
          </Typography>
        </Box>
      </div>
      <Box pb="100px" width={900} mx="auto">
        <Typography variant="h3" component="h3" className={classes.h3}>
          <FormattedMessage {...messages.customerFAQ} />
        </Typography>
        {!isLoading && !error && !isEmpty(data) && data.map((item) => <Faq item={item} key={item.questions} />)}
      </Box>
    </div>
  );
}

export default Helper;

// Import Light Icons
import {
  faBookHeart as falBookHeart,
  faPersonDollyEmpty as falPersonDollyEmpty,
  faSyncAlt as falSyncAlt,
  faCopy as falCopy,
  faBook as falBook,
  faTrashAlt as falTrashAlt,
  faAngleRight as falAngleRight,
  faExclamationSquare as falExclamationSquare,
  faArchive as falArchive,
  faBookmark as falBookmark,
  faArrowCircleRight as falArrowCircleRight,
  faArrowToLeft as falArrowToLeft,
  faArrowToRight as falArrowToRight,
  faAward as falAward,
  faSmile as falSmile,
  faBalanceScale as falBalanceScale,
  faBan as falBan,
  faBell as falBell,
  faMailbox as falMailbox,
  faBoxAlt as falBoxAlt,
  faBoxFull as falBoxFull,
  faBuilding as falBuilding,
  faCalendar as falCalendar,
  faCalendarDay as falCalendarDay,
  faCalendarStar as falCalendarStar,
  faCamera as falCamera,
  faCartPlus as falCartPlus,
  faCheck as falCheck,
  faCheckCircle as falCheckCircle,
  faChevronDown as falChevronDown,
  faChevronLeft as falChevronLeft,
  faChevronRight as falChevronRight,
  faFire as falFire,
  faAngleLeft as falAngleLeft,
  faChevronUp as falChevronUp,
  faCircle as falCircle,
  faClipboardCheck as falClipboardCheck,
  faCog as falCog,
  faCreditCard as falCreditCard,
  faDollarSign as falDollarSign,
  faEdit as falEdit,
  faEllipsisHAlt as falEllipsisHAlt,
  faEnvelope as falEnvelope,
  faExclamationCircle as falExclamationCircle,
  faExclamationTriangle as falExclamationTriangle,
  faEye as falEye,
  faEyeSlash as falEyeSlash,
  faFileCertificate as falFileCertificate,
  faFileSpreadsheet as falFileSpreadsheet,
  faFilter as falFilter,
  faGlobe as falGlobe,
  faHome as falHome,
  faHourglassHalf as falHourglassHalf,
  faIdBadge as falIdBadge,
  faIdCard as falIdCard,
  faImage as falImage,
  faKey as falKey,
  faLocationArrow as falLocationArrow,
  faLock as falLock,
  faMale as falMale,
  faMap as falMap,
  faMapMarkedAlt as falMapMarkedAlt,
  faMapMarker as falMapMarker,
  faMapMarkerAlt as falMapMarkerAlt,
  faMapMarkerCheck as falMapMarkerCheck,
  faMobile as falMobile,
  faPenAlt as falPenAlt,
  faPencilAlt as falPencilAlt,
  faPenSquare as falPenSquare,
  faPhone as falPhone,
  faUserHeadset as falUserHeadset,
  faPiggyBank as falPiggyBank,
  faPlus as falPlus,
  faPlusCircle as falPlusCircle,
  faStopwatch as falStopwatch,
  faQuestion as falQuestion,
  faQuestionCircle as falQuestionCircle,
  faSearch as falSearch,
  faUserEdit as falUserEdit,
  faEllipsisV as falEllipsisV,
  faShareAlt as falShareAlt,
  faShield as falShield,
  faShieldCheck as falShieldCheck,
  faShoppingBag as falShoppingBag,
  faShoppingBasket as falShoppingBasket,
  faShoppingCart as falShoppingCart,
  faSignature as falSignature,
  faSignIn as falSignIn,
  faSignOut as falSignOut,
  faSlidersH as falSlidersH,
  faStar as falStar,
  faSquareFull as falSquareFull,
  faTag as falTag,
  faThLarge as falThLarge,
  faThumbsUp as falThumbsUp,
  faQrcode as falQrcode,
  faDownload as falDownload,
  faTimes as falTimes,
  faTrash as falTrash,
  faTruck as falTruck,
  faStamp as falStamp,
  faUser as falUser,
  faUserCircle as falUserCircle,
  faUsers as falUsers,
  faUserTag as falUserTag,
  faWallet as falWallet,
  faBoxOpen as falBoxOpen,
} from '@fortawesome/pro-light-svg-icons';

// Import Regular Icons
import {
  faArrowDown as farArrowDown,
  faBan as farBan,
  faExclamationCircle as farExclamationCircle,
  faAngleDown as farAngleDown,
  faAngleUp as farAngleUp,
  faFile as farFile,
  faUsers as farUsers,
  faEdit as farEdit,
  faShoppingCart as farShoppingCart,
  faBookOpen as farBookOpen,
  faArchive as farArchive,
  faMapMarkedAlt as farMapMarkedAlt,
  faBoxAlt as farBoxAlt,
  faAngleLeft as farAngleLeft,
  faUsersCrown as farUsersCrown,
  faMeat as farMeat,
  faBoxFull as farBoxFull,
  faCalendarDay as farCalendarDay,
  faCheck as farCheck,
  faExternalLink as farExternalLink,
  faTicketAlt as farTicketAlt,
  faChevronDown as farChevronDown,
  faChevronLeft as farChevronLeft,
  faChevronRight as farChevronRight,
  faChevronUp as farChevronUp,
  faCircle as farCircle,
  faFileAlt as farFileAlt,
  faMedal as farMedal,
  faPercentage as farPercentage,
  faInfoCircle as farInfoCircle,
  faMap as farMap,
  faMinus as farMinus,
  faMobile as farMobile,
  faPlus as farPlus,
  faQuestion as farQuestion,
  faSearch as farSearch,
  faTimes as farTimes,
  faTruck as farTruck,
  faUnlock as farUnlock,
  faUser as farUser,
  faWallet as farWallet,
  faClock as farClock,
  faUserShield as farUserShield,
} from '@fortawesome/pro-regular-svg-icons';

// Import Solid Icons
import {
  faArchive as fasArchive,
  faArrowFromRight as fasArrowFromRight,
  faAward as fasAward,
  faBan as fasBan,
  faTicket as fasTicket,
  faBell as fasBell,
  faMedal as fasMedal,
  faBoxAlt as fasBoxAlt,
  faTicketAlt as fasTicketAlt,
  faCalendarDay as fasCalendarDay,
  faCaretDown as fasCaretDown,
  faCheck as fasCheck,
  faCheckCircle as fasCheckCircle,
  faLink as fasLink,
  faCheckSquare as fasCheckSquare,
  faChevronDown as fasChevronDown,
  faChevronLeft as fasChevronLeft,
  faChevronRight as fasChevronRight,
  faFire as fasFire,
  faShoppingCart as fasShoppingCart,
  faShoppingBag as fasShoppingBag,
  faCartPlus as fasCartPlus,
  faChevronUp as fasChevronUp,
  faUsersCrown as fasUsersCrown,
  faMeat as fasMeat,
  faBookOpen as fasBookOpen,
  faCircle as fasCircle,
  faAngleRight as fasAngleRight,
  faClock as fasClock,
  faHome as fasHome,
  faFile as fasFile,
  faUsers as fasUsers,
  faClone as fasClone,
  faCaretUp as fasCaretUp,
  faCloudDownload as fasCloudDownload,
  faUserHeadset as fasUserHeadset,
  faBookHeart as fasBookHeart,
  faCreditCard as fasCreditCard,
  faEnvelope as fasEnvelope,
  faExclamationCircle as fasExclamationCircle,
  faExclamationTriangle as fasExclamationTriangle,
  faFileAlt as fasFileAlt,
  faBalanceScale as fasBalanceScale,
  faImage as fasImage,
  faLockAlt as fasLockAlt,
  faMapMarkedAlt as fasMapMarkedAlt,
  faMapMarkerAlt as fasMapMarkerAlt,
  faRss as fasRss,
  faStar as fasStar,
  faUserTag as fasUserTag,
  faPiggyBank as fasPiggyBank,
  faSquareFull as fasSquareFull,
  faThLarge as fasThLarge,
  faThumbsUp as fasThumbsUp,
  faTimesCircle as fasTimesCircle,
  faTrash as fasTrash,
  faTruck as fasTruck,
  faUser as fasUser,
  faWallet as fasWallet,
  faUserShield as fasUserShield,
} from '@fortawesome/pro-solid-svg-icons';

// Import Brand Icons
import {
  faFacebookF as fabFacebookF,
  faInstagram as fabInstagram,
  faLinkedinIn as fabLinkedinIn,
  faPinterest as fabPinterest,
  faTwitter as fabTwitter,
  faYoutube as fabYoutube,
  faWeixin as fabWeixin,
} from '@fortawesome/free-brands-svg-icons';

// Combine Light Icons
const fal = [
  falPersonDollyEmpty,
  falAngleRight,
  falSyncAlt,
  falTrashAlt,
  falBookHeart,
  falBookmark,
  falExclamationSquare,
  falArchive,
  falArrowCircleRight,
  falQrcode,
  falDownload,
  falArrowToLeft,
  falArrowToRight,
  falAward,
  falSmile,
  falBalanceScale,
  falBan,
  falBell,
  falBoxAlt,
  falBoxFull,
  falMailbox,
  falBuilding,
  falCalendar,
  falCalendarDay,
  falCalendarStar,
  falCamera,
  falCartPlus,
  falCheck,
  falCheckCircle,
  falChevronDown,
  falChevronLeft,
  falChevronRight,
  falFire,
  falChevronUp,
  falCircle,
  falClipboardCheck,
  falCog,
  falCopy,
  falBook,
  falCreditCard,
  falDollarSign,
  falEdit,
  falEllipsisHAlt,
  falEnvelope,
  falExclamationCircle,
  falExclamationTriangle,
  falEye,
  falEyeSlash,
  falFileCertificate,
  falFileSpreadsheet,
  falFilter,
  falGlobe,
  falHome,
  falHourglassHalf,
  falIdCard,
  falIdBadge,
  falImage,
  falLocationArrow,
  falKey,
  falLock,
  falAngleLeft,
  falMale,
  falMap,
  falMapMarkedAlt,
  falMapMarker,
  falMapMarkerAlt,
  falMapMarkerCheck,
  falMobile,
  falPenAlt,
  falPencilAlt,
  falPenSquare,
  falPlus,
  falUserHeadset,
  falStopwatch,
  falPlusCircle,
  falPhone,
  falPiggyBank,
  falQuestion,
  falQuestionCircle,
  falSearch,
  falShareAlt,
  falUserEdit,
  falEllipsisV,
  falShield,
  falShieldCheck,
  falShoppingBag,
  falShoppingBasket,
  falShoppingCart,
  falSignature,
  falSignIn,
  falSignOut,
  falSlidersH,
  falStar,
  falSquareFull,
  falTag,
  falThLarge,
  falThumbsUp,
  falTimes,
  falTrash,
  falTruck,
  falStamp,
  falUser,
  falUserCircle,
  falUsers,
  falUserTag,
  falWallet,
  falBoxOpen,
];

// Combine Regular Icons
const far = [
  farArrowDown,
  farBan,
  fasAward,
  farBoxAlt,
  farAngleLeft,
  farUsersCrown,
  farMeat,
  farEdit,
  farBoxFull,
  farExternalLink,
  farCalendarDay,
  farTicketAlt,
  farCheck,
  farChevronDown,
  farChevronLeft,
  farChevronRight,
  farChevronUp,
  farPercentage,
  farCircle,
  farMedal,
  farAngleDown,
  farFileAlt,
  farExclamationCircle,
  farAngleUp,
  farFile,
  farUsers,
  farInfoCircle,
  farShoppingCart,
  farBookOpen,
  farArchive,
  farMapMarkedAlt,
  farMap,
  farMinus,
  farMobile,
  farPlus,
  farQuestion,
  farSearch,
  farTimes,
  farTruck,
  farUnlock,
  farUser,
  farWallet,
  farClock,
  farUserShield,
];

// Combine Solid Icons
const fas = [
  fasArchive,
  fasArrowFromRight,
  fasAward,
  fasMedal,
  fasBan,
  fasBell,
  fasBoxAlt,
  fasCalendarDay,
  fasTicketAlt,
  fasCaretDown,
  fasCheck,
  fasLink,
  fasCheckCircle,
  fasCheckSquare,
  fasChevronDown,
  fasChevronLeft,
  fasChevronRight,
  fasShoppingBag,
  fasFire,
  fasCartPlus,
  fasShoppingCart,
  fasChevronUp,
  fasCircle,
  fasUsersCrown,
  fasMeat,
  fasBookOpen,
  fasAngleRight,
  fasTicket,
  fasClock,
  fasHome,
  fasFile,
  fasUsers,
  fasClone,
  fasCaretUp,
  fasBookHeart,
  fasCloudDownload,
  fasCreditCard,
  fasEnvelope,
  fasExclamationCircle,
  fasUserHeadset,
  fasExclamationTriangle,
  fasFileAlt,
  fasBalanceScale,
  fasImage,
  fasLockAlt,
  fasMapMarkedAlt,
  fasMapMarkerAlt,
  fasRss,
  fasStar,
  fasSquareFull,
  fasUserTag,
  fasPiggyBank,
  fasThLarge,
  fasThumbsUp,
  fasTimesCircle,
  fasTrash,
  fasTruck,
  fasUser,
  fasWallet,
  fasUserShield,
];

// Combine Brand Icons
const fab = [
  fabFacebookF,
  fabInstagram,
  fabLinkedinIn,
  fabPinterest,
  fabTwitter,
  fabYoutube,
  fabWeixin,
];

// Export for Font Awesome library
// eslint-disable-next-line import/prefer-default-export
export const ICONS = fal.concat(far, fas, fab);

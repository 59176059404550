/*
 * Cart Messages
 *
 * This contains all the text for the Cart container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Cart';

export default defineMessages({
  confirmRemovalOfItem: {
    id: `${scope}.confirmRemovalOfItem`,
    defaultMessage: 'Confirm removal of item',
  },
  deleteItem: {
    id: `${scope}.deleteItem`,
    defaultMessage: 'Delete Item',
  },
  processing: {
    id: `${scope}.processing`,
    defaultMessage: 'Processing',
  },
  destructiveAction: {
    id: `${scope}.destructiveAction`,
    defaultMessage: 'Permanently delete this item? This action cannot be undone.',
  },
  cart: {
    id: `${scope}.cart`,
    defaultMessage: 'Cart',
  },
  pleaseWaiting: {
    id: `${scope}.pleaseWaiting`,
    defaultMessage: 'Please wait a moment',
  },
  myCart: {
    id: `${scope}.myCart`,
    defaultMessage: 'My Cart',
  },
  cartPage: {
    id: `${scope}.cartPage`,
    defaultMessage: 'Cart Page',
  },
  loadingCart: {
    id: `${scope}.loadingCart`,
    defaultMessage: 'Loading your cart',
  },
  totalColon: {
    id: `${scope}.totalColon`,
    defaultMessage: 'Total',
  },
  productHasBeenAddedToCart: {
    id: `${scope}.productHasBeenAddedToCart`,
    defaultMessage: '{name} has been added to your cart.',
  },
  checkOut: {
    id: `${scope}.checkOut`,
    defaultMessage: 'Check Out{quantity, plural, =0 {} other { ({quantity})}}',
  },
  cartIsEmpty: {
    id: `${scope}.cartIsEmpty`,
    defaultMessage: 'Your Cart is Empty',
  },
  youHaveWishListItems: {
    id: `${scope}.youHaveWishListItems`,
    defaultMessage:
      'You have {quantity, plural, =0 {nothing} one {one item} other {{quantity} items}} in your wish list',
  },
  selectItemsToAdd: {
    id: `${scope}.selectItemsToAdd`,
    defaultMessage: 'Select items to add to your cart',
  },
  addSelectedItemsToCart: {
    id: `${scope}.addSelectedItemsToCart`,
    defaultMessage: 'Add selected items to cart',
  },
  addFromFavourites: {
    id: `${scope}.addFromFavourites`,
    defaultMessage: 'Add From Favourites{quantity, plural, =0 {} other { ({quantity})}}',
  },
  estimation: {
    id: `${scope}.estimation`,
    defaultMessage: 'Estimation',
  },
  continueShopping: {
    id: `${scope}.continueShopping`,
    defaultMessage: 'Continue Shopping',
  },
  editCart: {
    id: `${scope}.editCart`,
    defaultMessage: 'View And Edit Cart',
  },
  youHaveCartItems: {
    id: `${scope}.youHaveCartItems`,
    defaultMessage:
      'You have {quantity, plural, =0 {nothing} one {one item} other {{quantity} items}} in your cart',
  },
  productName: {
    id: `${scope}.productName`,
    defaultMessage: 'Product Name',
  },
  price: {
    id: `${scope}.price`,
    defaultMessage: 'Price',
  },
  quantity: {
    id: `${scope}.quantity`,
    defaultMessage: 'Quantity',
  },
  addToCart: {
    id: `${scope}.addToCart`,
    defaultMessage: 'Add to Cart',
  },
  addingToCart: {
    id: `${scope}.addingToCart`,
    defaultMessage: 'Adding...',
  },
  onSale: {
    id: `${scope}.onSale`,
    defaultMessage: 'On Sale',
  },
  timeLeft: {
    id: `${scope}.timeLeft`,
    defaultMessage: 'Time left: ',
  },
});

exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("./SourceHanSansCN-Medium.woff2"));
var ___CSS_LOADER_URL___1___ = urlEscape(require("./SourceHanSansCN-Medium.woff"));
var ___CSS_LOADER_URL___2___ = urlEscape(require("./ProximaNova-Regular.woff2"));
var ___CSS_LOADER_URL___3___ = urlEscape(require("./ProximaNova-Regular.woff"));

// Module
exports.push([module.id, "/* SourceHanSansCN-500normal */\n@font-face {\n  font-family: 'SourceHanSansSC';\n  font-style: normal;\n  font-display: swap;\n  font-weight: 500;\n  src:\n    url(" + ___CSS_LOADER_URL___0___ + ") format('woff2'), \n    url(" + ___CSS_LOADER_URL___1___ + ") format('woff'); /* Modern Browsers */\n}\n\n/* Proxima Nova - regular */\n@font-face {\n  font-family: 'Proxima Nova';\n  font-style: normal;\n  font-display: swap;\n  font-weight: 400;\n  src:\n    url(" + ___CSS_LOADER_URL___2___ + ") format('woff2'), \n    url(" + ___CSS_LOADER_URL___3___ + ") format('woff'); /* Modern Browsers */\n}", ""]);


/**
 * QuantityAdjuster component bit.dev
 *
 * @param {number} maximum max value for input field(99 default)
 * @param {number|string} initialValue initial value
 * @param {func} onChange function on change new value
 * @param {bool} disabled disable the button
 * @param {bool} allowZero allow zero value(0 execute delete modal)
 * @param {bool} small small component
 * @param {bool} useSetValue use value
 * @param {bool} big big component
 * @param {...} other pass the necessary props
 *
 * @return {node} Returns QuantityAdjuster, all styling managed by Material UI Theme
 */

import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Buttons from 'components/Buttons/Buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  quantityAdjuster: {
    display: 'inline-flex',
    maxWidth: 160,
    width: 160,
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 0,
    borderRadius: 2,
    boxShadow: 'none',
    backgroundColor: theme.palette.background.default,
  },
  iconButton: {
    display: 'inline-flex',
    height: 36,
    width: 25,
    minWidth: 'auto',
    color: theme.palette.text.secondary,
  },
}));

function QuantityAdjuster({
  maximum, initialValue, onChange, disabled, allowZero, small, useSetValue, big, ...other
}) {
  const classes = useStyles();

  const [value, setValue] = useState(initialValue);

  const minimum = allowZero ? 0 : 1;

  const increment = () => {
    let newValue = parseInt(value + 1, 10);
    newValue = newValue > maximum ? maximum : newValue;
    if (newValue !== value) {
      onChange(newValue);
      setValue(newValue);
    }
  };

  const decrement = () => {
    const newValue = value - 1;
    onChange(newValue);
    if (newValue !== 0) {
      setValue(newValue);
    }
  };

  return (
    <Box
      className={classes.quantityAdjuster}
      style={{ maxWidth: `${small && '90px'}`, width: `${small && '90px'}` }}
      // eslint-disable-next-line no-nested-ternary
      height={big ? 48 : small ? 32 : 36}
      {...other}
    >
      <Buttons
        onClick={decrement}
        messageId={<FontAwesomeIcon size="sm" icon={['far', 'minus']} />}
        disabled={disabled || value === minimum}
        className={classes.iconButton}
      />
      <Typography variant="subtitle2">
        {useSetValue ? value : initialValue}
      </Typography>
      <Buttons
        onClick={increment}
        messageId={<FontAwesomeIcon size="sm" icon={['far', 'plus']} />}
        disabled={disabled || value === maximum}
        className={classes.iconButton}
      />
    </Box>
  );
}

QuantityAdjuster.defaultProps = {
  maximum: 99,
  initialValue: 1,
  disabled: false,
  allowZero: false,
  small: false,
};

QuantityAdjuster.propTypes = {
  maximum: PropTypes.number,
  initialValue: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  allowZero: PropTypes.bool,
  small: PropTypes.bool,
  useSetValue: PropTypes.bool,
  big: PropTypes.bool,
};

export default memo(QuantityAdjuster);

/**
 *
 * Asynchronously loads the component for CmsPage
 *
 */

import loadable from 'utils/loadable';

export const About = loadable(() => import('./About'));

export default loadable(() => import('./index'));

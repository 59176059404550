/**
 *
 * CheckBoxInput
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckboxIcons from './CheckboxIcons';

function CheckBoxInput({
  circleCheckBox, disabled, label, name, ...others
}) {
  return (
    <FormControlLabel
      control={(
        <Checkbox
          icon={(circleCheckBox ? <CheckboxIcons type="uncheckedCircle" /> : <CheckboxIcons type="unchecked" />)}
          color="secondary"
          style={{ transform: `${circleCheckBox && 'scale(0.8)'}` }}
          checkedIcon={(circleCheckBox ? <CheckboxIcons type="checkedCircle" /> : <CheckboxIcons type="checked" />)}
          {...others}
        />
  )}
      label={label}
      name={name && name.toString()}
      disabled={disabled}
    />
  );
}

CheckBoxInput.propTypes = {
  circleCheckBox: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  name: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

export default CheckBoxInput;

/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import history from 'utils/history';
import globalReducer from 'containers/App/reducer';
import appReducer from 'containers/App/slices';
import notificationReducer from 'utils/Notifications/reducer';
import authorizationReducer from 'containers/AuthorizationProvider/reducer';
import cartReducer from 'containers/CartProvider/reducer';
import checkoutReducer from 'containers/CheckoutPage/reducer';
/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    global: globalReducer,
    app: appReducer,
    router: connectRouter(history),
    notifications: notificationReducer,
    authorization: authorizationReducer,
    cart: cartReducer,
    checkout: checkoutReducer,
    ...injectedReducers,
  });

  return rootReducer;
}

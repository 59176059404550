/*
 * Generic App Messages
 *
 * This contains all generic messages that could be used in other containers or components
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.generic';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Helper',
  },
  loading: {
    id: `${scope}.loading`,
    defaultMessage: 'Loading...',
  },
  expired: {
    id: `${scope}.expired`,
    defaultMessage: 'Expired',
  },
  uploadPhoto: {
    id: `${scope}.uploadPhoto`,
    defaultMessage: '* Upload Photo',
  },
  uploadTakePhoto: {
    id: `${scope}.uploadTakePhoto`,
    defaultMessage: 'Upload / Take Photo',
  },
  attachmentRestrictions: {
    id: `${scope}.attachmentRestrictions`,
    defaultMessage:
      'The maximum allowed file size is {size} per attachment. The accepted file formats are {formats}.',
  },
  cart: {
    id: `${scope}.cart`,
    defaultMessage: 'Cart',
  },
  checkout: {
    id: `${scope}.checkout`,
    defaultMessage: 'Checkout',
  },
  complete: {
    id: `${scope}.complete`,
    defaultMessage: 'Complete',
  },
  imageLimitRestriction: {
    id: `${scope}.imageLimitRestriction`,
    defaultMessage: 'You can only add up to {limit} images',
  },
  invalidFileFormat: {
    id: `${scope}.invalidFileFormat`,
    defaultMessage: 'File format is not allowed, please check your selection.',
  },
  imageSizeRestriction: {
    id: `${scope}.imageSizeRestriction`,
    defaultMessage: 'Selected image(s) are too big, please use smaller images.',
  },
  open: {
    id: `${scope}.open`,
    defaultMessage: 'Open',
  },
  subtotalInclTax: {
    id: `${scope}.subtotalInclTax`,
    defaultMessage: 'Subtotal(including tax)',
  },
  taxA: {
    id: `${scope}.taxA`,
    defaultMessage: 'Tax A',
  },
  taxB: {
    id: `${scope}.taxB`,
    defaultMessage: 'Tax B',
  },
  taxC: {
    id: `${scope}.taxC`,
    defaultMessage: 'Tax C',
  },
  taxWithName: {
    id: `${scope}.taxWithName`,
    defaultMessage: '{name}',
  },
  discountAmount: {
    id: `${scope}.discountAmount`,
    defaultMessage: 'Discount Amount',
  },
  grandTotal: {
    id: `${scope}.grandTotal`,
    defaultMessage: 'Grand Total',
  },
  shippingAmount: {
    id: `${scope}.shippingAmount`,
    defaultMessage: 'Shipping Fee',
  },
  fillOutTheForm: {
    id: `${scope}.fillOutTheForm`,
    defaultMessage: 'Fill out the error report',
  },
  pageNotLoadedNormally: {
    id: `${scope}.pageNotLoadedNormally`,
    defaultMessage: 'This page is not loading Properly',
  },
  pageNotLoadedNormallyNote: {
    id: `${scope}.pageNotLoadedNormallyNote`,
    defaultMessage:
      'Our staff has received the error report and will resume using this page later. If you able to provide more detailed report to us, please click the button below to input and submit. We are very sorry, thank you for your cooperation',
  },
  resetAndLogin: {
    id: `${scope}.resetAndLogin`,
    defaultMessage: 'Reset And Login',
  },
  noArticles: {
    id: `${scope}.noArticles`,
    defaultMessage: 'No articles',
  },
  hotArticle: {
    id: `${scope}.hotArticle`,
    defaultMessage: 'Hot',
  },
  viewAnalytic: {
    id: `${scope}.viewAnalytic`,
    defaultMessage: 'View Analytic',
  },
  goEditAddress: {
    id: `${scope}.goEditAddress`,
    defaultMessage: 'View Analytic',
  },
  viewAllOrder: {
    id: `${scope}.viewAllOrder`,
    defaultMessage: 'View Analytic',
  },
  viewInfo: {
    id: `${scope}.viewInfo`,
    defaultMessage: 'View Analytic',
  },
  applyForSM: {
    id: `${scope}.applyForSM`,
    defaultMessage: 'Open',
  },
  wechatPay: {
    id: `${scope}.wechatPay`,
    defaultMessage: 'Wechat Pay',
  },
  aliPay: {
    id: `${scope}.aliPay`,
    defaultMessage: 'AliPay',
  },
  applePay: {
    id: `${scope}.applePay`,
    defaultMessage: 'ApplePay',
  },

  close: {
    id: `${scope}.close`,
    defaultMessage: 'Close',
  },
  back: {
    id: `${scope}.back`,
    defaultMessage: 'Back',
  },
  goBack: {
    id: `${scope}.goBack`,
    defaultMessage: 'Go Back',
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: 'Cancel',
  },
  apply: {
    id: `${scope}.apply`,
    defaultMessage: 'Apply',
  },
  applying: {
    id: `${scope}.applying`,
    defaultMessage: 'Applying',
  },
  confirm: {
    id: `${scope}.confirm`,
    defaultMessage: 'Confirm',
  },
  submit: {
    id: `${scope}.submit`,
    defaultMessage: 'Submit',
  },
  submitting: {
    id: `${scope}.submitting`,
    defaultMessage: 'Submitting',
  },
  save: {
    id: `${scope}.save`,
    defaultMessage: 'Save',
  },
  saving: {
    id: `${scope}.saving`,
    defaultMessage: 'Saving',
  },
  saveAll: {
    id: `${scope}.saveAll`,
    defaultMessage: 'Save All',
  },
  update: {
    id: `${scope}.update`,
    defaultMessage: 'Update',
  },
  updating: {
    id: `${scope}.updating`,
    defaultMessage: 'Updating',
  },
  edit: {
    id: `${scope}.edit`,
    defaultMessage: 'Edit',
  },
  editAll: {
    id: `${scope}.editAll`,
    defaultMessage: 'Edit All',
  },
  change: {
    id: `${scope}.change`,
    defaultMessage: 'Change',
  },
  add: {
    id: `${scope}.add`,
    defaultMessage: 'Add',
  },
  addSelected: {
    id: `${scope}.addSelected`,
    defaultMessage: 'Add Selected',
  },
  addItems: {
    id: `${scope}.addItems`,
    defaultMessage: 'Add Items',
  },
  adding: {
    id: `${scope}.adding`,
    defaultMessage: 'Adding',
  },
  move: {
    id: `${scope}.move`,
    defaultMessage: 'Move',
  },
  moveSelected: {
    id: `${scope}.moveSelected`,
    defaultMessage: 'Move Selected',
  },
  moveItems: {
    id: `${scope}.moveItems`,
    defaultMessage: 'Move Items',
  },
  moving: {
    id: `${scope}.moving`,
    defaultMessage: 'Moving',
  },
  movingItems: {
    id: `${scope}.movingItems`,
    defaultMessage: 'Moving Items',
  },
  remove: {
    id: `${scope}.remove`,
    defaultMessage: 'Remove',
  },
  removeSelected: {
    id: `${scope}.removeSelected`,
    defaultMessage: 'Remove Selected',
  },
  removeItems: {
    id: `${scope}.removeItems`,
    defaultMessage: 'Remove Items',
  },
  removing: {
    id: `${scope}.removing`,
    defaultMessage: 'Removing',
  },
  removingItems: {
    id: `${scope}.removingItems`,
    defaultMessage: 'Removing Items',
  },
  reset: {
    id: `${scope}.reset`,
    defaultMessage: 'Reset',
  },
  resetSelected: {
    id: `${scope}.resetSelected`,
    defaultMessage: 'Reset Selected',
  },
  resetItems: {
    id: `${scope}.resetItems`,
    defaultMessage: 'Reset Items',
  },
  delete: {
    id: `${scope}.delete`,
    defaultMessage: 'Delete',
  },
  deleteSelected: {
    id: `${scope}.deleteSelected`,
    defaultMessage: 'Delete Selected',
  },
  deleteItems: {
    id: `${scope}.deleteItems`,
    defaultMessage: 'Delete Items',
  },
  deleting: {
    id: `${scope}.deleting`,
    defaultMessage: 'Deleting',
  },
  select: {
    id: `${scope}.select`,
    defaultMessage: 'Select',
  },
  selectAll: {
    id: `${scope}.selectAll`,
    defaultMessage: 'Select All',
  },
  process: {
    id: `${scope}.process`,
    defaultMessage: 'Process',
  },
  processSelected: {
    id: `${scope}.processSelected`,
    defaultMessage: 'Process Selected',
  },
  processItems: {
    id: `${scope}.processItems`,
    defaultMessage: 'Process Items',
  },
  processing: {
    id: `${scope}.processing`,
    defaultMessage: 'Processing',
  },
  view: {
    id: `${scope}.view`,
    defaultMessage: 'View',
  },
  viewAll: {
    id: `${scope}.viewAll`,
    defaultMessage: 'View All',
  },
  viewDetails: {
    id: `${scope}.viewDetails`,
    defaultMessage: 'View Details',
  },
  of: {
    id: `${scope}.of`,
    defaultMessage: 'of',
  },
  rowsPerPage: {
    id: `${scope}.rowsPerPage`,
    defaultMessage: 'Rows per page: ',
  },
  next: {
    id: `${scope}.next`,
    defaultMessage: 'Next',
  },
  previous: {
    id: `${scope}.previous`,
    defaultMessage: 'Previous',
  },
  openNavigation: {
    id: `${scope}.openNavigation`,
    defaultMessage: 'Open Navigation',
  },
  thisMonth: {
    id: `${scope}.thisMonth`,
    defaultMessage: 'This Month',
  },
  nextMonth: {
    id: `${scope}.nextMonth`,
    defaultMessage: 'Next Month',
  },
  somethingWentWrong: {
    id: `${scope}.somethingWentWrong`,
    defaultMessage: 'Something went wrong',
  },
  internalIssues: {
    id: `${scope}.internalIssues`,
    defaultMessage: 'We seem to be having some internal issues.',
  },
  ourTeamHasBeenNotified: {
    id: `${scope}.ourTeamHasBeenNotified`,
    defaultMessage:
      "Our team has been notified. If you'd like to help us you can tell us what happened below.",
  },
  reportFeedback: {
    id: `${scope}.reportFeedback`,
    defaultMessage: 'Report Report',
  },
  looksLikeWereHavingProblems: {
    id: `${scope}.looksLikeWereHavingProblems`,
    defaultMessage: "It looks like we're having problems",
  },
  NA: {
    id: `${scope}.NA`,
    defaultMessage: 'N/A',
  },
  default: {
    id: `${scope}.default`,
    defaultMessage: 'default',
  },
  ok: {
    id: `${scope}.ok`,
    defaultMessage: 'OK',
  },

  blog: {
    id: `${scope}.blog`,
    defaultMessage: 'Blog',
  },

  // Address related messages
  addAddress: {
    id: `${scope}.addAddress`,
    defaultMessage: 'Add Address',
  },
  updateAddress: {
    id: `${scope}.updateAddress`,
    defaultMessage: 'Update Address',
  },
  setAsDefault: {
    id: `${scope}.setAsDefault`,
    defaultMessage: 'Set as default address',
  },
  editAddress: {
    id: `${scope}.editAddress`,
    defaultMessage: 'Edit Address',
  },
  deleteAddress: {
    id: `${scope}.deleteAddress`,
    defaultMessage: 'Delete Address',
  },
  selectAddress: {
    id: `${scope}.selectAddress`,
    defaultMessage: 'Select Address',
  },
  myAddresses: {
    id: `${scope}.myAddresses`,
    defaultMessage: 'My Addresses',
  },
  chineseIDNumber: {
    id: `${scope}.chineseIDNumber`,
    defaultMessage: 'Chinese ID Number',
  },
  IDNumber: {
    id: `${scope}.IDNumber`,
    defaultMessage: 'ID Number',
  },
  firstname: {
    id: `${scope}.firstname`,
    defaultMessage: 'First Name',
  },
  lastname: {
    id: `${scope}.lastname`,
    defaultMessage: 'Last Name',
  },
  cardholderName: {
    id: `${scope}.cardholderName`,
    defaultMessage: 'Cardholder Name',
  },
  phoneNumber: {
    id: `${scope}.phoneNumber`,
    defaultMessage: 'Phone Number',
  },
  newPhoneNumber: {
    id: `${scope}.newPhoneNumber`,
    defaultMessage: 'New Phone Number',
  },
  email: {
    id: `${scope}.email`,
    defaultMessage: 'Email Address',
  },
  dob: {
    id: `${scope}.dob`,
    defaultMessage: 'Date of Birth',
  },
  country: {
    id: `${scope}.country`,
    defaultMessage: 'Country',
  },
  countryCode: {
    id: `${scope}.countryCode`,
    defaultMessage: 'Country Code',
  },
  address: {
    id: `${scope}.address`,
    defaultMessage: 'Address',
  },
  address1Default: {
    id: `${scope}.address1Default`,
    defaultMessage: 'Address',
  },
  address2Default: {
    id: `${scope}.address2Default`,
    defaultMessage: 'Address Additional Line',
  },
  regionDefault: {
    id: `${scope}.regionDefault`,
    defaultMessage: 'State / Province / Region',
  },
  cityDefault: {
    id: `${scope}.cityDefault`,
    defaultMessage: 'City / Town',
  },
  districtDefault: {
    id: `${scope}.districtDefault`,
    defaultMessage: 'District / Neighbourhood',
  },
  postalDefault: {
    id: `${scope}.postalDefault`,
    defaultMessage: 'ZIP Code / Postal Code',
  },

  // Custom form labels
  colony: {
    id: `${scope}.colony`,
    defaultMessage: 'Colony',
  },
  state: {
    id: `${scope}.state`,
    defaultMessage: 'State',
  },
  province: {
    id: `${scope}.province`,
    defaultMessage: 'Province',
  },
  region: {
    id: `${scope}.region`,
    defaultMessage: 'Region',
  },
  city: {
    id: `${scope}.city`,
    defaultMessage: 'City',
  },
  town: {
    id: `${scope}.town`,
    defaultMessage: 'Town',
  },
  district: {
    id: `${scope}.district`,
    defaultMessage: 'District',
  },
  neighbourhood: {
    id: `${scope}.neighbourhood`,
    defaultMessage: 'Neighbourhood',
  },
  postalCode: {
    id: `${scope}.postalCode`,
    defaultMessage: 'Postal Code',
  },
  zipCode: {
    id: `${scope}.zipCode`,
    defaultMessage: 'ZIP Code',
  },

  // Order related messages
  orderSummary: {
    id: `${scope}.orderSummary`,
    defaultMessage: 'Order Summary',
  },
  name: {
    id: `${scope}.name`,
    defaultMessage: 'Name',
  },
  itemPrice: {
    id: `${scope}.itemPrice`,
    defaultMessage: 'Item Price',
  },
  quantity: {
    id: `${scope}.quantity`,
    defaultMessage: 'Quantity',
  },
  pv: {
    id: `${scope}.pv`,
    defaultMessage: 'PV',
  },
  pvc: {
    id: `${scope}.pvc`,
    defaultMessage: 'PV: ',
  },
  total: {
    id: `${scope}.total`,
    defaultMessage: 'Total',
  },
  totalColon: {
    id: `${scope}.totalColon`,
    defaultMessage: 'Total:',
  },
  PVColon: {
    id: `${scope}.PVColon`,
    defaultMessage: 'PV total:',
  },
  subtotal: {
    id: `${scope}.subtotal`,
    defaultMessage: 'Subtotal',
  },
  tax_amount: {
    id: `${scope}.tax_amount`,
    defaultMessage: 'Tax Amount',
  },
  discount_amount: {
    id: `${scope}.discount_amount`,
    defaultMessage: 'Discount Amount',
  },
  grand_total: {
    id: `${scope}.grand_total`,
    defaultMessage: 'Grand Total',
  },
  shipping_amount: {
    id: `${scope}.shipping_amount`,
    defaultMessage: 'Shipping Fee',
  },
  remainingTime: {
    id: `${scope}.remainingTime`,
    defaultMessage: 'Remaining Time',
  },
  payNow: {
    id: `${scope}.payNow`,
    defaultMessage: 'Pay Now',
  },
  addToCart: {
    id: `${scope}.addToCart`,
    defaultMessage: 'Add to Cart',
  },
  addingToCart: {
    id: `${scope}.addingToCart`,
    defaultMessage: 'Adding...',
  },
  buyNow: {
    id: `${scope}.buyNow`,
    defaultMessage: 'Buy Now',
  },
  orderNumberColon: {
    id: `${scope}.orderNumberColon`,
    defaultMessage: 'Order Number: {orderId}',
  },
  selectOptions: {
    id: `${scope}.selectOptions`,
    defaultMessage: 'Select Options',
  },

  // Empty pages related messages
  startShopping: {
    id: `${scope}.startShopping`,
    defaultMessage: 'Start Shopping',
  },
  tapToStartShopping: {
    id: `${scope}.tapToStartShopping`,
    defaultMessage: 'Tap the button to start shopping',
  },

  // Homepage
  about: {
    id: `${scope}.about`,
    defaultMessage: 'ABOUT',
  },
  helpCenter: {
    id: `${scope}.helpCenter`,
    defaultMessage: 'HELP CENTER',
  },
  modalConfirmPay: {
    id: `${scope}.modalConfirmPay`,
    defaultMessage: 'Confirm that the information is consistent and pay',
  },
  modalNotSure: {
    id: `${scope}.modalNotSure`,
    defaultMessage: 'Not sure, check back',
  },
  confirmRemovalOfItem: {
    id: `${scope}.confirmRemovalOfItem`,
    defaultMessage: 'Confirm removal of item',
  },
  wrongPhoneNumber: {
    id: `${scope}.wrongPhoneNumber`,
    defaultMessage: 'Wrong Phone Number',
  },
  home: {
    id: `${scope}.home`,
    defaultMessage: 'Home',
  },
  category: {
    id: `${scope}.category`,
    defaultMessage: 'Category',
  },
  account: {
    id: `${scope}.account`,
    defaultMessage: 'Account',
  },
});

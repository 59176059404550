import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './reducer';

/**
 * Direct selector to the checkout state domain
 */

const selectCheckoutDomain = (state) => state.checkout || initialState;

const makeSelectLockCart = () => createSelector(
  selectCheckoutDomain,
  (substate) => substate.lock,
);
/**
 * Other specific selectors
 */

const makeSelectCheckoutQuote = () => createSelector(
  selectCheckoutDomain,
  (substate) => substate.quote,
);

const makeSelectCheckoutQuoteId = () => createSelector(
  selectCheckoutDomain,
  (substate) => substate.quote.id,
);

const makeSelectCheckoutQuoteItems = () => createSelector(
  selectCheckoutDomain,
  (substate) => (substate.quote.items ? substate.quote.items : []),
);

const makeSelectShippingAddressSelected = () => createSelector(
  selectCheckoutDomain,
  (substate) => substate.selectedAddress,
);

const makeSelectShippingMethods = () => createSelector(
  selectCheckoutDomain,
  (substate) => substate.shippingMethods,
);

const makeSelectShippingMethodSelected = () => createSelector(
  selectCheckoutDomain,
  (substate) => substate.selectedShippingMethod,
);

const makeSelectPaymentMethods = () => createSelector(
  selectCheckoutDomain,
  (substate) => substate.paymentMethods || [],
);

const makeSelectPaymentMethodSelected = () => createSelector(
  selectCheckoutDomain,
  (substate) => substate.selectedPaymentMethod,
);

const makeSelectCheckoutTotals = () => createSelector(
  selectCheckoutDomain,
  (substate) => substate.totals,
);

const makeSelectCardSelected = () => createSelector(
  selectCheckoutDomain,
  (substate) => substate.selectedCard,
);

/**
 * Default selector used by Checkout
 */

const makeSelectCheckout = () => createSelector(
  selectCheckoutDomain,
  (substate) => substate,
);
export default makeSelectCheckout;
export {
  selectCheckoutDomain,
  makeSelectCheckoutQuote,
  makeSelectCheckoutQuoteId,
  makeSelectCheckoutQuoteItems,
  makeSelectShippingAddressSelected,
  makeSelectLockCart,
  makeSelectShippingMethods,
  makeSelectShippingMethodSelected,
  makeSelectPaymentMethods,
  makeSelectPaymentMethodSelected,
  makeSelectCheckoutTotals,
  makeSelectCardSelected,

};

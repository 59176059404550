/* eslint-disable no-console */
import React from 'react';
import {
  all, takeLeading, takeLatest, call, put, take, select,
} from 'redux-saga/effects';
import isUndefined from 'lodash/isUndefined';
import isEmpty from 'lodash/isEmpty';
import clone from 'lodash/clone';
import get from 'lodash/get';
import { push } from 'connected-react-router/immutable';
import { sprintf, convertCartItemId } from 'utils/helpers';
import routes from 'utils/routes';
import { setBuyNow, setProceedToCheckout } from 'utils/Checkout';
import { actions as notificationActions } from 'utils/Notifications/actions';
import CloseNotification from 'utils/Notifications/CloseNotification';
import { actions as appActions } from 'containers/App/actions';
import determineEnvironment from 'utils/WeChat/determineEnvironment';
import {
  makeSelectCartItems, makeSelectCartSelectedItems,
  makeSelectCartSubTotal,
} from 'containers/CartProvider/selectors';
import processWeChatPayParameters from 'utils/WeChat/processWeChatPayParameters';
import { getAuthorization } from 'utils/Auth';
import { AVAILABLE_PAYMENT_METHODS } from './constants';
import { constants, actions } from './actions';
import {
  makeSelectCheckoutQuoteId, makeSelectShippingAddressSelected,
  makeSelectShippingMethods, makeSelectPaymentMethods,
} from './selectors';
import {
  createCheckoutQuote, getCheckoutQuote, clearCheckoutQuote,
  addToCheckoutQuote, moveToCheckoutQuote, estimateShippingCosts,
  addShippingInformation, addBillingInformation, getCheckoutTotals,
  placeOrder, fetchWeChatQrPayParamaters, fetchWeChatPayParamaters,
  deployPoints, removePoints,
} from './api';

/**
 *
 * Set up checkout by fetching checkout quote
 */
export function* fetchCheckoutQuote() {
  const { location } = window;
  try {
    const quote = yield call(getCheckoutQuote);
    yield put({ type: constants.GET_TOTALS_REQUEST });

    yield put({ type: constants.SET_CHECKOUT_QUOTE, quote });

    yield put({ type: constants.GET_CHECKOUT_QUOTE_SUCCESS });

    if (isEmpty(quote.items) && location.pathname === routes.checkout) {
      yield put(push(routes.dashboard));
    }
  } catch (err) {
    console.log(err);
    yield put({ type: constants.GET_CHECKOUT_QUOTE_FAILURE });

    // Fallback for creating a (checkout) quote if customer does not have one
    // Back-end logic is coming from partial-checkout
    if (err.response && err.response.data) {
      const { message, parameters } = err.response.data;
      if (
        message === 'No such entity with %fieldName = %fieldValue'
        && parameters.fieldName === 'customerId'
      ) {
        yield call(createCheckoutQuote);
        yield put({ type: constants.GET_CHECKOUT_QUOTE_REQUEST });
      }
    }
  }
}

/**
 * Remove all items from the customer's active checkout quote
 */
export function* handleClearCheckoutQuote() {
  try {
    yield call(clearCheckoutQuote);
    yield put({ type: constants.CLEAR_CHECKOUT_QUOTE_COMPLETE });
  } catch (err) {
    console.log('Error: ', err);
  }
}

/**
 * Perform Buy Now process by adding single item to quote and redirecting to checkout page
 */
export function* handleBuyNow(action) {
  const { payload } = action;
  const { error } = payload;

  // for limit buynow quantity
  // check if over limit 5000yuan
  if (payload.calculatedPrice && payload.calculatedPrice * payload.data.cartItem.qty > 5000) {
    yield put(notificationActions.enqueueSnackbar({
      message: sprintf('单个订单金额不能超过5000元', ''),
      options: {
        key: new Date().getTime() + Math.random(),
        variant: 'warning',
        action: (key) => <CloseNotification notificationKey={key} />,
      },
    }));
    yield put({ type: constants.BUY_NOW_FAILURE });
    if (error) error();
    return;
  }

  try {
    const auth = getAuthorization();
    if (auth.type !== 'user') {
      setBuyNow(payload.data);

      if (determineEnvironment()) {
        yield put(appActions.toggleMPLoginVisible({ visible: true }));
      } else {
        yield put(push(routes.logIn));
      }
    } else {
      yield put(actions.clearCheckoutQuote());

      // Wait for clearing call to finish
      yield take(constants.CLEAR_CHECKOUT_QUOTE_COMPLETE);

      yield put({ type: constants.GET_CHECKOUT_QUOTE_REQUEST });
      yield take(constants.GET_CHECKOUT_QUOTE_SUCCESS);

      // The data structures are different between guest and user
      payload.cartItem = payload.cartItem || payload.data.cartItem;

      payload.cartItem.quote_id = yield select(makeSelectCheckoutQuoteId());

      const checkoutItem = yield call(addToCheckoutQuote, payload);

      const checkoutQuote = yield call(getCheckoutQuote);

      checkoutQuote.items = [checkoutItem];

      yield put({ type: constants.SET_CHECKOUT_QUOTE, quote: checkoutQuote });

      yield put({ type: constants.BUY_NOW_SUCCESS });

      yield put(push(routes.checkout));
    }
  } catch (err) {
    console.log('Error: ', err);
    yield put({ type: constants.BUY_NOW_FAILURE });
    if (error) error();
  }
}

/**
 * Perform Proceed to Checkout process by adding a set of items to quote and redirecting to checkout page
 */
export function* handleProceedToCheckout(action) {
  const cartItems = action.payload ? action.payload : [];
  // lock cart edit
  yield put({ type: constants.TOOGLE_LOCK_CART, lock: true });

  const total = yield select(makeSelectCartSubTotal());
  if (total > 5000) {
    yield put(notificationActions.enqueueSnackbar({
      message: sprintf('单个订单金额不能超过5000元', ''),
      options: {
        key: new Date().getTime() + Math.random(),
        variant: 'warning',
        action: (key) => <CloseNotification notificationKey={key} />,
      },
    }));

    yield put({ type: constants.PROCEED_TO_CHECKOUT_FAILURE });
    yield put({ type: constants.TOOGLE_LOCK_CART, lock: false });
    return;
  }
  try {
    const auth = getAuthorization();
    const itemIds = yield select(makeSelectCartSelectedItems());
    yield call(clearCheckoutQuote);

    if (auth.type !== 'user') {
      if (!itemIds || isEmpty(itemIds)) {
        yield put({ type: constants.PROCEED_TO_CHECKOUT_FAILURE });
        return;
      }

      const selectedCartItems = yield select(makeSelectCartItems());

      const items = [];
      selectedCartItems.forEach((i) => {
        if (itemIds.includes(i.item_id)) {
          //  items.push(i.item_id);
          items.push({ item_id: i.item_id, qty: i.qty, sku: i.sku });// [i.item_id] = i.qty;
        }
      });
      setProceedToCheckout(items);
      if (determineEnvironment()) {
        yield put(appActions.toggleMPLoginVisible({ visible: true }));
      } else {
        yield put(push(routes.logIn));
      }
    } else {
      const itemId = yield select(makeSelectCartSelectedItems());

      if (isEmpty(itemId) && isEmpty(cartItems)) {
        yield put({ type: constants.PROCEED_TO_CHECKOUT_FAILURE });
        return;
      }

      if (!isEmpty(itemId)) {
        yield call(moveToCheckoutQuote, { itemIds: itemId });
      }
      // for guest user  change cart item_id
      const selectedCartItems = yield select(makeSelectCartItems());
      let items = [];
      if (cartItems) {
        selectedCartItems.forEach((i) => {
          cartItems.forEach((i2) => {
            if (i.sku === i2.sku) {
              // items.push(i.item_id);// [i.item_id] = i.qty;
              items.push({ item_id: i.item_id, qty: i2.qty });// [i.item_id] = i.qty;
            }
          });
        });
      } else {
        items = selectedCartItems;
      }
      // check item_id if not in cart  change item_id;
      if (!isEmpty(cartItems)) {
        yield call(moveToCheckoutQuote, { itemIds: convertCartItemId(items) });
      }
      yield put({ type: constants.GET_CHECKOUT_QUOTE_REQUEST });
      yield put(push(routes.checkout));
    }

    yield put({ type: constants.TOOGLE_LOCK_CART, lock: false });
    yield put({ type: constants.PROCEED_TO_CHECKOUT_SUCCESS });
  } catch (err) {
    console.log('Error: ', err);
    yield put({ type: constants.TOOGLE_LOCK_CART, lock: false });
    yield put({ type: constants.PROCEED_TO_CHECKOUT_FAILURE });
  }
}

/**
 * Fetch available shipping methods for provided address. Fired every time a shipping address is set.
 */
export function* fetchShippingMethods(action) {
  const { address } = action;
  try {
    if (get(address, 'address.id')) {
      const shippingMethods = yield call(estimateShippingCosts, { address_id: address.address.id });

      yield put({ type: constants.SET_SHIPPING_METHODS, shippingMethods });

      yield put({ type: constants.GET_SHIPPING_METHODS_SUCCESS });

      if (shippingMethods.length > 0) {
        yield put({ type: constants.SELECT_SHIPPING_METHOD, method: shippingMethods[0] });
      }

      if (shippingMethods.length === 0) {
        yield put({ type: constants.SELECT_SHIPPING_METHOD, method: 'virtual' });
      }
    }
  } catch (err) {
    console.log('Error: ', err);
    yield put({ type: constants.GET_SHIPPING_METHODS_FAILURE });
  }
}

/**
 * Add shipping and billing information to checkout quote
 */
export function* handleAddShippingInformation(action) {
  const { method } = action;
  // yie.GET_TOTALS_REQUEST });
  const shippingAddress = yield select(makeSelectShippingAddressSelected());
  const shippingMethods = yield select(makeSelectShippingMethods());

  try {
    if ((isEmpty(method) || isEmpty(shippingAddress) || isEmpty(shippingMethods)) && method !== 'virtual') {
      return;
    }

    if (method === 'virtual') {
      const virtualPayload = {
        address: {
          customer_address_id: shippingAddress.address.id,
          country_id: shippingAddress.address.country_id,
        },
      };

      yield call(addBillingInformation, virtualPayload);

      yield put({ type: constants.ADD_SHIPPING_INFO_SUCCESS });
    } else {
      let selectedMethod = shippingMethods.find((m) => m.carrier_code === method.carrier_code);
      if (isUndefined(selectedMethod)) {
        // eslint-disable-next-line prefer-destructuring
        selectedMethod = shippingMethods[0];
      }

      const address = clone(shippingAddress.address);

      // TODO - Make
      const payload = {
        addressInformation: {
          shipping_address: { customer_address_id: address.id, country_id: address.country_id },
          billing_address: { customer_address_id: address.id },
          shipping_carrier_code: selectedMethod ? selectedMethod.carrier_code : '',
          shipping_method_code: selectedMethod ? selectedMethod.method_code : '',
        },
      };

      delete payload.addressInformation.shipping_address.id;
      delete payload.addressInformation.billing_address.id;

      const result = yield call(addShippingInformation, payload);

      yield put({ type: constants.ADD_SHIPPING_INFO_SUCCESS });

      yield put({ type: constants.SET_TOTALS, totals: result.totals });

      const paymentMethods = result.payment_methods.filter((m) => AVAILABLE_PAYMENT_METHODS.includes(m.code));

      yield put({ type: constants.SET_PAYMENT_METHODS, paymentMethods });

      if (paymentMethods.length) {
        yield put({ type: constants.SELECT_PAYMENT_METHOD, paymentMethod: paymentMethods[0] });
      }
    }
  } catch (err) {
    console.log('Error: ', err);
    yield put({ type: constants.ADD_SHIPPING_INFO_FAILURE });
  }
}

/**
 * Fetch order price information
 */
export function* fetchCheckoutQuoteTotals(action) {
  let totals = action.payload;
  try {
    // If totals are passed along with the action, we can skip calling the totals API
    if (isUndefined(totals) || isEmpty(totals)) {
      totals = yield call(getCheckoutTotals);
    }

    yield put({ type: constants.SET_TOTALS, totals });

    yield put({ type: constants.GET_TOTALS_SUCCESS });
  } catch (err) {
    console.log('Error: ', err);
    yield put({ type: constants.GET_TOTALS_FAILURE });
  }
}

/**
 * Place the order without a payment method
 */
export function* handlePlaceOrder(action) {
  const { complete, error } = action;
  const availablePaymentMethods = yield select(makeSelectPaymentMethods());
  const totals = yield call(getCheckoutTotals);
  const hasWCP = availablePaymentMethods.some((m) => m.code === 'wechatpay');

  if (totals.subtotal_incl_tax > 5000) {
    yield put(notificationActions.enqueueSnackbar({
      message: sprintf('单个订单金额不能超过5000元', ''),
      options: {
        key: new Date().getTime() + Math.random(),
        variant: 'warning',
        action: (key) => <CloseNotification notificationKey={key} />,
      },
    }));

    yield put({ type: constants.PLACE_ORDER_FAILURE });
    if (error) error();
  } else {
    try {
      let method = '';
      if (totals.grand_total) {
        method = hasWCP ? 'wechatpay' : 'cashondelivery';
      } else {
        method = 'wechatpay';
      }

      const payload = { paymentMethod: { method } };
      const orderID = yield call(placeOrder, payload);
      yield put({ type: constants.PLACE_ORDER_SUCCESS });

      if (orderID) {
        if (hasWCP && determineEnvironment()) {
          const wechatpayParameters = yield call(fetchWeChatPayParamaters, orderID);
          processWeChatPayParameters(wechatpayParameters, orderID).catch(() => {
            // Notification - Something went wrong
          });
          if (complete) complete(orderID);
          yield put(push(routes.success(orderID)));
        } else {
          if (complete) complete(orderID);
          yield put(push(routes.success(orderID)));
        }
      }
    } catch (err) {
      console.log('Error: ', err);
      if (error) error();
      yield put({ type: constants.PLACE_ORDER_FAILURE });
    }
  }
}

/**
 * Place the order without a payment method Desktop
 */
export function* handlePlaceOrderDesktop(action) {
  const { complete, error } = action.payload;
  // const availablePaymentMethods = yield select(makeSelectPaymentMethods());
  // const hasWCP = availablePaymentMethods.some((m) => m.code === 'wechatpay');
  const totals = yield call(getCheckoutTotals);
  if (totals.subtotal_incl_tax > 5000) {
    yield put(
      notificationActions.enqueueSnackbar({
        message: sprintf('单个订单金额不能超过5000元', ''),
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'warning',
          action: (key) => <CloseNotification notificationKey={key} />,
        },
      }),
    );

    yield put({ type: constants.PLACE_ORDER_FAILURE_DESKTOP });
    if (error) error();
  } else {
    try {
      const payload = { paymentMethod: { method: 'wechatpay' } };

      const orderID = yield call(placeOrder, payload);

      yield put({ type: constants.PLACE_ORDER_SUCCESS_DESKTOP });

      if (orderID) {
        if (complete) complete(orderID);
      }
    } catch (err) {
      console.log('Error: ', err);
      if (error) error();
      yield put({ type: constants.PLACE_ORDER_FAILURE_DESKTOP });
    }
  }
}

/**
 * Complete an order by processing the payment
 */
export function* handlePayment(action) {
  const {
    orderID, paymentMethod, complete, error,
  } = action.payload;
  try {
    switch (paymentMethod) {
      case 'wechatpay':
        if (determineEnvironment()) {
          const wechatpayParameters = yield call(fetchWeChatPayParamaters, orderID);
          processWeChatPayParameters(wechatpayParameters, orderID).then(() => {
            if (complete) complete();
          }).catch(() => {
            // Notification - Something went wrong
          });
        } else {
          // WeChat H5 payment
          yield put(notificationActions.enqueueSnackbar({
            message: 'No payment methods available',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error',
            },
          }));
        }
        break;

      default:
        yield put(notificationActions.enqueueSnackbar({
          message: 'No payment methods available',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        }));

        if (error) error();
    }

    // TODO - Add additional payment methods

    yield put({ type: constants.PROCESS_PAYMENT_SUCCESS });
  } catch (err) {
    console.log('Error: ', err);
    if (error) error();
    yield put({ type: constants.PROCESS_PAYMENT_FAILURE });
  }
}

/**
 * Complete an order by processing the payment
 */
export function* handlePaymentDesktop(action) {
  const {
    orderId, error, complete, selectedPaymentMethod,
  } = action.payload;
  try {
    switch (selectedPaymentMethod.code) {
      case 'wechatpay': {
        const wechatpayParameters = yield call(fetchWeChatQrPayParamaters, orderId);
        if (complete) complete(wechatpayParameters);
        break;
      }

      default:
        yield put(
          notificationActions.enqueueSnackbar({
            message: 'No payment methods available',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error',
              action: (key) => <CloseNotification notificationKey={key} />,
            },
          }),
        );

        if (error) error();
    }

    // TODO - Add additional payment methods

    yield put({ type: constants.PROCESS_PAYMENT_SUCCESS_DESKTOP });
  } catch (err) {
    console.log('Error: ', err);
    if (error) error();
    yield put({ type: constants.PROCESS_PAYMENT_FAILURE_DESKTOP });
  }
}

export function* toggleUserCredit(action) {
  yield put({ type: constants.SET_TOTALS, totals: 0 });
  const { method, complete } = action.payload;
  try {
    if (method === true) {
      deployPoints().then(() => {
        if (complete) complete();
      });
    } else if (method === false) {
      removePoints().then(() => {
        if (complete) complete();
      });
    }
  } catch (err) {
    console.log('Error: ', err);
    yield put({ type: constants.USER_CREDIT_FAILURE });
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* rootSaga() {
  yield all([
    takeLeading(constants.GET_CHECKOUT_QUOTE_REQUEST, fetchCheckoutQuote),
    takeLeading(constants.CLEAR_CHECKOUT_QUOTE, handleClearCheckoutQuote),
    takeLeading(constants.BUY_NOW_REQUEST, handleBuyNow),
    takeLeading(constants.PROCEED_TO_CHECKOUT_REQUEST, handleProceedToCheckout),
    takeLatest(constants.SELECT_SHIPPING_ADDRESS, fetchShippingMethods),
    takeLatest(constants.SELECT_SHIPPING_METHOD, handleAddShippingInformation),
    takeLatest(constants.GET_TOTALS_REQUEST, fetchCheckoutQuoteTotals),
    takeLatest(constants.USER_CREDIT_REQUEST, toggleUserCredit),
    takeLeading(constants.PLACE_ORDER_REQUEST, handlePlaceOrder),
    takeLeading(constants.PLACE_ORDER_REQUEST_DESKTOP, handlePlaceOrderDesktop),
    takeLeading(constants.PROCESS_PAYMENT_REQUEST, handlePayment),
    takeLeading(constants.PROCESS_PAYMENT_REQUEST_DESKTOP, handlePaymentDesktop),
  ]);
}

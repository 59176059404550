/**
 *
 * Asynchronously loads the component for SharingProvider
 *
 */

import loadable from 'utils/loadable';

export const CustomizeSharing = loadable(() => import('./components/CustomizeSharing'));
export const BrandEmbassador = loadable(() => import('./components/BrandEmbassador'));

export default loadable(() => import('./index'));

/**
 *
 * MiniCart
 *
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { createStructuredSelector } from 'reselect';
import { useHistory } from 'react-router-dom';
import { connect, useSelector, useDispatch } from 'react-redux';
import { selectShowMiniCart, toggleMinicart } from 'containers/App/slices';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatPrice } from 'utils/helpers';
import routes from 'utils/routes';
import { actions } from 'containers/CartProvider/actions';
import get from 'lodash/get';
import {
  makeSelectCartQuantity, makeSelectCartItems, makeSelectCartAllSelected,
  makeSelectCartSubTotal, makeSelectCartSelectedItems, makeSelectCartPvTotal,
  makeSelectLockCheckout, makeSelectLock1000,
} from 'containers/CartProvider/selectors';
import { makeSelectLockCart } from 'containers/CheckoutPage/selectors';
import { actions as checkoutActions } from 'containers/CheckoutPage/actions';
import { updateEcommerceCart, handleClearEcommerceCart } from 'utils/ga';
import isEmpty from 'lodash/isEmpty';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import Badge from '@material-ui/core/Badge';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { makeStyles } from '@material-ui/core/styles';
import Buttons from 'components/Buttons/Buttons';
import appMessages from 'containers/App/messages';
import MiniCartListItem from 'containers/CartProvider/components/MiniCartListItem';
import PointsProgress from 'components/Order/PointsProgress';
import EmptyState from 'components/EmptyState/Loadable';
import messages from 'containers/CartProvider/messages';
import CheckBoxInput from 'components/Inputs/CheckBoxInput';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: `${theme.zIndex.appBar - 1} !important`,
  },
  drawer: {
    height: 'auto',
    overflow: 'auto',
    paddingBottom: 50,
  },
  anchorBottom: {
    top: 30,
  },
  miniCartButton: {
    minWidth: 50,
    padding: theme.spacing(0.5, 1),
    fontSize: 24,
    '& .MuiBottomNavigationAction-label': {
      marginTop: -2,
    },
  },
}));

export function MiniCart({
  hideLabel, cartItems,
  selectedItems, allSelected, toggleAllItems, subTotal, pvTotal, cartQuantity,
  proceedToCheckout, lockCheckout, isCartLock,
  saveAll, lock1000,
}) {
  const classes = useStyles();
  const [totalQty, setTotalQty] = useState(0);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const showMiniCart = useSelector(selectShowMiniCart);

  useEffect(() => {
    saveAll({
      complete: () => {
      },
    });
  }, []);

  useEffect(() => {
    if (selectedItems && cartItems) {
      setTotalQty(cartItems.reduce((acc, curr) => acc + (selectedItems.includes(curr.item_id)
        ? curr.qty : 0), 0));
    }
  }, [cartItems, selectedItems]);

  useEffect(() => {
    let total = 0;
    if (!isEmpty(cartItems)) {
      // eslint-disable-next-line array-callback-return
      cartItems.map((item) => {
        const price = get(item.extension_attributes, 'calculated_price', 0);
        total += item.qty * price;
      });
    }
    if (total === 0) {
      handleClearEcommerceCart();
    } else if (!isEmpty(cartItems)) {
      setTimeout(() => {
        updateEcommerceCart(cartItems, total);
      }, 1000);
    }
  }, [cartItems]);

  const toggleDrawer = () => {
    saveAll({
      complete: () => {
      },
    });
    dispatch(toggleMinicart(!showMiniCart));
  };

  const handleDrawerClose = () => {
    dispatch(toggleMinicart(false));
    saveAll({
      complete: () => {
      },
    });
  };

  const handleSelectAll = () => toggleAllItems(!allSelected);

  const handleShowCheckout = () => proceedToCheckout();

  const handleRedirect = () => {
    history.push(routes.catalogEmpty);
    dispatch(toggleMinicart(false));
  };

  return (
    <>
      <BottomNavigationAction
        onClick={toggleDrawer}
        className={classes.miniCartButton}
        showLabel
        label={!hideLabel
          && (
          <Typography color={`${showMiniCart ? 'secondary' : 'textPrimary'}`} variant="caption" style={{ fontWeight: `${showMiniCart ? 550 : 300}` }}>
            <FormattedMessage {...messages.cart} />
          </Typography>
          )}
        icon={(
          <Badge style={{ marginTop: 2 }} badgeContent={cartQuantity} color="secondary">
            <Box color={`${showMiniCart ? 'secondary.main' : 'text.primary'}`}>
              <FontAwesomeIcon color="inherit" icon={[`${showMiniCart ? 'fas' : 'fal'}`, 'shopping-cart']} />
            </Box>
          </Badge>
   )}
      />
      <Drawer
        anchor="bottom"
        open={showMiniCart}
        transitionDuration={{ enter: 250, exit: 100 }}
        onClose={handleDrawerClose}
        classes={{ root: classes.root, paper: classes.drawer, paperAnchorBottom: classes.anchorBottom }}
      >
        {(cartItems && cartItems.length > 0) ? (
          <Box minHeight={1} overflow="scroll">
            <Box
              height={53}
              px={1.75}
              py={2}
              position="fixed"
              width={1}
              zIndex={10}
              display="flex"
              justifyContent="space-between"
              bgcolor="primary.white"
              alignItems="center"
              style={{ boxShadow: '0px -1px 10px 0px rgba(0, 0, 0, 0.45)' }}
            >
              <Box width={200}>
                <PointsProgress addition={pvTotal} />
              </Box>
              <Buttons
                onClick={handleDrawerClose}
                icon={<FontAwesomeIcon icon={['fal', 'times']} />}
                style={{ minHeight: 21 }}
                messageId={<FormattedMessage {...appMessages.close} />}
              />
            </Box>
            <Box overflow="scroll" maxHeight="100%" pt={7} pb={17.5}>
              {cartItems.map((item) => (
                <MiniCartListItem
                  key={item.item_id}
                  cartItem={item}
                  proceedingToCheckout={(bool) => setLoading(bool)}
                  small
                />
              ))}
            </Box>
            <Box zIndex={100} position="absolute" width={1} bottom={80} bgcolor="primary.white" style={{ padding: '0 12px 12px 10px' }}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box>
                  <CheckBoxInput
                    checked={allSelected}
                    value="selectAll"
                    onChange={handleSelectAll}
                    label={<FormattedMessage {...appMessages.selectAll} />}
                    disabled={isCartLock || lock1000 || loading || lockCheckout > 0}
                  />
                </Box>
                <Box pr={6}>
                  <Typography variant="caption" color="textSecondary">
                    <FormattedMessage {...appMessages.totalColon} />
&nbsp;
                  </Typography>
                  <Typography variant="subtitle2" component="span" color="secondary">
                    {formatPrice(subTotal)}
                  </Typography>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <Typography variant="caption" color="textSecondary">
                    <FormattedMessage {...appMessages.PVColon} />
&nbsp;
                  </Typography>
                  <Typography variant="subtitle2" component="span" color="secondary">
                    {pvTotal}
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" width={1} justifyContent="space-between" mt={0.5}>
                <Buttons
                  route={routes.catalogEmpty}
                  color="primary"
                  messageId={<FormattedMessage {...messages.continueShopping} />}
                  onClick={handleDrawerClose}
                />
                <Buttons
                  variant="contained"
                  onClick={handleShowCheckout}
                  loading={(loading)}
                  disabled={selectedItems.length < 1 || loading || totalQty <= 0 || lockCheckout > 0 || lock1000}
                  messageId={(lockCheckout > 0 || lock1000) ? (
                    <Typography color="primary"><FormattedMessage {...appMessages.processing} /></Typography>
                  ) : (
                    <Typography>
                      <FormattedMessage {...messages.checkOut} values={{ quantity: totalQty }} />
                      <FontAwesomeIcon icon={['fal', 'chevron-right']} transform="right-8" />
                    </Typography>
                  )}
                  style={{ marginLeft: 8 }}
                />
              </Box>
            </Box>
          </Box>
        ) : (
          <Box>
            <Box align="end" px={1.75} py={2}>
              <Buttons
                onClick={handleDrawerClose}
                icon={<FontAwesomeIcon icon={['fal', 'times']} />}
                messageId={<FormattedMessage {...appMessages.close} />}
              />
            </Box>
            <Box>
              <EmptyState
                body={<FormattedMessage {...appMessages.tapToStartShopping} />}
                icon={<FontAwesomeIcon icon={['fal', 'person-dolly-empty']} />}
                action={{
                  label: <FormattedMessage {...appMessages.startShopping} />,
                  event: handleRedirect,
                }}
              />
            </Box>
          </Box>
        )}
      </Drawer>
    </>
  );
}

MiniCart.defaultProps = {
  hideLabel: false,
  cartItems: [],
  selectedItems: [],
};

MiniCart.propTypes = {
  icon: PropTypes.node,
  hideLabel: PropTypes.bool,
  className: PropTypes.string,
  cartItems: PropTypes.array,
  selectedItems: PropTypes.array,
  allSelected: PropTypes.bool.isRequired,
  toggleAllItems: PropTypes.func,
  subTotal: PropTypes.number.isRequired,
  pvTotal: PropTypes.number.isRequired,
  cartQuantity: PropTypes.number.isRequired,
  proceedToCheckout: PropTypes.func,
  lockCheckout: PropTypes.number,
  lock1000: PropTypes.bool,
  isCartLock: PropTypes.bool,
  saveAll: PropTypes.func,
  updateCartItem: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  lockCheckout: makeSelectLockCheckout(),
  isCartLock: makeSelectLockCart(),
  lock1000: makeSelectLock1000(),
  cartItems: makeSelectCartItems(),
  selectedItems: makeSelectCartSelectedItems(),
  allSelected: makeSelectCartAllSelected(),
  subTotal: makeSelectCartSubTotal(),
  pvTotal: makeSelectCartPvTotal(),
  cartQuantity: makeSelectCartQuantity(),
});

function mapDispatchToProps(dispatch) {
  return {
    saveAll: (payload) => dispatch(actions.saveAll(payload)),
    updateCartItem: (checked) => dispatch(actions.updateCartItem(checked)),
    toggleAllItems: (checked) => dispatch(actions.toggleAllCartItems(checked)),
    proceedToCheckout: () => dispatch(checkoutActions.proceedToCheckout()),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default withConnect(MiniCart);

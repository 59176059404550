/*
 * Cart actions
 */

const base = 'Cart';
export const RESET_CART = `${base}/RESET`;
export const GET_CART_QUOTE = `${base}/GET`;
export const ADD_TO_CART = `${base}/ADD`;
export const UPDATE_CART = `${base}/UPDATE`;
export const REMOVE_FROM_CART = `${base}/REMOVE`;
export const SAVE_ALL = `${base}/SAVE/ALL`;

export const constants = {
  GET_CART_QUOTE_REQUEST: `${GET_CART_QUOTE}_REQUEST`,
  GET_CART_QUOTE_SUCCESS: `${GET_CART_QUOTE}_SUCCESS`,
  GET_CART_QUOTE_FAILURE: `${GET_CART_QUOTE}_FAILURE`,
  SET_CART_QUOTE: `${base}/SET`,

  ADD_TO_CART_REQUEST: `${ADD_TO_CART}_REQUEST`,
  ADD_TO_CART_SUCCESS: `${ADD_TO_CART}_SUCCESS`,
  ADD_TO_CART_FAILURE: `${ADD_TO_CART}_FAILURE`,

  TOGGLE_CART_ITEM: `${base}/SELECT/SINGLE`,
  TOGGLE_ALL_CART_ITEMS: `${base}/SELECT/ALL`,
  CLEAR_SELECTED_CART_ITEMS: `${base}/SELECT/CLEAR`,

  UPDATE_CART_REQUEST: `${UPDATE_CART}_REQUEST`,
  UPDATE_CART_SUCCESS: `${UPDATE_CART}_SUCCESS`,
  UPDATE_CART_FAILURE: `${UPDATE_CART}_FAILURE`,
  SET_CART_UPDATE: `${UPDATE_CART}/SET`,

  REMOVE_FROM_CART_REQUEST: `${REMOVE_FROM_CART}_REQUEST`,
  REMOVE_FROM_CART_SUCCESS: `${REMOVE_FROM_CART}_SUCCESS`,
  REMOVE_FROM_CART_FAILURE: `${REMOVE_FROM_CART}_FAILURE`,
  SET_CART_REMOVAL: `${REMOVE_FROM_CART}/SET`,

  EDIT_ALL: `${base}/EDIT/ALL`,
  CLEAR_ALL: `${base}/EDIT/ALL/CLEAR`,
  EDIT_SINGLE: `${base}/EDIT/SINGLE`,
  CLEAR_FROM_EDIT: `${base}/EDIT/SINGLE/CLEAR`,

  SAVE_ALL_REQUEST: `${SAVE_ALL}_REQUEST`,
  SAVE_ALL_SUCCESS: `${SAVE_ALL}_SUCCESS`,
  SAVE_ALL_FAILURE: `${SAVE_ALL}_FAILURE`,
  SAVING_SINGLE: `${base}/SAVE/SINGLE`,
  FINISH_SINGLE_SAVE: `${base}/SAVE/SINGLE/CLEAR`,
  UPDATE_CART_REQUEST_LOCAL: 'UPDATE_CART_REQUEST_LOCAL_REQUEST',
  TOOGLE_LOCK_CHECKOUT: `${base}/TOGGLECARTPENDDING`,
  TOOGLE_LOCK_1000: `${base}/TOOGLELOCK1000`,

  MIDDLEWARE: `${base}/MIDDLEWARE`,
};

export const actions = {
  toggleLock1000: (lock1000 = false) => ({ type: constants.TOOGLE_LOCK_1000, lock1000 }),
  toggleLockCheckout: (lock = false) => ({ type: constants.TOOGLE_LOCK_CHECKOUT, lock }),
  getCartQuote: (payload = {}) => ({ type: constants.GET_CART_QUOTE_REQUEST, payload }),
  addToCart: (payload = {}) => ({ type: constants.ADD_TO_CART_REQUEST, payload }),
  toggleCartItem: (itemId) => ({ type: constants.TOGGLE_CART_ITEM, itemId }),
  toggleAllCartItems: (checked = false) => ({ type: constants.TOGGLE_ALL_CART_ITEMS, checked }),
  clearSelectedCartItems: () => ({ type: constants.CLEAR_SELECTED_CART_ITEMS }),
  updateCartItem: (payload = {}) => ({ type: constants.UPDATE_CART_REQUEST, payload }),
  removeCartItem: (payload = {}) => ({ type: constants.REMOVE_FROM_CART_REQUEST, payload }),
  editAll: () => ({ type: constants.EDIT_ALL }),
  clearAll: () => ({ type: constants.CLEAR_ALL }),
  editSingle: (payload) => ({ type: constants.EDIT_SINGLE, payload }),
  cancelSingleEdit: (itemId) => ({ type: constants.CLEAR_FROM_EDIT, itemId }),
  saveAll: (payload) => ({ type: constants.SAVE_ALL_REQUEST, payload }),
  updateCartItemLocal: (payload = {}) => ({ type: constants.UPDATE_CART_REQUEST_LOCAL, payload }),
};

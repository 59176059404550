import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import { actions } from './actions';
import messages from './messages';

function CloseNotification({ notificationKey, closeSnackbar }) {
  const handleClick = () => closeSnackbar(notificationKey);

  return (
    <Button onClick={handleClick} color="inherit">
      <FormattedMessage {...messages.dismiss} />
    </Button>
  );
}

CloseNotification.propTypes = {
  notificationKey: PropTypes.number,
  closeSnackbar: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    closeSnackbar: (key) => dispatch(actions.closeSnackbar(key)),
  };
}

const withConnect = connect(
  null,
  mapDispatchToProps,
);

export default withConnect(CloseNotification);
